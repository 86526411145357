import states from '../reduxStates';

const initialState = {
    state: states.NEW,
    news: {}
};

const match = (state = initialState, action) => {
    switch (action.type) {
      case 'REQUEST_NEWS':
        return {
          ...state,
          state: states.FETCHING
        };
      case 'RECEIVE_NEWS_FAIL':
        return {
          ...state,
          state: states.FAILED,
        };
      case 'RECEIVE_NEWS':
        return {
          ...state,
          news: action.news,
          state: states.SAVED,
        };
      default:
        return state;
    }
  };
  
  export default match;