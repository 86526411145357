import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Button,
  Alert
} from 'reactstrap';
import FloatingLabelInput from './utils/FloatingLabelInput';


class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      submitted: false,
      password: "",
      waiting: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const id = event.target.id;
    const value = event.target.value;

    this.setState({
      [id]: value
    });
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true, waiting: true });
    this.props.login(this.state.email, this.state.password).then(() => {
      if(this.props.redirectUrl)
        this.props.redirectToUrl(this.props.redirectUrl)
      if (!this.unmounting) {
        this.setState({ waiting: false });
      }
    });
  }

  render() {
    let errors = (this.props.errors && this.state.submitted) ? 
      this.props.errors.map((e, i) => (<Alert key={`error-${i}`} color="warning">{e}</Alert>)) :
      [];
    if(errors.length === 0 && this.props.oauthError){
      errors.push(<Alert key={`error-1`} color="warning">{this.props.oauthError}</Alert>);
    }
    const google_oauth_url = process.env.REACT_APP_API_URL +
      '/auth/google_oauth2?auth_origin_url=' +
      process.env.REACT_APP_PUBLIC_URL +
      '/oauth-callback&page=login';

    const loadingIcon = this.state.waiting ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <FloatingLabelInput
            id="email"
            type="email"
            placeholder="Email"
            onChange={this.handleChange}
            required />
        </FormGroup>
        <FormGroup>
          <FloatingLabelInput
            id="password"
            type="password"
            placeholder="Password"
            onChange={this.handleChange}
            required />
        </FormGroup>
        <FormGroup className="text-center">
          <Button type="submit" color="black" className="theme-button login-button">
            Log In {loadingIcon}
          </Button>
        </FormGroup>
        <FormGroup className="text-center ">
          <Button href={google_oauth_url} className="theme-button-inverse google-login" >
            <i className="fab fa-google"></i> Log in with Google
          </Button>
        </FormGroup>
        {errors}
      </Form>
    );
  }
}

export default LoginForm; 
