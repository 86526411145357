import React, { Component } from 'react';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Table,
  Progress
} from 'reactstrap';
import { Link } from 'react-router-dom';
import states from '../../reduxStates';
import Util from '../../util';

class Profile extends Component {
  constructor(props) {
    super(props);

    if (this.props.profileState === states.NEW) {
      this.props.fetchProfiles();
    }
  }

  becomeUser = (id) => {
    Util.apiGetRequest(`/admin/users/${id}/become`).then(response => {
      if (response.ok) {
        response.json().then(json => {
          this.props.switchUser(json);
          this.props.history.push('/');
        });
      }
    });
  }

  render() {
    const profile = this.props.profiles.find(p => {
      return p.id === Number(this.props.match.params.id);
    });

    const links = this.props.profiles.map(p => (
      <ListGroupItem key={p.id}>
        <Link to={`/admin/profile/${p.id}`}>{p.id} {p.first_name} {p.last_name}</Link>
      </ListGroupItem>
    ));

    if (profile) {
      const attributes = Object.keys(profile).filter(key =>
        typeof profile[key] !== "object");

      return (
        <Row>
          <Col xs={2}>
            <ListGroup>
              {links}
            </ListGroup>
          </Col>
          <Col xs={10}>
            <div style={{overflow:'scroll'}}>
              <Table bordered striped>
                <thead>
                  <tr>
                    {attributes.map(a => <th key={a}>{a}</th>)}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {attributes.map(a => <td key={a}>{profile[a]}</td>)}
                  </tr>
                </tbody>
              </Table>
            </div>
            <Table bordered striped>
              <thead>
                <tr>
                  {
                    profile.home_survey_answers_attributes.map(ans => (
                      <th key={ans.id}>{ans.home_survey_question.question}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  {
                    profile.home_survey_answers_attributes.map(ans => (
                      <td key={ans.id}>
                        <Progress value={ans.answer} max="7" className="progress-notransition">
                          <span className="p-1">
                            {ans.answer}
                          </span>
                        </Progress>
                      </td>
                    ))
                  }
                </tr>
              </tbody>
            </Table>
            <Table bordered striped>
              <thead>
                <tr>
                  <th>Neighbourhood</th>
                  <th>City</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                {
                  profile.location_selections_attributes.map(ls => (
                    <tr key={ls.id}>
                      <td>{ls.neighbourhood}</td>
                      <td>{ls.location.city}</td>
                      <td>{ls.location.country}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            <Button onClick={() => this.becomeUser(profile.user_id)}>Become User</Button>
          </Col>
        </Row>
      );
    } else return null;
  }
}

export default Profile;
