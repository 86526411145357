import React, { Component } from 'react';
import {
    Card,
    CardBody,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom';

export default class WelcomeInfo extends Component {
    render() {
        return (
            <Card className="welcome-card">
                <CardBody>
                    <span className="header-text" >
                        Welcome back, {this.props.firstName}.
                    </span>
                    <p className="welcome-content">
                        The current real estate market in large requires us to think creatively about homeownership. Co-ownership is when two or more parties buy a property together. It is a model that invites people to pool their resources to enter the housing market and is one way that enables everyday Canadians to take advantage of the economic, social and community benefits of homeownership. Not ready? Learn about co-ownership at GoCo.
                    </p>
                    <div className="text-right">
                        <Button className="theme-button" tag={Link} to="profiles">
                        Start Matching
                        </Button>
                    </div>
                    {/* <PartnersInfo {...this.props} /> */}
                </CardBody>
            </Card>
        )
    }
}