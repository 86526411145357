import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Button,
  Alert
} from 'reactstrap';
import FloatingLabelInput from './utils/FloatingLabelInput';

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      waiting: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const id = event.target.id;
    const value = event.target.value;

    this.setState({
      [id]: value
    });
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ waiting: true });
    this.props.registerUser(
      this.state.email,
      this.state.firstName,
      this.state.lastName,
      this.state.password,
      this.state.passwordConfirmation,
      this.props.history
    ).then(() => {
      if (!this.unmounting) {
        this.setState({ waiting: false });
      }
    });
  }

  render() {
    const google_oauth_url = process.env.REACT_APP_API_URL +
      '/auth/google_oauth2?auth_origin_url=' +
      process.env.REACT_APP_PUBLIC_URL +
      '/oauth-callback&page=signup';
    let errors = (this.props.errors) ?
      this.props.errors.map((e, i) => (<Alert key={`error-${i}`} color="warning">{e}</Alert>)) :
      [];
    if(errors.length === 0 && this.props.oauthError){
      errors.push(<Alert key={`error-1`} color="warning">{this.props.oauthError}</Alert>);
    }
    const loadingIcon = this.state.waiting ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <FloatingLabelInput
            id="firstName"
            placeholder="First name"
            onChange={this.handleChange}
            required />
        </FormGroup>
        <FormGroup>
          <FloatingLabelInput
            id="lastName"
            placeholder="Last name"
            onChange={this.handleChange}
            required />
        </FormGroup>
        <FormGroup>
          <FloatingLabelInput
            id="email"
            type="email"
            placeholder="Email"
            onChange={this.handleChange}
            required />
        </FormGroup>
        <FormGroup>
          <FloatingLabelInput
            id="password"
            type="password"
            placeholder="Password"
            onChange={this.handleChange}
            required />
        </FormGroup>
        <FormGroup>
          <FloatingLabelInput
            id="passwordConfirmation"
            type="password"
            placeholder="Confirm password"
            onChange={this.handleChange}
            required />
        </FormGroup>
        <FormGroup className="text-center ">
          <Button type="submit" color="primary" className="theme-button login-button">
            Sign up {loadingIcon}
          </Button>
        </FormGroup>
        <FormGroup className="text-center ">
          <Button href={google_oauth_url} className="theme-button-inverse google-login">
            <i className="fab fa-google"></i> Sign up with Google
          </Button>
        </FormGroup>
        {errors}
      </Form>
    );
  }
}

export default RegistrationForm;
