import { connect } from 'react-redux';
import { fetchMyMatches } from '../actions';
import MyMatchesIndex from '../components/pages/MyMatches';

import _ from 'lodash'

const mapStateToProps = (state) => {

  return {
    profile: state.profile,
    matches: state.myMatches.matches,
    state: state.myMatches.state

  };
};

const mapDispatchToProps = (dispatch) => ({
    fetchMyMatches: () => dispatch(fetchMyMatches()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyMatchesIndex);
