import React, { Component } from 'react';
import {
  Button,
  FormGroup,
  Label,
  Col
} from 'reactstrap';

import {INTRESTS} from '../constants'


class InterestList extends Component {
   
    toggleSelected = (rec)=>{
        let selected  = [...this.props.selected]
        var index = selected.indexOf(rec.value);
        if(index === -1)
            selected.push(rec.value);
        else
            selected.splice(index, 1);

        this.props.handleChange('interests', selected);
    }
    getInterestList = (selected, fetching) => {
        return INTRESTS.map((interst, i)=>{
            return (<Button key={'intrest-'+i} outline onClick={()=>this.toggleSelected(interst)} className={"interest-btn "+ (selected.indexOf(interst.value) > -1 ? 'selected' : '')} disabled={fetching}>
                        {interst.name}  <img src={`/intrests/${interst.icon}.png`} alt="" />
                    </Button>)
        })
    };
    render(){
        const fetching = this.props.fetching;
        return(
            <FormGroup row className="mt-5 mb-0 pb-0">
                <Label for="gender" sm={12}>
                    <strong className="font-weight-bold">Interests (Pick as many as you want)</strong>
                </Label>
                <Col sm={12} md={12}>
                    {this.getInterestList(this.props.selected, fetching)}
                </Col>
            </FormGroup>
        )
    }
}
export default InterestList;
