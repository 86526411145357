import Util from '../util';
import { profile as profileSchema } from '../schema';
import { fetchEntity, saveEntity } from './common';

/**
 * User's own profile
 */
export const updateProfile = (name, value) => ({
  type: 'UPDATE',
  payload: {
    [name]: value
  },
  meta: {
    type: 'profile'
  }
});

export const fetchProfile = () => fetchEntity(
  'profile',
  profileSchema
);

export const saveProfilePhoto = (photo) => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST',
    meta: {
      type: 'profile'
    }
  });
  return Util.uploadProfilePhoto(
    '/profile_photo',
    photo
  ).then(response => {
    if (response.ok) {
      return response.json().then(json => {
        const profile = getState().profile;
        profile.photo = json.photo;
        dispatch({
          type: 'RECEIVE',
          payload: profile,
          meta: {
            type: 'profile'
          }
        });
      });
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: {
          type: 'profile'
        }
      });
    }
  });
};

export const deleteProfilePhoto = () => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST',
    meta: {
      type: 'profile'
    }
  });
  return Util.apiRequest(
    'DELETE',
    '/profile_photo'
  ).then(response => {
    if (response.ok) {
      return response.json().then(json => {
        const profile = getState().profile;
        profile.photo = json.photo;
        dispatch({
          type: 'RECEIVE',
          payload: profile,
          meta: {
            type: 'profile'
          }
        });
      });
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: {
          type: 'profile'
        }
      });
    }
  });
}

export const saveImageGalleryPhoto = (id,photo) => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST',
    meta: {
      type: 'profile'
    }
  });
  return Util.uploadImagegalleryPhoto(
    '/update_image_gallery',
    id,
    photo
  ).then(response => {
    if (response.ok) {
      return response.json().then(json => {
        const profile = getState().profile;
        profile.image_galleries = json.image_galleries;
        dispatch({
          type: 'RECEIVE',
          payload: profile,
          meta: {
            type: 'profile'
          }
        });
      });
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: {
          type: 'profile'
        }
      });
    }
  });
};

export const deleteImageGalleryPhoto = (id) => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST',
    meta: {
      type: 'profile'
    }
  });
  return Util.apiRequest(
    'DELETE',
    `/delete_image_gallery/${id}`
  ).then(response => {
    if (response.ok) {
      return response.json().then(json => {
        const profile = getState().profile;
        profile.image_galleries = json.image_galleries;
        dispatch({
          type: 'RECEIVE',
          payload: profile,
          meta: {
            type: 'profile'
          }
        });
      });
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: {
          type: 'profile'
        }
      });
    }
  });
}

export const saveProfile = () => saveEntity(
  'profile',
  profileSchema
);
