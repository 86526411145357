import { connect } from 'react-redux';
import UserSettings from '../components/pages/UserSettings';
import { saveProfile, updateProfile } from '../actions';

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});


const mapDispatchToProps = (dispatch) => ({
  updateProfile: (id, value) => dispatch(updateProfile(id, value)),
  saveProfile: () => dispatch(saveProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings);
