import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Button
} from 'reactstrap';
import FloatingLabelInput from '../../components/utils/FloatingLabelInput';
import SimpleModal from '../../components/utils/SimpleModal';

class AddQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addModal: false,
      question: '',
      subtext: '',
      answer_type: '',
      questionnaire_order: '',
      short_name: ''
    };
  }

  handleChange = (event) => this.setState({
    [event.target.name]: event.target.value
  });

  handleSave = (event) => {
    this.props.createQuestion({
      question: this.state.question,
      subtext: this.state.subtext,
      answer_type: this.state.answer_type,
      questionnaire_order: this.state.questionnaire_order,
      short_name: this.state.short_name
    }).then(() => this.props.fetchQuestions());
    this.setState({
      question: '',
      subtext: '',
      answer_type: '',
      questionnaire_order: '',
      short_name: ''
    });
  };

  toggleAddModal = () => this.setState({
    addModal: !this.state.addModal
  });

  render() {
    return (
      <React.Fragment>
        <Form noValidate onSubmit={ (event) => {event.preventDefault();} }>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Question"
              onChange={this.handleChange}
              name="question"
              value={this.state.question}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Subtext"
              onChange={this.handleChange}
              name="subtext"
              value={this.state.subtext}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Short Name"
              onChange={this.handleChange}
              name="short_name"
              value={this.state.short_name}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Type"
              onChange={this.handleChange}
              name="answer_type"
              value={this.state.answer_type}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Order"
              type="number"
              onChange={this.handleChange}
              name="questionnaire_order"
              value={this.state.questionnaire_order}
            />
          </FormGroup>
          <FormGroup>
            <Button onClick={this.toggleAddModal} color="primary"> 
              Add Question
            </Button>
          </FormGroup>
        </Form>

        <SimpleModal
          isOpen={this.state.addModal}
          toggle={this.toggleAddModal}
          title="Are you sure?"
          buttons={[
            { text: 'Add Question', color: 'success', action: this.handleSave },
            { text: 'Cancel' }
          ]}>
          This change will be visible to users.
        </SimpleModal>
      </React.Fragment>
    );
  }
}

export default AddQuestion;
