import React, { Component } from 'react';
import Page from './_Page';
import UpdatePasswordForm from '../../containers/UpdatePasswordForm';
import {
  Card,
  CardBody
} from 'reactstrap';
import Logo from '../Logo';
import { Link } from 'react-router-dom';

class ResetPassword extends Component {
  render() {
    return (
      <Page title="Reset Password" pageClass="reset-password" hideTitle background hideNavbar hideFooter darkMode>
        <h1 className="text-center text-light mb-4">
          <Logo height="60" textBeside textColor="#fff" />
        </h1>
        <Card className="login-signup-card">
          <CardBody>
            <h2 className="text-center mb-3">Reset Password</h2>
            <p>
              Please enter a new password for your account:
            </p>
            <UpdatePasswordForm />
            <p className="text-center">
              <Link to="/logout">
                Cancel
              </Link>
            </p>
          </CardBody>
        </Card>
      </Page>
    );
  }
}

export default ResetPassword;
