import React, { Component } from 'react';
import states from '../reduxStates';
import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';

class MessagesWidget extends Component {
  componentDidMount() {
    this.props.fetchUnreadCount();
  }

  render() {
    const showUnread = (this.props.match.url.split('/')[1] !== 'partners') || (this.props.match.url.split('/')[1] !== 'conversations');
    if(showUnread && this.props.unreadCount){
      return (
        <UncontrolledDropdown nav inNavbar className="notifications-dropdown messages" >
          <Link to='/partners'>
            <span  className="notification-icon messages">
                <img src="/icons/envelope.svg" alt="Notifications" className="image"/>
                <i className="fa fa-circle unread" ></i>
            </span>
          </Link>
        </UncontrolledDropdown>
      );
    }else{
      return (
        <UncontrolledDropdown nav inNavbar className="notifications-dropdown messages">
          <Link to='/partners'>
            <span  className="notification-icon">
                <img src="/icons/envelope.svg" alt="Notifications" className="image"/>
            </span>
          </Link>
        </UncontrolledDropdown>
      )
    }
  }
}

export default MessagesWidget;
