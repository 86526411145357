import React, { Component } from 'react';
import {
  InputGroup,
  CustomInput
} from 'reactstrap';
import Cleave from 'cleave.js/react';

class BirthdayInput extends Component {

  splitBirthday = () => {
    return this.props.value ? this.props.value.split('-') : ['', '', ''];
  };

  handleChangeBirthday = (event) => {
    const part = event.target.dataset.birthday_part;
    const value = event.target.value;
    let birthday = this.splitBirthday();

    if (part === 'year') { birthday[0] = value; }
    else if (part === 'month') { birthday[1] = value; }
    else if (part === 'day') { birthday[2] = value; }

    this.props.onChange('birthday', birthday.join('-'));
  };


  render() {
    const birthday = this.splitBirthday();

    return (
      <InputGroup role="group" id="birthday-container" className={this.props.invalid ? 'is-invalid' : ''}>
        <CustomInput
          id={this.props.id}
          data-birthday_part="month"
          aria-label="Birthday Month"
          type="select"
          disabled={this.props.disabled}
          value={birthday[1]}
          onChange={this.handleChangeBirthday}
          // invalid={this.props.invalid}
          style={{ flexGrow: '1.5' }}>
          <option value="" disabled>Month</option>
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </CustomInput>
        <Cleave
          data-birthday_part="day"
          aria-label="Birthday Day"
          placeholder="Day"
          className="form-control"
          pattern="[0-9]*"
          options={{ date: true, datePattern: ['d'] }}
          disabled={this.props.disabled}
          value={birthday[2]}
          // invalid={this.props.invalid}
          onChange={this.handleChangeBirthday}
        />
        <Cleave
          data-birthday_part="year"
          aria-label="Birthday Year"
          placeholder="Year"
          className="form-control"
          pattern="[0-9]*"
          options={{ date: true, datePattern: ['Y'] }}
          disabled={this.props.disabled}
          // invalid={this.props.invalid}
          value={birthday[0]}
          onChange={this.handleChangeBirthday}
        />
      </InputGroup>
    );
  }
}

export default BirthdayInput;
