import React, { Component } from 'react';
import {  ListGroup,ListGroupItem  } from 'reactstrap';
import NestedSingleSelect from '../../NestedSingleSelect';
class SearchSuggestions extends Component {
    render(){
        const {status, stateFor, states, searchByCity, searchedText, resultIndex} = this.props;
        let render_list =null;
        let resultLoading = (status === states.FETCHING &&  stateFor === "PROPERTIES_SEARCH_BY_LOCATION");
        if(resultLoading && resultIndex){
            render_list = <ListGroup>
            <ListGroupItem>please wait <i className="fas fa-fw fa-spinner fa-spin"></i> </ListGroupItem>
        </ListGroup>
        }else if(status === states.FETCHING &&  stateFor === "SEARCH_SUGGESTIONS"){
            render_list = <ListGroup>
                <ListGroupItem>searching for <span className='text-muted'>'{searchedText}'</span></ListGroupItem>
            </ListGroup>
        }else if(this.props.list && this.props.list.length > 0){
            render_list = <NestedSingleSelect 
                options={this.props.list}
                searchByCity={searchByCity}
            />
        }else if(this.props.list.length === 0){
            render_list = (<ListGroup>
                <ListGroupItem>no search found</ListGroupItem>
            </ListGroup>)
        }
        
       
        return(
            <div className='search-suggetions'>
                {render_list}
            </div>
        )
    }
}
export default SearchSuggestions;