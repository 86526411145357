import { Util } from '../util.js';

/**
 * /admin/home_survey_answers
 */
export const fetchAdminAnswers = () => dispatch => {
  dispatch({
    type: 'REQUEST_ADMIN_ANSWERS'
  });
  return Util.apiGetRequest('/admin/home_survey_answers').then(response => {
    if (response.status === 200) {
      return response.json()
        .then(items => dispatch({
            type: 'RECEIVE_ADMIN_ANSWERS',
            items
          }));
    } else {
      dispatch({
        type: 'RECEIVE_ADMIN_ANSWERS_FAIL'
      });
    }
  });
};

/**
 * /admin/home_survey_questions
 */
export const fetchAdminQuestions = () => dispatch => {
  dispatch({
    type: 'REQUEST_ADMIN_QUESTIONS'
  });
  return Util.apiGetRequest('/admin/home_survey_questions').then(response => {
    if (response.status === 200) {
      return response.json()
        .then(items => dispatch({
            type: 'RECEIVE_ADMIN_QUESTIONS',
            items
          }));
    } else {
      dispatch({
        type: 'RECEIVE_ADMIN_QUESTIONS_FAIL'
      });
    }
  });
};

export const createQuestion = (question) => dispatch => {
  return Util.apiRequest(
    'POST',
    '/admin/home_survey_questions',
    null,
    {home_survey_question: question}
  );
}
export const updateQuestion = (question) => dispatch => {
  return Util.apiRequest(
    'PUT',
    `/admin/home_survey_questions/${question.id}`,
    null,
    {home_survey_question: question}
  );
};

export const deleteQuestion = (question) => dispatch => {
  return Util.apiRequest(
    'DELETE',
    `/admin/home_survey_questions/${question.id}`);
};

/**
 * /admin/matches
 */
export const createMatch = (match) => dispatch => {
  return Util.apiRequest(
    'POST',
    '/admin/matches',
    null,
    {match: match}
  );
};

export const updateAdminMatch = (match) => dispatch => {
  return Util.apiRequest(
    'PUT',
    `/admin/matches/${match.id}`,
    null,
    {match: match}
  );
};

export const deleteMatch = (match) => dispatch => {
  return Util.apiRequest(
    'DELETE',
    `/admin/matches/${match.id}`
  );
};

export const fetchAdminMatches = () => dispatch => {
  dispatch({
    type: 'REQUEST_ADMIN_MATCHES'
  });
  return Util.apiGetRequest('/admin/matches').then(response => {
    if (response.status === 200) {
      return response.json()
        .then(items => dispatch({
            type: 'RECEIVE_ADMIN_MATCHES',
            items
          }));
    } else {
      dispatch({
        type: 'RECEIVE_ADMIN_MATCHES_FAIL'
      });
    }
  });
};

/**
 * /admin/users
 */
export const fetchUsers = () => dispatch => {
  dispatch({
    type: 'REQUEST_USERS'
  });
  return Util.apiGetRequest('/admin/users').then(response => {
    if (response.status === 200) {
      return response.json()
        .then(items => dispatch({
            type: 'RECEIVE_USERS',
            items
          }));
    } else {
      dispatch({
        type: 'RECEIVE_USERS_FAIL'
      });
    }
  });
};

/**
 * /admin/profiles
 */
export const fetchAdminProfiles = () => dispatch => {
  dispatch({
    type: 'REQUEST_ADMIN_PROFILES'
  });
  return Util.apiGetRequest('/admin/profiles').then(response => {
    if (response.status === 200) {
      return response.json()
        .then(items => dispatch({
            type: 'RECEIVE_ADMIN_PROFILES',
            items
          }));
    } else {
      dispatch({
        type: 'RECEIVE_ADMIN_PROFILES_FAIL'
      });
    }
  });
};

export const updateProfile = (profile) => dispatch => {
  return Util.apiRequest(
    'PUT',
    `/admin/profiles/${profile.id}`,
    null,
    {profile: profile}
  );
};

export const deleteProfile = (profile) => dispatch => {
  return Util.apiRequest(
    'DELETE',
    `/admin/profiles/${profile.id}`);
};
