import { combineReducers } from 'redux';
import auth from './auth';
import match from './match';
import profile from './profile';
import fellow from './fellow';
import states from './states';
import alerts from './alerts';
import { common, types } from './common';
import adminAnswers from './admin/answers';
import adminMatches from './admin/matches';
import adminProfiles from './admin/profiles';
import adminQuestions from './admin/questions';
import adminUsers from './admin/users';
import property from './property';
import myMatches from './myMatches';
import news from './news';
import conversationMessages from './conversationMessages';
import {reducer as toastrReducer} from 'react-redux-toastr'

export default (state, action) => {
  if (action.type === 'SIGNOUT_USER') {
    state = undefined;
  }

  const reducers = {
    auth,
    adminAnswers,
    adminMatches,
    adminProfiles,
    adminQuestions,
    adminUsers,
    profile,
    match,
    states,
    alerts,
    fellow,
    news,
    properties: property,
    myMatches: myMatches,
    conversationMessages: conversationMessages,
    toastr: toastrReducer
  };
  types.forEach(type => {
    reducers[type] = common(type);
  });
  const reducer = combineReducers(reducers);
  return reducer(state, action);
};
