import React, { Component } from 'react';
import states from '../../../reduxStates';
import { Redirect, Link } from 'react-router-dom';
import Page from '../_Page';
import MyMatch from './MyMatch';
import {
    Col,
    Row,
    ListGroup,
    Card, 
    CardHeader,  
    CardBody
} from 'reactstrap';
import NotificationLoader from '../../NotificationLoader';

class MyMatchesIndex extends Component {
    componentDidMount() {
        this.props.fetchMyMatches();
    }
    getContent = () => {
        if (!this.props.profile.complete) {
          return (
            <p className="text-center">
              You can see other HusMates users once
              you <Link to="/profile">complete your profile</Link>.
            </p>
          );
        } 
        else {
            return (
                <Row>
                    <Col sm={12} md={{size: 6, offset: 3}}>
                        <Card className='chat-list-container'>
                            <CardHeader>My Matches</CardHeader>
                            <CardBody>
                            <ListGroup>
                                {this.props.state === states.FETCHING ? (
                                    <NotificationLoader 
                                    width={'100%'}
                                    height={'400'}
                                    />
                                ) : (
                                    <MyMatch records = {this.props.matches}/>
                                )}
                            
                            </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )
        }
    }
    render() {
        return (
            <Page title="Partners" pageClass="partners" hideTitle hideFooter={true}>
                {this.getContent()}
            </Page>
        );
    }
}

export default MyMatchesIndex;