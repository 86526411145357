import { connect } from 'react-redux';
import Chat from '../components/pages/Conversations/Chat';
import { markSeen, sendMessage, updateConversationMessages } from '../actions';


const mapStateToProps = (state) => {
    return {
        cid: state.conversationMessages.id,
        messages: state.conversationMessages.messages,
        profile: state.profile,
        stateFor: state.conversationMessages.stateFor
    }
  };

const mapDispatchToProps = (dispatch) => ({
  markSeen: markSeen,
  sendMessage: sendMessage,
  updateConversationMessages: () => dispatch(updateConversationMessages)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chat);
