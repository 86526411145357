import Util from '../util';
import { notification as notificationsSchema } from '../schema';
import { fetchEntity, processEntity } from './common';

var notification = null;

export const sub = (cable, dispatch, getState) => {
  notification = cable.subscriptions.create('NotificationChannel', {
    received: (data) => {
      const key = Object.keys(data)[0];
      switch (key) {
        case 'notification':
          receiveNotification(dispatch, getState, data[key])
          break;
        default:
          throw Error('Unknown broadcast received from NotificationChannel');
      }
    }
  });
};

export const unsub = () => notification.unsubscribe();

export const receiveNotification = (dispatch, getState, notification) => {
  dispatch({
    type: 'RECEIVE',
    meta: { type: 'notifications' },
    payload: {
      [notification.id]: notification
    }
  });
};

export const fetchNotifications = () => fetchEntity(
  'notifications',
  [notificationsSchema]
);

export const markNotificationsSeen = () => (dispatch) => {
  dispatch({
    type: 'REQUEST',
    meta: {
      type: 'notifications'
    }
  });

  return Util.apiRequest('post', `/notifications/mark_all_seen`).then(response => {
    if (response.ok) {
      return response.json().then(json =>
        processEntity(dispatch, 'notifications', [notificationsSchema], json));
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: {
          type: 'notifications'
        }
      });
    }
  });
}

