import { connect } from 'react-redux';
import Profiles from '../components/pages/Profiles';
import { createFellowship, cancelFellowship, fetchFellows,clearFellows } from '../actions';
import _ from 'lodash'

const mapStateToProps = (state) => {
  if(state.pagination){
    localStorage.setItem('pagination',JSON.stringify(state.pagination))
  }
  // assumes only one location selection.
  // TODO rewrite when we allow for multiple location selections
  let city=null;
  if(state.auth.loggedIn){
    city = state.profile.location_selections_attributes[0] ?
      state.locations[state.locationSelections[
      state.profile.location_selections_attributes[0]].location
    ].city : null;
  }
  return {
    city: city,
    conversations: state.conversations,
    fellowsState: state.states.fellows,
    fellows: state.fellows,
    fellowships: state.fellowships,
    locationSelections: state.locationSelections,
    locations: state.locations,
    profile: state.profile,
    loggedIn: state.auth.loggedIn,
    pagination: state.pagination
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFellows: (page) => dispatch(fetchFellows(page)),
  clearFellows: () => dispatch(clearFellows()),
  createFellowship: (id) => dispatch(createFellowship(id)),
  cancelFellowship: (id) => dispatch(cancelFellowship(id))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profiles);
