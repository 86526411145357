import React, { Component } from 'react';
import states from '../../reduxStates';
import {
  CustomInput,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col
} from 'reactstrap';
import ReactTable from 'react-table';
import EditQuestion from './EditQuestion';
import AddQuestion from './AddQuestion';

class Questions extends Component {
  constructor(props) {
    super(props);

    if (this.props.questionsState === states.NEW) {
      this.props.fetchQuestions();
    }

    this.state = {
      advancedMode: false,
      filteredCount: null
    };

    this.tableOptions = {
      filters: {
        boolean: (
          ({ onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}>
              <option value="">All</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
          )
        ),
        string: (
          ({ onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}>
              <option value="">All</option>
              <option value="home">Home</option>
              <option value="legal">Legal</option>
              <option value="health_and_disability">Health And disability</option>
              <option value="spirituality_lifestyle">Spirituality Lifestyle</option>
            </select>
          )
        ),

      },
      formatters: {
        tostring: (
          d => d.value.toString()
        ),
        datetime: (
          d => new Date(d.value).toLocaleString("en-CA", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit'
          })
        ),
        number: (
          d => (
            <div className="text-right">
              { Number(d.value).toLocaleString('en-CA') }
            </div>
          )
        )
      }
    }
  }

  toggleAdvanced = () => {
    this.setState({
      advancedMode: !this.state.advancedMode
    });
  };

  updateFilteredCount = () => {
    if (this.tableRef.state.filtered.length > 0) {
      this.setState({
        filteredCount: this.tableRef.state.sortedData.length
      });
    } else {
      this.setState({
        filteredCount: null
      });
    }
  };

  render() {
    const data = this.props.questions;
    const count = this.state.filteredCount ? this.state.filteredCount : data.length;
    const columns = [
      {
        Header: 'id',
        accessor: 'id',
        maxWidth: 50,
        show: this.state.advancedMode
      },
      {
        Header: 'Order',
        accessor: 'questionnaire_order',
        maxWidth: 60
      },
      { 
        Header: 'Question',
        accessor: 'question',
      },
      {
        Header: 'Subtext',
        accessor: 'subtext',
        show: this.state.advancedMode
      },
      {
        Header: 'Short Name',
        accessor: 'short_name',
        maxWidth: 100
      },
      {
        Header: 'Type',
        accessor: 'answer_type',
        maxWidth: 100
      },
      {
        Header: 'Category',
        accessor: 'category',
        maxWidth: 100,
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.string,
      },
      {
        Header: 'Parent Question Id',
        accessor: 'parent_question_id',
        maxWidth: 100
      },
      {
        Header: 'Question For',
        accessor: 'question_for',
        maxWidth: 100
      },
      {
        Header: 'Archived',
        accessor: 'archived',
        maxWidth: 100,
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean,
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: this.tableOptions.formatters.datetime,
        show: this.state.advancedMode
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: this.tableOptions.formatters.datetime,
        show: this.state.advancedMode
      }
    ];

    return (
      <React.Fragment>
        <Row>
          <Col>
            <CustomInput
              type="checkbox"
              id="advancedMode"
              label="Advanced Mode (show all columns)"
              className="mb-3"
              checked={this.state.advancedMode}
              onChange={this.toggleAdvanced}
            />
          </Col>
          <Col xs={3} className="text-right">
            Count: { count }
          </Col>
        </Row>
        <ReactTable
          ref={(r) => {this.tableRef = r}}
          data={data}
          columns={columns}
          defaultPageSize={20}
          filterable
          onFilteredChange={this.updateFilteredCount}
          className="-striped"
          defaultSorted={[{ id: 'questionnaire_order', asc: true }]}
          SubComponent={row => (
            <div className="p-3">
              <EditQuestion
                question={row.original}
                fetchQuestions={this.props.fetchQuestions}
                updateQuestion={this.props.updateQuestion}
                deleteQuestion={this.props.deleteQuestion}
              />
            </div>
          )}
        />
        <Card className="mt-4">
          <CardHeader>New Question</CardHeader>
          <CardBody>
            <AddQuestion
              createQuestion={this.props.createQuestion}
              fetchQuestions={this.props.fetchQuestions}
            />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Questions;
