export const INTRESTS= [
{name: "Dancing", value:"Dancing",icon:"dancing"},
{name: "Parties", value:"Parties",icon:"parties"},
{name: "Yoga", value:"Yoga",icon:"yoga"},
{name: "Fitness", value:"Fitness",icon:"fitness"},
{name: "Fashion", value:"Fashion",icon:"fashion"},
{name: "Law", value:"Law",icon:"law"},
{name: "Sewing", value:"Sewing",icon:"sewing"},
{name: "Plants", value:"Plants",icon:"plants"},
{name: "Anime ", value:"Anime ",icon:"anime"},
{name: "Books and Reading", value:"Books and Reading",icon:"books_and_reading"},
{name: "Carpentry", value:"Carpentry",icon:"carpentry"},
{name: "Making Music", value:"Making Music",icon:"making_music"},
{name: "Netflix & Chill", value:"Netflix & Chill",icon:"netflix_and_chill"},
{name: "Pop Culture", value:"Pop Culture",icon:"pop_culture"},
{name: "Social Justice", value:"Social Justice",icon:"social_justice"},
{name: "Travel and Tourism", value:"Travel and Tourism",icon:"travel"},
{name: "Pottery", value:"Pottery",icon:"pottery"},
{name: "Painting", value:"Painting",icon:"painting"},
{name: "Sports", value:"Sports",icon:"sports"},

{name: "Cooking", value:"Cooking",icon:"cooking"},

{name: "Learning Languages", value: "Learning Languages",icon:"learning_languages"},
{name: "Comics", value:"Comics",icon:"comics"},
{name: "Design & Architecture", value:"Design & Architecture",icon:"design_and_architecture"},
{name: "Gaming", value:"Gaming",icon:"gaming"},
{name: "Larping", value:"Larping",icon:"larping"},
{name: "Writing", value:"Writing",icon:"writing"},
{name: "Knitting", value:"Knitting",icon:"knitting"},
{name: "Gardening ", value:"Gardening ",icon:"gardening"},
{name: "Board Games", value: "Board Games",icon:"board_games"},
{name: "Park and Beach Hangouts", value:"Park and Beach Hangouts",icon:"park"},
{name: "Vinyl", value:"Vinyl",icon:"vinyl"},

{name: "Fine Art, Contemporary Art", value:"Fine Art, Contemporary Art",icon:"fine_art"},

{name: "Camping", value:"Camping",icon:"camping"},
{name: "Puzzles", value:"Puzzles",icon:"puzzles"},
{name: "Blogging", value:"Blogging",icon:"blogging"},


{name: "Podcasting", value:"Podcasting",icon:"podcasting"},

{name: "Listening to Music", value:"Listening to Music",icon:"music"},
{name: "Volunteering", value:"Volunteering",icon:"volunteering"},
{name: "Photography", value:"Photography",icon:"photography"},
{name: "Making + Crafting", value:"Making + Crafting",icon:"crafting"},
{name: "Astrology", value:"Astrology",icon:"astrology"},
{name: "Programming + Coding", value:"Programming + Coding",icon:"coding"},
{name: "Business", value:"Business",icon:"business"},
{name: "Tattoos", value:"Tattoos",icon:"tattoos"},
{name: "Academia", value:"Academia",icon:"academia"},
{name: "Marketing", value:"Marketing",icon:"marketing"},
{name: "Finance", value:"Finance",icon:"finance"},

{name: "Cryptography and Blockchain", value:"Cryptography and Blockchain",icon:"cryptography"},
{name: "Vegan", value:"Vegan", icon:"vegan"},
{name: "Pescatarian", value:"Pescatarian", icon:"pescatarian"},
{name: "Vegetarian", value:"Vegetarian", icon:"vegetarian"},
{name: "Spiritual", value:"Spiritual", icon:"spiritual"},
{name: "Foodie", value:"Foodie", icon:"foodie"},
{name: "Wine", value:"Wine", icon:"wine"},
{name: "Beer", value:"Beer", icon:"beer"},
{name: "Cocktails", value:"Cocktails", icon:"cocktails"},
{name: "Politics", value:"Politics", icon:"politics"}
]


export const DURATION_LIST=[
    { value: '1', label: '1 month',displayLabel: '1 Month' },
    { value: '6', label: '6 months',displayLabel: '6 Months' },
    { value: '12', label: '1 year',displayLabel: '1 Year' },
    { value: '24', label: '2 years',displayLabel: '2 Years' }
]

export const PROFESSIONS = [{"value":"Accountant", "label":"Accountant"}, 
{"value":"Actor", "label":"Actor"}, 
{"value":"Actuary", "label":"Actuary"}, 
{"value":"Administrative Assistant", "label":"Administrative Assistant"}, 
{"value":"Agriculturist", "label":"Agriculturist"}, 
{"value":"Air Traffic Controller", "label":"Air Traffic Controller"}, 
{"value":"Anesthesiologist", "label":"Anesthesiologist"}, 
{"value":"Animal Trainer", "label":"Animal Trainer"}, 
{"value":"Arborist", "label":"Arborist"}, 
{"value":"Architect", "label":"Architect"}, 
{"value":"Armed Forces Servicemember", "label":"Armed Forces Servicemember"}, 
{"value":"Artist", "label":"Artist"}, 
{"value":"Assistant", "label":"Assistant"}, 
{"value":"Astronaut", "label":"Astronaut"}, 
{"value":"Astronomer", "label":"Astronomer"}, 
{"value":"Athlete", "label":"Athlete"}, 
{"value":"Attendant", "label":"Attendant"}, 
{"value":"Audiologist", "label":"Audiologist"}, 
{"value":"Author", "label":"Author"}, 
{"value":"Bartender", "label":"Bartender"}, 
{"value":"Beautician", "label":"Beautician"}, 
{"value":"Biologist", "label":"Biologist"}, 
{"value":"Botanist", "label":"Botanist"}, 
{"value":"Business Owner", "label":"Business Owner"}, 
{"value":"Cafeteria Worker", "label":"Cafeteria Worker"}, 
{"value":"Captain", "label":"Captain"}, 
{"value":"Carpenter", "label":"Carpenter"}, 
{"value":"Cashier", "label":"Cashier"}, 
{"value":"Certified Nurse Midwife", "label":"Certified Nurse Midwife"}, 
{"value":"Certified Nursing Assistant", "label":"Certified Nursing Assistant"}, 
{"value":"Chef", "label":"Chef"}, 
{"value":"Chemist", "label":"Chemist"}, 
{"value":"Chief Executive Officer", "label":"Chief Executive Officer"}, 
{"value":"Chief Financial Officer", "label":"Chief Financial Officer"}, 
{"value":"Child Care Provider", "label":"Child Care Provider"}, 
{"value":"Chiropractor", "label":"Chiropractor"}, 
{"value":"Civil Engineer", "label":"Civil Engineer"}, 
{"value":"Clergy", "label":"Clergy"}, 
{"value":"Clerk", "label":"Clerk"}, 
{"value":"Coach", "label":"Coach"}, 
{"value":"Commissioner", "label":"Commissioner"}, 
{"value":"Construction Worker", "label":"Construction Worker"}, 
{"value":"Consultant", "label":"Consultant"}, 
{"value":"Cosmetologist", "label":"Cosmetologist"}, 
{"value":"Counselor", "label":"Counselor"}, 
{"value":"Court Reporter", "label":"Court Reporter"}, 
{"value":"Dental Hygienist", "label":"Dental Hygienist"}, 
{"value":"Dentist", "label":"Dentist"}, 
{"value":"Designer", "label":"Designer"}, 
{"value":"Dietician", "label":"Dietician"}, 
{"value":"Director", "label":"Director"}, 
{"value":"Doctor", "label":"Doctor"}, 
{"value":"Driver", "label":"Driver"}, 
{"value":"Ecologist", "label":"Ecologist"}, 
{"value":"Economist", "label":"Economist"}, 
{"value":"Editor", "label":"Editor"}, 
{"value":"Educator", "label":"Educator"}, 
{"value":"Electrical Worker", "label":"Electrical Worker"}, 
{"value":"Emergency Medical Technician", "label":"Emergency Medical Technician"}, 
{"value":"Engineer", "label":"Engineer"}, 
{"value":"Esthetician", "label":"Esthetician"}, 
{"value":"Farmer", "label":"Farmer"}, 
{"value":"Financial Advisor", "label":"Financial Advisor"}, 
{"value":"Firefighter", "label":"Firefighter"}, 
{"value":"Florist", "label":"Florist"}, 
{"value":"Geologist", "label":"Geologist"}, 
{"value":"Graphic Designer", "label":"Graphic Designer"}, 
{"value":"Guidance Counselor", "label":"Guidance Counselor"}, 
{"value":"Gynecologist", "label":"Gynecologist"}, 
{"value":"Hairdresser", "label":"Hairdresser"}, 
{"value":"Horticulturist", "label":"Horticulturist"}, 
{"value":"Human Resources", "label":"Human Resources"}, 
{"value":"Immunologist", "label":"Immunologist"}, 
{"value":"Insurance Agent", "label":"Insurance Agent"}, 
{"value":"Interpreter", "label":"Interpreter"}, 
{"value":"Investor", "label":"Investor"}, 
{"value":"IT Professional", "label":"IT Professional"}, 
{"value":"Janitor", "label":"Janitor"}, 
{"value":"Jeweler", "label":"Jeweler"}, 
{"value":"Journalist", "label":"Journalist"}, 
{"value":"Judge", "label":"Judge"}, 
{"value":"Lawyer", "label":"Lawyer"}, 
{"value":"Librarian", "label":"Librarian"}, 
{"value":"Maintenance Worker", "label":"Maintenance Worker"}, 
{"value":"Makeup Artist", "label":"Makeup Artist"}, 
{"value":"Manager", "label":"Manager"}, 
{"value":"Marketing", "label":"Marketing"}, 
{"value":"Massage Therapist", "label":"Massage Therapist"}, 
{"value":"Mathematician", "label":"Mathematician"}, 
{"value":"Medical Assistant", "label":"Medical Assistant"}, 
{"value":"Meteorologist", "label":"Meteorologist"}, 
{"value":"Mortician", "label":"Mortician"}, 
{"value":"Musician", "label":"Musician"}, 
{"value":"Nail Technician", "label":"Nail Technician"}, 
{"value":"Nanny", "label":"Nanny"}, 
{"value":"Nurse", "label":"Nurse"}, 
{"value":"Obstetrician", "label":"Obstetrician"}, 
{"value":"Occupational Therapist", "label":"Occupational Therapist"}, 
{"value":"Optometrist", "label":"Optometrist"}, 
{"value":"Paleontologist", "label":"Paleontologist"}, 
{"value":"Paralegal", "label":"Paralegal"}, 
{"value":"Park Ranger", "label":"Park Ranger"}, 
{"value":"Pastor", "label":"Pastor"}, 
{"value":"Pathologist", "label":"Pathologist"}, 
{"value":"Pediatrician", "label":"Pediatrician"}, 
{"value":"Personal Assistant", "label":"Personal Assistant"}, 
{"value":"Personal Trainer", "label":"Personal Trainer"}, 
{"value":"Pharmacist", "label":"Pharmacist"}, 
{"value":"Photographer", "label":"Photographer"}, 
{"value":"Physical Therapist", "label":"Physical Therapist"}, 
{"value":"Physician", "label":"Physician"}, 
{"value":"Physician’s Assistant", "label":"Physician’s Assistant"}, 
{"value":"Physicist", "label":"Physicist"}, 
{"value":"Pilot", "label":"Pilot"}, 
{"value":"Police Officer", "label":"Police Officer"}, 
{"value":"Politician", "label":"Politician"}, 
{"value":"Postal Worker", "label":"Postal Worker"}, 
{"value":"President", "label":"President"}, 
{"value":"Priest", "label":"Priest"}, 
{"value":"Principal", "label":"Principal"}, 
{"value":"Producer", "label":"Producer"}, 
{"value":"Professor", "label":"Professor"}, 
{"value":"Programmer", "label":"Programmer"}, 
{"value":"Proofreader", "label":"Proofreader"}, 
{"value":"Proprietor", "label":"Proprietor"}, 
{"value":"Psychiatric Nurse", "label":"Psychiatric Nurse"}, 
{"value":"Psychiatrist", "label":"Psychiatrist"}, 
{"value":"Psychologist", "label":"Psychologist"}, 
{"value":"Radiologist", "label":"Radiologist"}, 
{"value":"Realtor", "label":"Realtor"}, 
{"value":"Repair Worker", "label":"Repair Worker"}, 
{"value":"Reporter", "label":"Reporter"}, 
{"value":"Retail Worker", "label":"Retail Worker"}, 
{"value":"Salesperson", "label":"Salesperson"}, 
{"value":"Scientist", "label":"Scientist"}, 
{"value":"Secretary", "label":"Secretary"}, 
{"value":"Server", "label":"Server"}, 
{"value":"Singer", "label":"Singer"}, 
{"value":"Small-Business Owner", "label":"Small-Business Owner"}, 
{"value":"Social Worker", "label":"Social Worker"}, 
{"value":"Sociologist", "label":"Sociologist"}, 
{"value":"Speech Therapist", "label":"Speech Therapist"}, 
{"value":"Spy", "label":"Spy"}, 
{"value":"Statistician", "label":"Statistician"}, 
{"value":"Stenographer", "label":"Stenographer"}, 
{"value":"Surgeon", "label":"Surgeon"}, 
{"value":"Surveyor", "label":"Surveyor"}, 
{"value":"Tailor", "label":"Tailor"}, 
{"value":"Teacher", "label":"Teacher"}, 
{"value":"Technical Writer", "label":"Technical Writer"}, 
{"value":"Technician", "label":"Technician"}, 
{"value":"Therapist", "label":"Therapist"}, 
{"value":"Tour Guide", "label":"Tour Guide"}, 
{"value":"Trainer", "label":"Trainer"}, 
{"value":"Translator", "label":"Translator"}, 
{"value":"Travel Agent", "label":"Travel Agent"}, 
{"value":"Truck Driver", "label":"Truck Driver"}, 
{"value":"Underwriter", "label":"Underwriter"}, 
{"value":"Veterinarian", "label":"Veterinarian"}, 
{"value":"Videographer", "label":"Videographer"}, 
{"value":"Virologist", "label":"Virologist"}, 
{"value":"Waitstaff", "label":"Waitstaff"}, 
{"value":"Web Designer", "label":"Web Designer"}, 
{"value":"Writer", "label":"Writer"}, 
{"value":"Zookeeper", "label":"Zookeeper"}, 
{"value":"Zoologist", "label":"Zoologist"}] 


export const GENDERS=[
    {"value":"Male", "label":"Male"},
    {"value":"Female", "label":"Female"},
    {"value":"Non-Binary", "label":"Non-Binary"},
    {"value":"Prefer Not to Say", "label":"Prefer Not to Say"},
    {"value":"Other", "label":"Other"}
]

export const SEXUALITY=[
    {"value":"Straight", "label":"Straight"},
    {"value":"Gay", "label":"Gay"},
    {"value":"Lesbian", "label":"Lesbian"},
    {"value":"Bisexual", "label":"Bisexual"},
    {"value":"Prefer Not to Say", "label":"Prefer Not to Say"},
    {"value":"Other", "label":"Other"}
]

export const PETS=[
    {"value":"No", "label":"No"},
    {"value":"Cats ", "label":"Cats"},
    {"value":"Dogs ", "label":"Dogs"},
    {"value":"Rodents ", "label":"Rodents"},
    {"value":"Other ", "label":"Other"},
]

export const RELATIONSHIP=[
    {"value":"Single", "label":"Single"},
    {"value":"Partnered - not buying together ", "label":"Partnered - not buying together"},
    {"value":"Partnered - buying together ", "label":"Partnered - buying together"}
    
]


export const CHILDREN=[
    {"value":0, "label":"0"},
    {"value":1, "label":"1"},
    {"value":2, "label":"2"},
    {"value":3, "label":"3"},
    {"value":4, "label":"4"}
]


export const PREFERRED_LOCATION = [
    { value: 'Urban', label: 'Urban' },
    { value: 'Rural', label: 'Rural' }
]

export const SHARED_SPACES = [
    { value: 'Living Room', label: 'Living room' },
    { value: 'Bathroom', label: 'Bathroom' },
    { value: 'Kitchen', label: 'Kitchen' },
    { value: 'Dining', label: 'Dining' },
    { value: 'Garden', label: 'Garden' }
]

export const HAVE_CARRY_MORTGAGE = [
    { value: 'No', label: 'No' },
    { value: 'Yes', label: 'Yes' }
]

export const CREDIT_SCORE = [
    { value: '300-500', label: '300-500' },
    { value: '500-700', label: '500-700' },
    { value: '700+', label: '700+' },
    { value: 'Prefer Not to Say', label: 'Prefer Not to Say' }
    
]

export const SOURCE = [
    { value: 'Friend', label: 'Friend' },
    { value: 'Website', label: 'Website' },
    { value: 'Newsletter', label: 'Newsletter' },
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Radio_Television', label: 'Radio Television' }
    
]


export const SALARY =[
    {value: "$40K-$59K",label: "$40K-$59K"},
    {value: "$60K-$79K",label: "$60K-$79K"},
    {value: "$80K-$99K",label: "$80K-$99K"},
    {value: "$100K-$119K",label: "$100K-$119K"},
    {value: "$120K-$139K",label: "$120K-$139K"},
    {value: "$140K-$159K",label: "$140K-$159K"},
    {value: "$160K-$179K",label: "$160K-$179K"},
    {value: "$180K-$199K",label: "$180K-$199K"},
    {value: "$200K-$219K",label: "$200K-$219K"},
    {value: "$220K+",label: "$220K+"},
    {value: "Prefer not to disclose",label: "Prefer not to disclose"}
]


export const PREAPPROVED_MORTGAGE=[
    {value: 200000 ,label: "$200K"},
    {value: 250000 ,label: "$250K"},
    {value: 300000 ,label: "$300K"},
    {value: 350000 ,label: "$350K"},
    {value: 400000 ,label: "$400K"},
    {value: 450000 ,label: "$450K"},
    {value: 500000 ,label: "$500K"},
    {value: 550000 ,label: "$550K"},
    {value: 600000 ,label: "$600K"},
    {value: 650000 ,label: "$650K"},
    {value: 700000 ,label: "$700K"},
    {value: 750000 ,label: "$750K"},
    {value: 800000 ,label: "$800K"},
    {value: 850000 ,label: "$850K"},
    {value: 900000 ,label: "$900K"},
    {value: 950000 ,label: "$950K"},

]


export const DOWN_PAYMENT= [
{label: '$20K',value: 20000},
{label: '$40K',value: 40000},
{label: '$60K',value: 60000},
{label: '$80K',value: 80000},
{label: '$100K',value: 100000},
{label: '$120K',value: 120000},
{label: '$140K',value: 140000},
{label: '$160K',value: 160000},
{label: '$180K',value: 180000},
{label: '$200K',value: 200000},
{label: '$200K+',value: 200001},
]

