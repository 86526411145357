import Util from '../util';

const requestNews = (stateFor) => ({
    type: 'REQUEST_NEWS',
    stateFor: stateFor
  });
  
  const receiveNewsFail = () => ({
    type: 'RECEIVE_NEWS_FAIL'
  });
  
  export const receiveNews = (news) => ({
    type: 'RECEIVE_NEWS',
    news
  });
  
  
export const fetchNews = (params, stateFor) => dispatch => {
    dispatch(requestNews(stateFor));
    return Util.apiGetRequest('/news').then(response => {
        if (response.ok) {
            return response.json().then(json => {dispatch(receiveNews(json))});
        } else {
            dispatch(receiveNewsFail());
        }
    })
};