import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import { withRouter } from 'react-router-dom';
import { fetchNotifications, markNotificationsSeen } from '../actions';


const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  notifications: state.notifications,
  notificationsState: state.states.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: () => dispatch(fetchNotifications()),
  markNotificationsSeen: () => dispatch(markNotificationsSeen())
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar));
