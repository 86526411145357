
import React, { Component } from 'react';
import {  Button, Row, Col,Input, Spinner} from 'reactstrap';
import states from '../../../reduxStates';
import Page from '../_Page';
import SearchSuggestions from './SearchSuggestions';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Util from '../../../util'
import Filters from './filters';
import Properties from './Properties';
import _ from 'lodash'


class PropertyIndex extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          openFilters: false,
          openSuggestions: false,
          resultIndex: null,
          searchedData: {},
          clearWait:false,
          filters: {
            minSqft: 100,
            maxSqft: 10000
          },
          searchedText: null
        }
    
        this.toggleFilter = this.toggleFilter.bind(this);
        this.searchText = this.searchText.bind(this);
        this.applyFilter = Util.debounce(this.applyFilter.bind(this),1000);
    }
    
    componentWillUnmount() {
        this.unmounting = true;
    }
    componentDidMount() {
        this.props.fetchProperties({}, 'PROPERTIES');
    }
    
    toggleFilter = () => {
        this.setState({ openFilters: !this.state.openFilters });
    }

    toggleFavorite = (msl_number) =>{
        this.props.toggleFavorite(msl_number, 'TOGGLE_FAVORITE_LIST')
    }

    updateFilters = (obj) =>{
        let filters = {...this.state.filters}
        this.setState({filters: {...filters, ...obj}})
    }
    applyFilter = (searchObj, stateFor) =>{
        if(!_.isEmpty(this.state.searchedData)){
            searchObj = _.merge(searchObj, this.state.searchedData)
        }
        this.props.fetchProperties(searchObj, (stateFor || 'PROPERTIES_FILTERS'))
        .then((res)=>{
            this.setState({openFilters: false, openSuggestions: false, resultIndex: null})
        });
    }
    clearFilters = () =>{
        this.setState({clearWait: true})
        this.props.fetchProperties({},'PROPERTIES_CLEAR_FILTERS')
        .then((res)=>{
            this.setState({clearWait: false, filters: {
                minSqft: 100,
                maxSqft: 10000
            }})
        });
    }

    loadMore = () =>{
        this.applyFilter({...this.state.filters, page: (this.props.page + 1)}, 'PROPERTIES_LOAD_MORE')
    }
    searchByCity = (data, resultIndex) =>{
        let keys = Object.keys(data)
        if(keys.indexOf('mlsNumber') > -1){
            window.open('/properties/'+data['mlsNumber'],'_blank');
        }else{
            this.setState({resultIndex: resultIndex, searchedData: data, searchedText: data[Object.keys(data)[0]]}, ()=>{
                if(!_.isEmpty(this.state.filters)){
                    data = _.merge(data, this.state.filters)
                }
                this.applyFilter(data, 'PROPERTIES_SEARCH_BY_LOCATION')
            })
        }
    }   
    searchText = (txt) => {
        txt= txt.trim()
        if(txt.length === 0 ){
            this.setState({openSuggestions: false, searchedText: txt})
        }else{
            this.setState({openSuggestions: true , searchedText: txt}, ()=>{
                if(txt.length > 2)
                    this.props.fetchSearchSuggestions(txt)
            })
            
        }
    }
    render(){
        const {properties, count, page,totalPages,suggestions,status, stateFor} =this.props
        const loadingIcon =  <i className="fas fa-fw fa-spinner fa-spin"></i> 
        return(
            
            <Page title="PropertyIndex" pageClass="PropertyIndex" container hideTitle background  hideFooter darkMode>
               <Row className="searchbar-container z-index-4">
                    <Col sm={{size: 12}} className='p-0 d-flex'>
                        <div className="search-form"> 
                            <img src="/icons/search.png" alt="icon" className="fa fa-search image"/>
                            <Input 
                             className="form-control form-input"
                             placeholder="Search anything..." 
                             value={this.state.searchedText || ""}
                             onChange={(e)=> this.searchText(e.target.value) }
                            //  defaultValue ={this.state.searchedText || ''}
                            //  defaultValue={(this.state.searchedData) ? [this.state.searchedData.area, this.state.searchedData.city, this.state.searchedData.state].join(",") : null}
                             ></Input>
                            {this.state.openSuggestions && <SearchSuggestions searchedText={this.state.searchedText} resultIndex={this.state.resultIndex} states={states} status={status} stateFor={stateFor} list={suggestions} searchByCity={this.searchByCity}/>}
                        </div>
                        <Button className={"property-rounded-button filter-button "+ (this.state.openFilters ? 'dark' : '')} onClick={this.toggleFilter}>
                            <img src={this.state.openFilters  ? "/icons/filter-white.png" : "/icons/filter.svg"} alt="icon" className='image'/>
                            Filters
                        </Button>
                    </Col>
                   
               </Row>
              
                {(this.props.status === states.FETCHING && stateFor === 'PROPERTIES') ? (
                    <Row className="list-wrapper mt-2">
                        <div className='m-auto pt-5 pb-5'>
                            Fetching properties please wait {loadingIcon}
                        </div>
                    </Row>
                ) : (
                    <Row className="list-wrapper mt-2">
                        {this.state.openFilters &&  <Filters 
                            clearFilters={this.clearFilters} 
                            applyFilters={()=>this.applyFilter(this.state.filters, "PROPERTIES_FILTERS")} 
                            updateFilters={this.updateFilters} 
                            filters={this.state.filters}
                            status={this.props.status}
                            states={states}
                            stateFor={this.props.stateFor}
                            loadingIcon={loadingIcon}
                            clearWait={this.state.clearWait}
                        />}
                    
                        <Col sm={{size: 12}} className="mt-2">
                            <Row className="margin-10">
                                <Col className="text-left p-0">
                                    <span className='results-counter'>
                                        <NumberFormat value={count} displayType={'text'} thousandSeparator={true}  /> Results
                                    </span>
                                    
                                </Col>
                                <Col className="text-right p-0 hide">
                                    <Link to="#" className='property-rounded-button'>
                                        <img src="/icons/Path_230.png" alt="icon" className='image'/>
                                        View Map
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={{size: 12}}  >
                            <Row className="m-0">
                                <Properties 
                                    list ={properties}
                                    toggleFavorite={this.toggleFavorite}
                                ></Properties>
                            </Row>
                        </Col>
                        {(page < totalPages) ? (
                            <Col className="d-flex justify-content-center align-items-center">
                                {status === states.FETCHING && stateFor === 'PROPERTIES_LOAD_MORE' ? (
                                    <span className='mt-5 mb-5'>Loading {loadingIcon}</span>
                                    
                                ) : (
                                    <Button className="property-rounded-button mt-5 mb-5" onClick={this.loadMore}>Load More</Button>
                                )}
                            </Col>
                        ) : null}
                    </Row>
                    
                )}
            </Page>
        )
    }
}
export default PropertyIndex;
