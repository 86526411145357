
import { connect } from 'react-redux';
import ProfileDetails from '../components/pages/Profiles/show';
import {
  fetchProfile,
} from '../actions';

const mapStateToProps = (state) => {
  const locationSelections = state.profile['location_selections_attributes'] ?
    state.profile['location_selections_attributes']
    .map(id => state.locationSelections[id]) :
    [];
  const locationSelection = locationSelections[0] || {};
  const location = (locationSelection.location_id) ?
    state.locations[locationSelection.location_id] :
    {};
  return{
    profile: state.profile,
    profileState: state.states.profile,
    location: location,
    locationSelection: locationSelection,
    locationSelections: state.locationSelections,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchProfile: () => dispatch(fetchProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDetails);