import React, { Component } from 'react';
import { NavLink as RRNavLink, Link } from 'react-router-dom';
import {
  Collapse,
  Navbar as RSNavbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap';
import Notifications from './Notifications';
import MessagesWidget from '../containers/MessagesWidget';

class Navbar extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const adminLinks = this.props.auth.role === 'admin' ?
      <NavItem>
        <NavLink tag={RRNavLink} to="/admin">Admin</NavLink>
      </NavItem> : null;
    const photo = (this.props.profile && this.props.profile.photo) ? this.props.profile.photo.thumb : "";
    const navContent = this.props.auth.loggedIn ? (
      <React.Fragment>
        <Nav navbar>
         
          { adminLinks }
        </Nav>
        <Nav className="ml-auto topbar-wrapper" navbar>
          <NavItem className="my-matched">
            <img src="/icons/star.svg" alt="" />
            <NavLink tag={RRNavLink} to="/partners">
              My Matches
            </NavLink>
          </NavItem> 
          <NavItem className='saved-list'>
            <img src="/icons/heart-in-circle.png" alt="" />
            <NavLink tag={RRNavLink} to="/saved-list">Saved Properties</NavLink>
          </NavItem>
         
          <MessagesWidget />
          <Notifications {...this.props} />
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav  aria-label="User menu" className="profile-pic-name" >
              <img
                className="img-fluid rounded-circle avatar"
                src={photo}
                alt=""
                style={{width: '34px'}}
              />
              <span className="d-sm-none d-lg-inline name-and-caret" >
                {' '} {this.props.profile.first_name}
                <img src="/icons/chevron-down.svg" alt="" className="caret"/>
              </span>
            </DropdownToggle>
            <DropdownMenu right className="rounded-menu">
              <DropdownItem tag={Link} to="/partners" className='my-matched-mobile'>
                My Matches
              </DropdownItem>
              <DropdownItem tag={Link} to="/saved-list" className='my-saved-list-mobile'>
              Saved Properties
              </DropdownItem>
              <DropdownItem className='navbar-divider'>
                <hr />
              </DropdownItem>
              <DropdownItem tag={Link} to="/profile">
                View Profile
              </DropdownItem>
              {/* <DropdownItem divider /> */}
              <DropdownItem tag={Link} to="/edit-profile">
                Edit Profile
              </DropdownItem>
              
              {/* <DropdownItem divider /> */}
              <DropdownItem tag={Link} to="/logout">
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </React.Fragment>
    ) : (
      <Nav className="ml-auto" navbar>
        <NavItem>
          <NavLink tag={RRNavLink} to="/login" exact>Log In</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={RRNavLink} to="/signup" exact>Sign Up</NavLink>
        </NavItem>
      </Nav>
    );

    return (
      <RSNavbar  expand="sm">
      
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar className="text-center flex-wrap justify-content-start">
          { navContent }
        </Collapse>
      </RSNavbar>
    );
  }
}

export default Navbar;
