import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ImpostorAlert extends Component {
  render() {
    return (
      <div className="impostor-alert">
        You are currently impersonating this user. You will not be able to chat while impersonating.
        {' '}
        <Link to="/logout">Logout</Link>
      </div>
    );
  }
}

export default ImpostorAlert;
