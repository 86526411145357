import { connect } from 'react-redux';
import AboutProfileForm from '../components/pages/AboutProfileForm';
import {
  updateProfile,
  saveProfile,
  addAlert
} from '../actions';

const mapStateToProps = (state) => {
  return {
    profileState: state.states.profile,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (name, value) => dispatch(updateProfile(name, value)),
  saveProfile: () => dispatch(saveProfile()),
  addAlert: (alert) => dispatch(addAlert(alert))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutProfileForm);
