import React, { Component } from 'react';
import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import {  Row, Col, Input,Button  } from 'reactstrap';
import HomeTypes from './HomeTypes'
import OptionChoices from './OptionChoices'
import NumberFormat from 'react-number-format';

class Filters extends Component {
    
    render(){
        const options=[
            {
                name: 'Any',
                value: '0'
            },
            {
                name: '1+',
                value: 1
            },
            {
                name: '2+',
                value: 2
            },
            {
                name: '3+',
                value: 3
            },
            {
                name: '4+',
                value: 4
            },
            {
                name: '5+',
                value: 5
            }
        ]
        const homeTypes=[
            {
                name: 'Semi-Detached Homes',
                value: 'Semi-Detached',
            },
            {
                name: 'Attached Row / Townhouses',
                value: 'Att/Row/Twnhouse'
            },
            {
                name: 'Detached Homes',
                value: 'Detached'
            },
            {
                name: 'Condominium',
                value: 'Condo Apt'
            }
        ]
        const filters = this.props.filters
        return(
            <>
                <Col className="filters-container mb-2 p-3 ">
                    <Row>
                        <Col sm={{size: 12}}>
                            <Row>
                                <Col sm={{size: 12}} md={{size: 6}}>
                                    <HomeTypes 
                                        list={homeTypes}
                                        selected={ filters.propertyType || [] }
                                        onChange={(selected)=>{
                                            let propertyType = filters.propertyType ? [...filters.propertyType] : []
                                            if(propertyType.indexOf(selected) > -1){
                                                propertyType.splice(propertyType.indexOf(selected), 1)
                                            }else{
                                                propertyType.push(selected)
                                            }
                                            this.props.updateFilters({propertyType: propertyType})
                                        }}
                                    />
                                    <Row>
                                        <Col sm={12}>
                                            <Row className="mt-4">
                                                <Col sm={12} className="title">
                                                    Price Range <span className='text-muted'>({filters.minPrice ? <NumberFormat value={filters.minPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : 'No Minimum Price'} - {filters.maxPrice ? <NumberFormat value={filters.maxPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : 'No Maximum Price'})</span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2  ">
                                                <Col sm={4} xs={12} className="min-price-txt">
                                                    <Input 
                                                        type='number'
                                                        placeholder="No Minimum Price" 
                                                        value={filters.minPrice || ''}
                                                        onChange={(e)=>{
                                                            this.props.updateFilters({minPrice: e.target.value})
                                                        }}
                                                    ></Input>
                                                </Col>
                                                <Col sm={1} className="d-flex justify-content-center align-items-center p-0 price-dash">
                                                    <img src="/icons/line.png" alt="icon" className='image'/>
                                                </Col>
                                                <Col sm={4} xs={12} className="max-price-txt">
                                                    <Input 
                                                        type='number'
                                                        placeholder="No Maximum Price"
                                                        value={filters.maxPrice || ''}
                                                        onChange={(e)=>{
                                                            this.props.updateFilters({maxPrice: e.target.value})
                                                        }}
                                                    ></Input>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col sm={12} className="title">
                                                    Internal Sqft. <span className='text-muted'>
                                                    ({filters.minSqft && parseInt(filters.minSqft) > 100 ? <NumberFormat value={filters.minSqft} displayType={'text'} thousandSeparator={true} /> : 'No Min sqft. limit'} - {(filters.maxSqft) ? ((parseInt(filters.maxSqft) < 9999) ? (<NumberFormat value={filters.maxSqft} displayType={'text'} thousandSeparator={true} />) : 'No Max sqft. limit') : 'No Max sqft. limit'})
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm={8} className="title">
                                                    <Range
                                                        draggableTrack
                                                        min={ 100 }
                                                        max={ 10000 }
                                                        value={ [ filters.minSqft, filters.maxSqft ] }
                                                        className="sqft-slider"
                                                        onChange={ (sqrtArr)=> this.props.updateFilters({minSqft: sqrtArr[0], maxSqft: sqrtArr[1]}) }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={{size: 12}} md={{size: 6}}>
                                    <OptionChoices 
                                        list={options}
                                        selected={filters.minBeds ? [filters.minBeds] : ['0'] }
                                        header='Bedrooms'
                                        onClick = {(beds) => {this.props.updateFilters({
                                            minBeds: beds
                                        })}}
                                    />
                                    <OptionChoices 
                                        list={options}
                                        selected={filters.minKitchens ? [filters.minKitchens] : ['0'] }
                                        header='Kitchens'
                                        onClick = {(kitchens) => {this.props.updateFilters({
                                            minKitchens: kitchens
                                        })}}
                                    />
                                    <OptionChoices 
                                        list={options}
                                        selected={filters.minBaths ? [filters.minBaths] : ['0'] }
                                        header='Bathrooms'
                                        onClick = {(baths) => {this.props.updateFilters({
                                            minBaths: baths
                                        })}}
                                    />
                                    <OptionChoices 
                                        list={options}
                                        selected={filters.minParkingSpaces ? [filters.minParkingSpaces] : ['0'] }
                                        header='Parking Spots'
                                        onClick = {(parking) => {this.props.updateFilters({
                                            minParkingSpaces: parking
                                        })}}
                                    />
                                </Col>
                            </Row>
                            <Row className="flex-row-reverse mt-3 mr-2">
                                {this.props.status === this.props.states.FETCHING && this.props.stateFor === 'PROPERTIES_FILTERS' ? (
                                    <Button className="property-rounded-button dark pull-right" >Applying {this.props.loadingIcon}</Button>
                                ) : (
                                    <Button className="property-rounded-button dark pull-right" onClick={this.props.applyFilters}>Apply Filters</Button>
                                )}
                                {(this.props.clearWait) ? (
                                    <Button className="property-rounded-button border-0 text-muted pull-right clear-filters" >Clearing Filters {this.props.loadingIcon}</Button>

                                ) :(
                                    <Button className="property-rounded-button border-0 text-muted pull-right clear-filters" onClick={this.props.clearFilters}>Clear Filters</Button>
                                    
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <div className='backdrop'></div>
            </>
        )
    }
}
export default Filters;
