import React, { Component } from "react";
import Slider from "react-slick";

export default class CardSlider extends Component {
  state = { index: 0 };
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.removeLastIndex = this.removeLastIndex.bind(this);
  }
  componentDidMount(){
    let index = localStorage.getItem('lastVisitedIndex')
    console.warn("index ===>",index,this.props.count)
    if(index && this.props.count >= index){
      this.slider.slickGoTo(index)
      // setTimeout(()=>{
      //   localStorage.removeItem('lastVisitedIndex');
      // },3000)
      
    }
   
  }
  removeLastIndex(){
    let index = localStorage.getItem('lastVisitedIndex')
    if(index){
      console.warn("index removed ",index);
      // localStorage.removeItem('lastVisitedIndex')
    }
  }
  next() {
    localStorage.removeItem('lastVisitedIndex')
    this.slider.slickNext();
  }
  previous() {
    localStorage.removeItem('lastVisitedIndex')
    this.slider.slickPrev();
  }
  beforeChange = (prev, next) => {
    if ((prev === next) && (prev !== 0 && next !== 0)){
      let pagination = this.props.pagination
      if(pagination && pagination['current_page']){
        this.props.fetchFellowsByPageNumber(pagination['current_page'] + 1 )

      }
    }
    this.setState({ index: next });
  };
  render() {
    const {current_page, total_pages, previous_page} = this.props.pagination;
    const loadingIcon = this.props.isNextFetching ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;
    const initialSlide = ((current_page  > 1) ? ((current_page - 1 ) * 9) : 0)
    const settings = {
      dots: true,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      // initialSlide: initialSlide,
      waitForAnimate:false,
      beforeChange: (prev, next)=>{this.beforeChange(prev, next,settings)},
      // afterChange:()=>{this.removeLastIndex()},
      onInit: ()=>{
        setTimeout(()=>{
          this.removeLastIndex()
        },1000)
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            // initialSlide: initialSlide,
            infinite: false,
            waitForAnimate:false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            // initialSlide: initialSlide,
            dots: false,
            waitForAnimate:false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // initialSlide: initialSlide,
            waitForAnimate:false,
            dots: false
          }
        }
      ]
    };
    const index = this.state.index;
    return (
      <div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {this.props.children}
        </Slider>
        <br />
        <div style={{ textAlign: "right" }} className="slick-buttons-container">
          {(this.props.count > 1) && <button className="button slick-previous-button mobile"  onClick={this.previous} disabled={(index === 0)}>
            Previous
          </button>}

          {(this.props.count > 3) && <button className="button slick-previous-button desktop" onClick={this.previous} disabled={(index === 0)}>
            Previous
          </button>}

          {(this.props.count > 1) && <button className="button slick-next-button mobile " onClick={this.next} disabled={(current_page && total_pages &&  current_page === total_pages) && ((index + 1) === this.props.count)}>
            Next {loadingIcon}
          </button>}

          {(this.props.count > 3) && <button className="button slick-next-button desktop " onClick={this.next} disabled={(current_page && total_pages &&  current_page === total_pages) && ((index + 3) === this.props.count)}>
            Next {loadingIcon}
          </button>}
        </div>
      </div>
    );
  }
}

