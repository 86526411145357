import React, { Component } from 'react';

import Slider from "react-slick";
import {  CardImg , CardImgOverlay} from 'reactstrap';
import {  Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class ImageSlider extends Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        let settings = {
            dots: false,
            arrows: false,
            autoplay: false,
            lazyLoad: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
        };
        const property = this.props.property
        let placeHolder = ( <CardImg top width="100%" height="100%" src='images/missing-image.png' alt="Card image" className="image" ></CardImg>)
        let imageList = property.images.map((image,index)=>{
            return (
                <CardImg key={"image-"+index} top width="100%" height="100%" src={image} alt="Card image" className="image img-fluid" >
                </CardImg>
                    
                   
            )
        })
        return (
            
                <div  className="image-container" >
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        {property.images.length > 0 && imageList}
                        {property.images.length === 0 && placeHolder}
                    </Slider>
                    <CardImgOverlay >
                        <Row className="m-0 p-2">
                            <a href={"/properties/"+property.msl_number} target='_blank' className='full-width full-height property-link'></a>
                            <Col className="p-0 z-index-4">
                                {property.is_husmates_pick ? (
                                    <span className='husmates-pick'>
                                        <img src="/icons/husmate-picks.png" alt="icon" />
                                        <span className='text'>HUSMATES PICK</span>
                                    </span>
                                ) : null}
                            </Col>
                            <Col className="p-0 ">
                                {(property.is_favorite ) ? (
                                    <span className='favourite text-right' onClick={()=>this.props.toggleFavorite(property.msl_number)}>
                                        <img src="/icons/heart-fill.svg" alt="icon" />
                                    </span>
                                ) : (
                                    <span className='favourite text-right' onClick={()=>this.props.toggleFavorite(property.msl_number)}>
                                        <img src="/icons/heart.svg" alt="icon" />
                                    </span>
                                )}
                            </Col>
                        </Row>
                        {property.images.length > 1 && <Row className="buttons-wrapper">
                            <div className="right-button" onClick={ this.next}>
                                <img src="/icons/right-arrow.svg" alt="left arrow" />
                            </div>
                            <div className="left-button" onClick={ this.previous}>
                                <img src="/icons/left-arrow.svg" alt="left arrow" />
                            </div>

                        </Row>}
                    </CardImgOverlay>
                </div>
        );
    }
}