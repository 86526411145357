import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import Page from './_Page';
import Logo from '../Logo';

class Loading extends Component {
  render() {
    return (
      <Page pageClass="loading" background hideNavbar hideFooter>
        <div className="loading-wrapper">
          <Logo height="100px" />
          <Progress animated value={100} className="progressbar-wrapper my-3"/>
        </div>
      </Page>
    );
  }
}

export default Loading;
