import { connect } from 'react-redux';
import WishLists from '../components/pages/Properties/WishLists.js';
import { fetchWishlistsProperties, toggleFavorite } from '../actions';

const mapStateToProps = state => ({
    properties: state.properties.properties,
    page: state.properties.page,
    count: state.properties.count,
    totalPages: state.properties.totalPages,
    status: state.properties.state,
    stateFor: state.properties.stateFor,
});
  
const mapDispatchToProps = dispatch => ({
  fetchProperties: (params,stateFor) => dispatch(fetchWishlistsProperties(params,stateFor)),
  toggleFavorite: (mslNumber,stateFor) => dispatch(toggleFavorite(mslNumber, stateFor)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishLists);
