import React, { Component } from 'react';
import {
  FormGroup,
  Input,
  Label,
  Col
} from 'reactstrap';
import CityInput from './CityInput';
import QuestionWithTextChoice from './QuestionWithTextChoice';
import {DURATION_LIST} from '../constants'

class LocationForm extends Component {

  handleChange = (event) => this.props.updateLocationSelection({
    ...this.props.locationSelection,
    [event.target.id]: event.target.value
  });

  render() {
    const fetching = this.props.fetching;
    const neighbourhood = this.props.locationSelection.neighbourhood || '';
    const invalid = this.props.errors && this.props.errors.includes('duration');
    return (
        <>
        <FormGroup row className="mt-5 mb-0 pb-0">
          <Label for="coownership" sm={12}>
            <strong className="font-weight-bold">Co-ownership Preferences</strong>
          </Label>
        </FormGroup>
        <FormGroup row>
          <Label for="city" sm={12}>
            Preferred city *
          </Label>
          <Col sm={12}>
            <CityInput
              id="city"
              placeholder="Select a city"
              disabled={fetching}
              locations={this.props.locations}
              location={this.props.location}
              locationSelection={this.props.locationSelection}
              updateLocationSelection={(e)=>{this.props.updateLocationSelection(e)}}
              populatePredictions={this.props.populatePredictions}
            />
             {(this.props.errors && this.props.errors.indexOf("location_selections_attributes") !== -1) ? (
                <p style={{color: "#ee3f34"}}><small>Required</small></p>
              ) : null}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="neighbourhood" sm={12}>
            Preferred neighbourhoods *
          </Label>
          <Col sm={12}>
            <Input
              id="neighbourhood"
              placeholder="e.g. The Annex, The Beaches"
              className="form-control"
              disabled={fetching}
              value={neighbourhood}
              onChange={this.handleChange}
              maxLength="100"
            />
          </Col>
        </FormGroup>
        

        <QuestionWithTextChoice 
          question="When are you hoping to find your dream co-owner? *"
          value={this.props.duration}
          options={DURATION_LIST}
          handleChange={(choice) => this.props.handleChange('duration', choice)}
          invalid={invalid}
          disabled={fetching}
        />
      </>
    );
  }
}

export default LocationForm;
