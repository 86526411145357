import { connect } from 'react-redux';
import {
  fetchAdminAnswers,
  fetchAdminProfiles,
  fetchAdminQuestions,
  fetchUsers
} from '../../actions';
import Answers from '../components/Answers';

const mapStateToProps = (state) => ({
  answers: state.adminAnswers.items,
  answerState: state.adminAnswers.state,
  profiles: state.adminProfiles.items,
  profilesState: state.adminProfiles.state,
  questions: state.adminQuestions.items,
  questionsState: state.adminQuestions.state,
  users: state.adminUsers.items,
  usersState: state.adminUsers.state,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAnswers: () => dispatch(fetchAdminAnswers()),
  fetchProfiles: () => dispatch(fetchAdminProfiles()),
  fetchQuestions: () => dispatch(fetchAdminQuestions()),
  fetchUsers: () => dispatch(fetchUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Answers);
