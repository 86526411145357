import { LS_TMP_ID } from './common';
import { updateProfile } from './profile';

/**
 * User's location selection
 */

export const updateLocationSelection = (locationSelection) => ({
  type: 'UPDATE',
  payload: locationSelection,
  meta: {
    type: 'locationSelections'
  }
});

export const addLocationSelection = (locationSelection) => (dispatch, getState) => {
  const lsList = getState().profile['location_selections_attributes'];

  locationSelection.id = LS_TMP_ID;
  lsList.push(LS_TMP_ID);

  dispatch(updateLocationSelection(locationSelection));
  dispatch(updateProfile('location_selections_attributes', lsList));
};

