export const types = [
  'fellows',
  'fellowships',
  'conversations',
  'homeSurveyAnswers',
  'homeSurveyQuestions',
  'locationSelections',
  'locations',
  'messages',
  'notifications',
  'pagination'
];

export const common = (type) => (state = {}, action) => {
  if (!action.meta || action.meta.type !== type) {
    return state;
  }

  switch (action.type) {
    case 'CLEAR':
      return {};
    case 'RECEIVE':
      if (action.payload) {
        return {
          ...state,
          ...action.payload
        }
      } else {
        return state;
      }
    case 'UPDATE':
      return {
        ...state,
        [action.payload.id]: action.payload
      };
    default:
      return state;
  }
}


