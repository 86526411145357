import { fellow as fellowSchema, pagination } from '../schema';
import { fetchEntity } from './common';

/**
 * Others' Profiles
 */

export const fetchFellows = (page) => fetchEntity(
  'fellows',
  {'profiles': [fellowSchema], 'pagination': pagination},
  `/profiles?page=${page || 1}`
);

export const updateFellow = (fellow) => ({
  type: 'UPDATE',
  payload: fellow,
  meta: {
    type: 'fellows'
  }
});

export const clearFellows = () => ({
  type: 'CLEAR',
  meta: {
    type: 'fellows'
  }
});
