import React, { Component } from 'react';
import states from '../../reduxStates';
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col
} from 'reactstrap';
import ReactTable from 'react-table';
import AddMatch from './AddMatch';
import EditMatch from './EditMatch';

class Matches extends Component {
  constructor(props) {
    super(props);

    if (this.props.matchState === states.NEW) {
      this.props.fetchMatches();
    }

    this.state = {
      filteredCount: null
    };

    this.tableOptions = {
      filters: {
        boolean: (
          ({ onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}>
              <option value="">All</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
          )
        )
      },
      formatters: {
        tostring: (
          d => d.value.toString()
        ),
        datetime: (
          d => new Date(d.value).toLocaleString("en-CA", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit'
          })
        ),
        number: (
          d => (
            <div className="text-right">
              { Number(d.value).toLocaleString('en-CA') }
            </div>
          )
        )
      }
    }
  }

  updateFilteredCount = () => {
    if (this.tableRef.state.filtered.length > 0) {
      this.setState({
        filteredCount: this.tableRef.state.sortedData.length
      });
    } else {
      this.setState({
        filteredCount: null
      });
    }
  };

  render() {
    const data = this.props.matches;
    const count = this.state.filteredCount ? this.state.filteredCount : data.length;
    const columns = [
      {
        Header: 'id',
        accessor: 'id',
        maxWidth: 50,
      },
      {
        Header: 'First User Id',
        accessor: 'first_user_id',
        maxWidth: 80,
      },
      {
        Header: 'Second User Id',
        accessor: 'second_user_id',
        maxWidth: 80,
      },
      {
        Header: 'Value',
        accessor: 'value',
        Cell: this.tableOptions.formatters.number,
      },
      {
        Header: 'First User Approved',
        accessor: 'first_user_approved',
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean,
      },
      {
        Header: 'Second User Approved',
        accessor: 'second_user_approved',
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean,
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean,
      },
      {
        Header: 'Visible',
        accessor: 'visible',
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean,
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: this.tableOptions.formatters.datetime,
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: this.tableOptions.formatters.datetime,
      },
    ];

    return (
      <React.Fragment>
        <Row>
          <Col className="text-right">
            Count: { count }
          </Col>
        </Row>
        <ReactTable
          ref={(r) => {this.tableRef = r}}
          data={data}
          columns={columns}
          defaultPageSize={20}
          filterable
          onFilteredChange={this.updateFilteredCount}
          className="-striped"
          defaultSorted={[{ id: 'id', desc: true }]}
          SubComponent={row => (
            <EditMatch
              match={row.original}
              fetchMatches={this.props.fetchMatches}
              updateMatch={this.props.updateMatch}
              deleteMatch={this.props.deleteMatch}
            />
          )}
        />
        <Card className="mt-4">
          <CardHeader>New Match</CardHeader>
          <CardBody>
            <AddMatch
              createMatch={this.props.createMatch}
              fetchMatches={this.props.fetchMatches}
            />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Matches;
