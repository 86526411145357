import { connect } from 'react-redux';
import { signInUser } from '../actions';
import LoginForm from '../components/LoginForm';

const mapStateToProps = state => ({
  errors: state.auth.authErrors
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(signInUser(email, password)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
