import React, { Component } from 'react';
import { Button, ButtonGroup, Row,Col  } from 'reactstrap';

class OptionChoices extends Component {
    rednerButtonList = () => {
        let list = []
        if(this.props.list && this.props.list.length){
            list = this.props.list.map((item,index)=>{
                return ( 
                    <Button onClick={()=>this.props.onClick(item.value)} className={"option-item " +((this.props.selected.indexOf(item.value) > -1) ? 'selected' : '')} key={"list-item-"+index}>{item.name}</Button>
                )
            })
        }
        return list
    }
    render(){
        return(
            <Row className='option-choices-wrapper mt-3'>
                <Col sm={12} className="title">
                    {this.props.header}
                </Col>
                <Col sm={12} className="mt-2">
                    <ButtonGroup>
                        {this.rednerButtonList()}
                    </ButtonGroup>
                </Col>
            </Row>
        )
    }
}
export default OptionChoices;