import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Row, Col, Input  } from 'reactstrap';

class HomeTypes extends Component {
    rednerList = () => {
        let list = []
        if(this.props.list && this.props.list.length){
            list = this.props.list.map((item,index)=>{
                return ( 
                    <ListGroupItem className="list-item" key={"list-item-"+index}>
                        <Input type="checkbox" onChange={(e) => this.props.onChange(e.target.value)} checked={(this.props.selected.indexOf(item.value) > -1)} value={item.value}/>{' '}
                        <div className='text'>{item.name}</div>
                    </ListGroupItem>
                )
            })
        }
        return list
    }
    render(){
        return(
            <Row className='home-type-wrapper'>
                <Col sm={12} className="title">
                    Home Type
                </Col>
                <Col sm={12}>
                    <ListGroup className="list-wrapper">
                        {this.rednerList()}
                    </ListGroup>
                </Col>
            </Row>
        )
    }
}
export default HomeTypes;