import React, { Component } from 'react';
import states from '../reduxStates';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import AdminDashboard from '../admin/components/AdminDashboard';
import NotFound from '../containers/NotFound';
import ConfirmEmailSent from './pages/ConfirmEmailSent';
import Dashboard from '../containers/Dashboard';
import EditProfile from '../containers/EditProfile';
import ProfileDetails from '../containers/ProfileDetails';
import ProfileInfo from '../containers/ProfileInfo';
import AssignInviteCode from '../containers/AssignInviteCode';
import Properties from '../containers/Properties';
import WishLists from '../containers/WishLists';
import PropertyDetails from '../containers/PropertyDetails';
import BetaTerms from '../containers/BetaTerms';
import Profiles from '../containers/Profiles';
// import Partners from '../containers/Partners';
import MyMatches from '../containers/MyMatches';
import ConversationDetails from '../containers/ConversationDetails';
import PartnerDetails from '../containers/PartnerDetails';
import Loading from './pages/Loading';
import Login from './pages/Login';
import Logout from '../containers/Logout';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
// import UserSettings from '../containers/UserSettings';
import Util from '../util.js';
import GlobalAlerts from '../containers/GlobalAlerts';
import SidebarContent from "../components/SidebarContent";
import Sidebar from 'react-sidebar'

const mql = window.matchMedia(`(min-width: 800px)`);
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docked: false,
      open: false
    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);

    ReactGA.initialize('UA-126555620-2');
  }

  componentDidMount() {
    mql.addListener(this.mediaQueryChanged);
    if (this.props.location.search) {
      const oauth = this.props.location.pathname === '/oauth-callback';
      const auth = Util.getAuthObjFromURL(this.props.location.search, oauth);
      const keys = Object.keys(auth);
      if (keys.includes('access-token') &&
        keys.includes('client') &&
        keys.includes('uid')) {

        Util.setLocalAuth(
          auth['access-token'],
          auth['client'],
          auth['uid']
        );
      }
    }
    const localAuth = Object.keys(Util.getLocalAuthObj());
    if (localAuth.includes('access-token') &&
      localAuth.includes('client') &&
      localAuth.includes('uid')) {
      this.props.validateToken();
    }
 
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.auth.loggedIn && this.props.location.pathname !== "/reset-password") {
      this.setState({open: mql.matches, docked: mql.matches});
    }else{
      this.setState({open: false, docked: false});
    }
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: (this.props.auth.loggedIn && mql.matches),
      open: false
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });

    if (ev) {
      ev.preventDefault();
    }
  }
  render() {
    const publicRoutes = [
      <Redirect exact from="/" to="/login" />,
      <Route exact path="/login" component={Login} />,
      <Route exact path="/signup" component={Signup} />,
      <Route exact path="/confirm-email-sent" component={ConfirmEmailSent} />,
      <Route exact path="/assign-invite-code" component={AssignInviteCode} />,
      <Route exact path="/profiles/:id" component={Profiles} />,
      <Route exact path="/profile-details/:id" component={ProfileInfo} />
    ];
    const privateRoutes = [
      <Redirect exact from="/" to="/dashboard" />,
      <Route exact path="/dashboard" component={Dashboard} />,
      <Route exact path="/edit-profile" component={EditProfile} />,
      <Route exact path="/profile" component={ProfileDetails} />,
      <Route exact path="/saved-list" component={WishLists} />,
      // <Route exact path="/user-settings" component={UserSettings} />,
      <Route exact path="/logout" component={Logout} />,
      <Route exact path="/properties/:id" component={PropertyDetails} />,
      <Route exact path="/properties" component={Properties} />,
      // <Redirect exact from="/reset-password" to="/" />,
    ];
    const adminRoutes = [
      <Route path="/admin" component={AdminDashboard} />
    ];

    let routes = [
      <Redirect from="/oauth-callback" to={{
        pathname: '/dashboard',
        state: { registered: Util.parseSearchHash(this.props.location.search).oauth_registration }
      }} />,
      <Redirect from="/confirm-email-received" to={{
        pathname: '/dashboard',
        state: { registered: true }
      }} />,
      <Redirect from="/login" to="/" />,
      <Redirect from="/signup" to="/" />,
      <Route component={NotFound} />,
    ];

    if (this.props.auth.loggedIn) {
      routes = privateRoutes.concat(routes);
      if (this.props.auth.role === 'admin') {
        routes = adminRoutes.concat(routes);
      }
      routes = [<Route exact path="/partners" component={MyMatches} />].concat(routes);
      routes = [<Route exact path="/conversations/:id" component={ConversationDetails} />].concat(routes);
      // routes = [<Route exact path="/partners" component={Partners} />].concat(routes);
      // routes = [<Route exact path="/partners/:id" component={Partners} />].concat(routes);
      routes = [<Route exact path="/partner-details/:id" component={PartnerDetails} />].concat(routes);
      routes = [<Route exact path="/profile-details/:id" component={ProfileInfo} />].concat(routes);
      routes = [<Route exact path="/profiles/:id" component={Profiles} />].concat(routes);
      routes = [<Route exact path="/profiles" component={Profiles} />].concat(routes);
      routes = [<Route exact path="/reset-password" component={ResetPassword} />].concat(routes);

      // When user is changing password, redirect all routes except logout to reset-password
      if (this.props.auth.allow_password_change) {
        routes = [
          <Route exact path="/reset-password" component={ResetPassword} />,
          <Route exact path="/logout" component={Logout} />,
          <Redirect to="/reset-password" />,
        ];
      }else if(!this.props.auth.invite_code){
        // routes = [
        //   <Route exact path="/assign-invite-code" component={AssignInviteCode} />,
        //   <Route exact path="/beta_terms/:code" component={BetaTerms} />,
        //   <Redirect to="/assign-invite-code" />,
        // ]
        routes = [
          // <Route exact path="/assign-invite-code" component={AssignInviteCode} />,
          <Route exact path="/beta_terms" component={BetaTerms} />,
          <Redirect to="/beta_terms" />,
        ]
      }
    } else {
      routes = publicRoutes.concat(routes);
    }

    routes = routes.map((r, index) => React.cloneElement(r, {key: index}));
    const content = this.props.auth.state === states.VALIDATING ?
      <Loading /> :
      <>
      {(this.props.auth.loggedIn && this.props.location.pathname !== "/reset-password" && this.props.auth.invite_code) && <button className="navbar-toggler navbar-toggler-right custom-toggler" type="button" onClick={()=>this.onSetOpen(true)}>
          <span className="navbar-toggler-icon"></span>
      </button>}
      <Switch>{routes}</Switch>
      </>;

    const sidebar = (this.props.auth.loggedIn  && this.props.location.pathname !== "/reset-password" && this.props.auth.invite_code) ? <SidebarContent currentPage={this.props.location.pathname} /> : <></>;
    const sidebarProps = {
      sidebar,
      docked: this.state.docked,
      open: this.state.open,
      onSetOpen: this.onSetOpen,
      sidebarClassName: 'sidebar-container'
    };
   
    return (
      <div className="app">
        <Sidebar {...sidebarProps} >
          <a className="sr-only sr-only-focusable" href="#main-content">
            Skip to main content
          </a>
          <GlobalAlerts impostorId={this.props.auth.impostor_id} />
          { content }
        </Sidebar>
      </div>
    );
  }
}

export default App;
