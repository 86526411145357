import React, { Component } from 'react';
import { Label, Input } from 'reactstrap';

class FloatingLabelInput extends Component {

  render() {
    return (
      <div className="floating-label-input">
        <Input {...this.props} />
        <Label for={this.props.id}>{this.props.placeholder}</Label>
      </div>
    );
  }
}

export default FloatingLabelInput;