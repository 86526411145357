import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Button
} from 'reactstrap';
import FloatingLabelInput from '../../components/utils/FloatingLabelInput';
import SimpleModal from '../../components/utils/SimpleModal';

class EditMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      match: this.props.match,
      editing: false,
      saveModal: false,
      deleteModal: false
    };
  }

  toggleEditing = () => {
    this.setState({
      editing: !this.state.editing
    });
  };

  toggleSaveModal = () => {
    this.setState({
      saveModal: !this.state.saveModal
   });
  };

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
  };

  handleChange = (event) => {
    this.setState({
      match: {
        ...this.state.match,
        [event.target.name]: event.target.value
      }
    });
  };

  handleDelete = (event) => {
    this.setState({
      editing: false
    });
    this.props.deleteMatch(this.state.match).then(() => {
      this.props.fetchMatches();
    });
  };

  handleSave = (event) => {
    this.setState({
      editing: false
    });
    this.props.updateMatch(this.state.match).then(() => {
      this.props.fetchMatches();
    });
  };

  render() {
    const editButton = (
      <Button onClick={this.toggleEditing} color="info" className="mr-2"> 
        { this.state.editing ? "Cancel" : "Edit" }
      </Button>
    );
    const saveButton = this.state.editing ? (
      <Button onClick={this.toggleSaveModal} color="primary" className="mr-2"> 
        Save
      </Button>
    ) : null;

    return (
      <React.Fragment>
        <Form noValidate onSubmit={ (event) => event.preventDefault() }>
          <FormGroup>
            <FloatingLabelInput
              placeholder="First User ID"
              type="number"
              id="first_user_id"
              onChange={this.handleChange}
              name="first_user_id"
              value={this.state.match.first_user_id}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Second User ID"
              type="number"
              id="second_user_id"
              onChange={this.handleChange}
              name="second_user_id"
              value={this.state.match.second_user_id}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Match Value"
              type="number"
              id="value"
              onChange={this.handleChange}
              name="value"
              value={this.state.match.value}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Visible?"
              type="boolean"
              id="visible"
              onChange={this.handleChange}
              name="visible"
              value={this.state.match.visible}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            { editButton }
            { saveButton }
            <Button onClick={this.toggleDeleteModal} color="danger" size="sm"> 
              Delete
            </Button>
          </FormGroup>
        </Form>

        <SimpleModal
          isOpen={this.state.saveModal}
          toggle={this.toggleSaveModal}
          title="Are you sure?"
          buttons={[
            { text: 'Save Match', color: 'success', action: this.handleSave },
            { text: 'Cancel' }
          ]}>
          Users may see this change.
        </SimpleModal>
        <SimpleModal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeleteModal}
          title="Are you sure?"
          buttons={[
            { text: 'Delete Match', color: 'danger', action: this.handleDelete },
            { text: 'Cancel' }
          ]}>
          This change is permanant and cannot be undone.
        </SimpleModal>
      </React.Fragment>
    );
  }
}

export default EditMatch;
