import states from '../reduxStates';

const initialState = {
  state: states.NEW
};

const fellow = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_PARTNER_DETAILS':
        return {
          ...state,
          state: states.FETCHING,
          stateFor: action.stateFor
        };
    case 'RECEIVE_PARTNER_DETAILS_FAIL':
        return {
          ...state,
          state: states.FAILED,
          stateFor: null
        };
    case 'RECEIVE_PARTNER_DETAILS':
        return {
          ...state,
          partnerDetails: action.profile,
          state: states.SAVED,
          stateFor: null
        };
    default:
      return state;
  }
};

export default fellow;
