import React, { Component } from 'react';
import { 
    CardHeader,  
    Row, 
    Col, 
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';

class ChatHead extends Component {
    render() {
        let partnerProfile = this.props.partnerProfile
        const partnerFullName= `${(partnerProfile.first_name ? partnerProfile.first_name : '')} ${(partnerProfile.last_name ? partnerProfile.last_name : '')}`
        
        return (
            <CardHeader>
                <Row className="full-height">
                    <Col xs={1} className="d-flex justify-content-center align-items-center" >
                    <Link to="/partners" className="partners-link"></Link>
                    <img src="/icons/chevron-left.svg" alt=""/>
                    </Col>
                    <Col xs={5} sm={6} className="pl-0 d-flex align-items-center">
                    <img src={(partnerProfile && partnerProfile.photo) ? partnerProfile.photo.thumb : ''} alt="" className='user-image'/>
                    <span className='name'>{partnerFullName}</span>
                    </Col>
                    <Col xs={4} className="pr-0">
                        <Link to={{
                                pathname: `/partner-details/${partnerProfile.profile_id}`,
                                state: {backPath: `conversations/${this.props.cid}`}
                            }}>
                            <img src="/icons/default_image.png" alt="" className='user-image float-right'/>
                        </Link>
                    </Col>
                    <Col xs={1} className="d-flex justify-content-center align-items-center p-0">
                    <UncontrolledDropdown>
                        <DropdownToggle nav  aria-label="menu" className="p-0" >
                            <img src="/icons/three-dots.svg" alt="" />
                        </DropdownToggle>
                        <DropdownMenu right className="rounded-menu">
                            <DropdownItem onClick={this.props.cancelFellowship} className="dropdown-menu-item" >
                            Unmatch
                            </DropdownItem>
                            <a className="dropdown-menu-item dropdown-item"  target="_blank" href={`mailto:admin@husmates.com?subject=Reporting User : ${partnerFullName}&body=Please describe your reason for reporting this user:`}>Report User</a>
                        </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                </Row>
            </CardHeader>
        );
    }
}

export default ChatHead;