import React, { Component } from 'react';
import {
  Button,
  ButtonGroup,
} from 'reactstrap';

class TextChoice extends Component {
  setChoice = (choice) => {
    this.props.setChoice(choice);
  };

  render() {
    return (
      <ButtonGroup style={{verticalAlign: 'baseline'}} className="button-group">
        {this.props.options.map((item, i) => {
          return (
            <Button
              key={i}
              size="sm"
              outline={this.props.value !== item.value}
              color={this.props.invalid ? 'danger' : 'primary'}
              onClick={() => this.setChoice(item.value)}
              disabled={this.props.disabled}
            >
              { item.label }
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}
export default TextChoice;
