import React, { Component } from 'react';
import UpdatePasswordForm from '../../containers/UpdatePasswordForm';
import {
  Card,
  CardBody,
  CardHeader,
  CardColumns,
  CustomInput
} from 'reactstrap';

class UserSettings extends Component {
  toggleMatchable = () => {
    this.props.updateProfile(
      "matchable", !this.props.profile.matchable
    );
    this.props.saveProfile();
  };

  render() {
    return (
      // <Page title="Account Settings" pageClass="user-settings" background hideTitle hideFooter>
        <CardColumns className="mt-4">
          <Card>
            <CardHeader tag="h5">Show me on HusMates</CardHeader>
            <CardBody>
              <CustomInput
                type="switch"
                id="show-me"
                label="Show my profile on HusMates"
                checked={this.props.profile.matchable}
                onChange={this.toggleMatchable}
                // disabled={!this.props.profile.complete}
              />
              <hr />
              <p className="text-muted">
               While turned on, you will be able to find and connect with other HusMates users.
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardHeader tag="h5">Change Password</CardHeader>
            <CardBody>
              <UpdatePasswordForm />
            </CardBody>
          </Card>
          <Card>
            <CardHeader tag="h5">Personal Info</CardHeader>
            <CardBody>
              <p>
                <i className="fas fa-fw fa-xs fa-lock"></i> Name: {this.props.auth.first_name} {this.props.auth.last_name}
              </p>
              <p>
                <i className="fas fa-fw fa-xs fa-lock"></i> Email: {this.props.auth.email}
              </p>
            </CardBody>
          </Card>
        </CardColumns>
      // </Page>
    );
  }
}

export default UserSettings;
