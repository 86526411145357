import React, { Component } from 'react';
import {
    Col,
    Row,
    Form,
    FormGroup,
    Label,
} from 'reactstrap';
import states from '../../reduxStates';
import QuestionWithTextChoice from '../QuestionWithTextChoice';
import QuestionWithMultiChoice from '../QuestionWithMultiChoice';
import SingleSelectDropdown from '../SingleSelectDropdown';
import {PREFERRED_LOCATION,SHARED_SPACES,HAVE_CARRY_MORTGAGE, CREDIT_SCORE, SOURCE, SALARY, PREAPPROVED_MORTGAGE, DOWN_PAYMENT} from '../../constants'

class AboutProfileForm extends Component {

    handleCancel = () => {
        this.props.fetchProfile();
        this.props.history.goBack();
    };

    static validate(profile) {
        let req = [
            "down_payment",
            "have_carry_mortgage"
        ]
        const errors = req.reduce((acc, cur) => {
          if (profile[cur] === null ||
            profile[cur] === undefined ||
            profile[cur] === ''
          ) {
            acc.push(cur);
          }
          return acc;
        }, []);

        // console.log(errors)
        return errors;
    }

    handleChange = (key, value) => {
        this.props.updateProfile(key, value)
    }

    render() {
        const fetching = (this.props.profileState === states.FETCHING) ? true : false;
        let {
            preferred_location,
            shared_spaces,
            have_carry_mortgage,
            down_payment,
            source,
            salary,
            preapproved_mortgage,
            credit_score,
            

        } = this.props.profile
        const {errors} = this.props
        return (
            <Form noValidate>
                <Row className="about-container">
                    <Col sm={12} md={6}>
                        <br/>
                        <h4>Tell us more about yourself</h4>
                        <p className="subtitle">
                            Answering these questions will help you find a better match on HusMates and will give us an idea of exactly what you’re looking for
                        </p>
                        <span className="font-weight-bold font-17">(These will not be displayed on your profile)</span>
                        <br />
                        <br />
                        <QuestionWithTextChoice
                            question="1. Do you prefer an urban or rural location?"
                            value={preferred_location}
                            options={PREFERRED_LOCATION}
                            handleChange={(choice) => this.handleChange('preferred_location', choice)}
                            invalid={errors && errors.includes('preferred_location')}
                            disabled={fetching}
                        />
                        <br />
                        <QuestionWithMultiChoice
                            question="2. What spaces would you share?"
                            value={shared_spaces}
                            options={SHARED_SPACES}
                            handleChange={(choice) => this.handleChange('shared_spaces', choice)}
                            invalid={errors && errors.includes('shared_spaces')}
                            disabled={fetching}
                        />
                        <br />

                        <QuestionWithTextChoice
                            question="3. Can you carry a monthly mortgage payment? *"
                            value={have_carry_mortgage}
                            options={HAVE_CARRY_MORTGAGE}
                            handleChange={(choice) => this.handleChange('have_carry_mortgage', choice)}
                            invalid={errors && errors.includes('have_carry_mortgage')}
                            disabled={fetching}
                        />
                        <br />
                        <FormGroup row  >
                            <Label sm={12}>4. Credit Score</Label>
                            <Col sm={12}>
                                <SingleSelectDropdown
                                    value={credit_score}
                                    useGivenList={true}
                                    options={CREDIT_SCORE}
                                    setChoice={(credit_score) => this.handleChange('credit_score', credit_score)}
                                    invalid={errors && errors.includes('credit_score')}
                                    disabled={fetching}
                                />
                            </Col>
                        </FormGroup>
                        <br />
                        <FormGroup row  >
                            <Label sm={12}>5. What is your Salary?</Label>
                            <Col sm={12}>
                                <SingleSelectDropdown
                                    value={salary}
                                    useGivenList={true}
                                    options={SALARY}
                                    setChoice={(salary) => this.handleChange('salary', salary)}
                                    invalid={errors && errors.includes('salary')}
                                    disabled={fetching}
                                />
                            </Col>
                        </FormGroup>
                        <br />
                        <FormGroup row  >
                            <Label sm={12}>6. If you've been pre-approved for a mortgage, what is the amount?</Label>
                            <Col sm={12}>
                                <SingleSelectDropdown
                                    value={preapproved_mortgage}
                                    useGivenList={true}
                                    options={PREAPPROVED_MORTGAGE}
                                    setChoice={(preapproved_mortgage) => this.handleChange('preapproved_mortgage', preapproved_mortgage)}
                                    invalid={errors && errors.includes('preapproved_mortgage')}
                                    disabled={fetching}
                                />
                            </Col>
                        </FormGroup>
                        <br />
                        <FormGroup row  >
                            <Label sm={12}>7. What down payment do you have saved? *</Label>
                            <Col sm={12}>
                                <SingleSelectDropdown
                                    value={down_payment}
                                    useGivenList={true}
                                    options={DOWN_PAYMENT}
                                    setChoice={(down_payment) => this.handleChange('down_payment', down_payment)}
                                    invalid={errors && errors.includes('down_payment')}
                                    disabled={fetching}
                                />
                            </Col>
                        </FormGroup>
                        <br />
                        <FormGroup row  >
                            <Label sm={12}>8. How did you hear about the concept for co-owning property?</Label>
                            <Col sm={12}>
                                <SingleSelectDropdown
                                    value={source}
                                    useGivenList={true}
                                    options={SOURCE}
                                    setChoice={(source) => this.handleChange('source', source)}
                                    invalid={errors && errors.includes('source')}
                                    disabled={fetching}
                                />
                            </Col>
                        </FormGroup>
                       
                    </Col>
                </Row>
                <br />
            </Form>
          
        )
    }
}

export default AboutProfileForm