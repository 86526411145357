import { connect } from 'react-redux';
import AssignInviteCode from '../components/pages/AssignInviteCode';
import { validateInviteCode } from '../actions';

const mapStateToProps = state => ({
    errors: state.auth.inviteErrors,
    user: state.auth
});
  
const mapDispatchToProps = dispatch => ({
  validateInviteCode: (code) => dispatch(validateInviteCode(code)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignInviteCode);
