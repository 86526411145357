const initialState = [];

const alerts = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ALERT':
      return [
        ...state,
        action.payload
      ];
    case 'REMOVE_ALERT':
      return state.filter((item, i) => (
        i !== action.payload
      ));
    case 'UPDATE_ALERT':
      return state.map((item, i) => {
        if (i !== action.payload.index) return item;
        return {
          ...item,
          ...action.payload.data
        };
      });
    case 'CLEAR_ALERTS':
      return initialState;
    default:
      return state;
  }
};

export default alerts;
