import Util from '../util';
import { normalize, denormalize } from 'normalizr';
import states from '../reduxStates';
import ActionCable from 'actioncable';
import { sub as messagesSub, unsub as messagesUnsub } from './messages';
import { sub as notificationsSub, unsub as notificationsUnsub } from './notifications';

export const LS_TMP_ID = -1;

export const fetchEntity = (
  type,
  schema,
  path = `/${Util.snakeCase(type)}`
) => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST',
    meta: { type }
  });

  return Util.apiGetRequest(path).then(response => {
    if (response.status === 204) {
      dispatch({
        type: 'RECEIVE',
        meta: { type }
      });
    } else if (response.ok) {
      return response.json().then(json =>processEntity(dispatch, type, schema, json) );
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: { type }
      });
    }
    return null;
  });
};

export const processEntity = (dispatch, type, schema, entity) => {
  const { result, entities } = normalize(entity, schema);
  Object.keys(entities).forEach(entity => {
    let payload= undefined
    if(entity === 'pagination'){
      payload= entities[entity][Object.keys(entities[entity])[0]]
    }else{
      payload= entities[entity]
    }
    dispatch({
      type: 'RECEIVE',
      payload: payload,
      meta: { type: entity }
    });
  });

  /**
   * Need to dispatch an empty receive in case the entities object was empty so we
   * don't leave the state stuck as 'fetching'.
   */
  dispatch({
    type: 'RECEIVE',
    meta: { type }
  });

  /**
   * The user's own profile is not an entity which gets normalized,
   * so we need to process it separately here.
   */
  if (type === "profile") {
    dispatch({
      type: 'RECEIVE',
      payload: result,
      meta: { type }
    });
  }
  return result;
};

export const saveEntity = (
  type,
  schema,
  path = `/${Util.snakeCase(type)}`
) => (dispatch, getState) => {
  const entity = getState()[type];
  const entities = getState();
  const typeSnake = Util.snakeCase(type);
  let denormalized = denormalize(entity, schema, entities);

  /**
   * Need to check whether we have created a new locationSelection and
   * remove its tmp id before saving.
   * TODO improve how this works
   */
  if (denormalized.location_selections_attributes) {
    denormalized.location_selections_attributes.forEach(selection => {
      if (selection.id === LS_TMP_ID) {
        delete selection.id;
      }
    });
  }

  const action = entity.state === states.NEW ? 'POST' : 'PUT';

  dispatch({
    type: 'REQUEST',
    meta: { type }
  });
  return Util.apiRequest(
    action,
    path,
    null,
    {[typeSnake]: denormalized}
  ).then(response => {
    if (response.ok) {
      return response.json().then(json =>
        processEntity(dispatch, type, schema, json));
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: { type }
      });
    }
  });
};

/**
 * Websocket functions
 */
var cable = null;

export const openWebsockets = (dispatch, getState) => {
  cable = ActionCable.createConsumer(process.env.REACT_APP_API_WEBSOCKET);
  messagesSub(cable, dispatch, getState);
  notificationsSub(cable, dispatch, getState);
};

export const closeWebsockets = () => {
  if (cable) {
    messagesUnsub();
    notificationsUnsub();
    cable.connection.webSocket.close();
    cable = null;
  }
};
