import { connect } from 'react-redux';
import { fetchAdminProfiles } from '../../actions';
import Profiles from '../components/Profiles';

const mapStateToProps = (state) => ({
  profiles: state.adminProfiles.items,
  profileState: state.adminProfiles.state,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProfiles: () => dispatch(fetchAdminProfiles()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profiles);
