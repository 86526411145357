import React, { Component } from "react";
import NumberFormat from 'react-number-format';

import Select from "react-select";
export default class NestedSingleSelect extends Component {
  handleChange = (option) =>{
    let data={}
    data[option['key']] =option['value'] 
    console.log(data)
    this.props.searchByCity(data)
  }
  render() {
    return (
        <Select 
            options={this.props.options} 
            onChange={this.handleChange}
            defaultMenuIsOpen={true}
            className="nested-select"
            classNamePrefix="nest"
            closeMenuOnSelect={false}
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
            formatOptionLabel={function(data) {
              return (
                  <div dangerouslySetInnerHTML={{ __html: data.label }} />
              );
          }}
        />
      
    );
  }
}

