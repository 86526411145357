import React, { Component } from 'react';

import { 
  Card,  
  CardBody,
  Row, 
  Col, 
  Button 
} from 'reactstrap';

import Page from '../_Page';
import states from '../../../reduxStates';
import Chat from '../../../containers/Chat';
import ChatHead from './ChatHead';
import CleanChat from '../../CleanChat';
import SnapchatThread from '../../SnapchatThread';

class ConversationDetails extends Component {
    constructor(props) {
        super(props)

    }
    componentDidMount(){
        if(this.props.match.params.id){
            this.props.fetchConversationMessages(this.props.match.params.id)
        }
    }
    cancelFellowship = () => {
        // this.setState({ waiting: true });
        var fellowshipId = this.props.fellowship_id
        if(fellowshipId){
            this.props.cancelFellowship(fellowshipId).then(()=> {
              this.props.history.push('/partners')
            });
        }
    };
    
     
    requestMeetClick = (id) => {
        // this.setState({ waiting: true });
        this.props.sendRequestMeetup(id)
        
    };

    meetRequestSection = () =>{
        let section=[]
        let textButton = this.getTextAndButton()
        
        section = (
            <Row className="send-request-container">
                <Col xs={6} sm={8} className="d-flex align-items-center">
                    {textButton[0]}
                </Col>
                {(textButton[1]) ? (
                    <Col xs={6}  sm={4}>
                        {textButton[1]}
                    </Col>
                ) : null}
                
            </Row>
        )
        return section
    }
    getTextAndButton = () =>{
        let {request_meet, profile, partner_profile, state , stateFor} = this.props

        const partnerFullName= `${(partner_profile.first_name ? partner_profile.first_name : '')} ${(partner_profile.last_name ? partner_profile.last_name : '')}`
        let message = null
        let button = null
        let profileId= profile.id
        const requestIcon = (state === states.FETCHING && stateFor === 'requestMeet')  ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;
        if(!request_meet){
            // no request meet
            message = `Send a request to meet with ${partnerFullName}.`
            button = <Button className="theme-button btn btn-secondary req-button float-right " onClick={()=>this.requestMeetClick(partner_profile.profile_id)}>{requestIcon}Request Meet</Button>
        }else if(request_meet.sender_id === profileId && request_meet.accepted === false){
            // request_meet sent by current_user but not accepted
            message = `You’ve requested to meet ${partnerFullName}.`
            button = <Button className="theme-button btn btn-secondary meet-requested-button float-right " >Meet Requested</Button>
        }else if(request_meet.receiver_id === profileId && request_meet.accepted === false){
            // request_meet sent by partner but not accepted
            message =`${partnerFullName} wants to meet you. If you accept, we’ll connect you in person`
            button= <Button className="theme-button btn btn-secondary req-button float-right " onClick={()=>this.requestMeetClick(partner_profile.profile_id)}>{requestIcon}Accept</Button>
        }else if(request_meet.sender_id === profileId && request_meet.accepted === true){
            // request sent by current_user and accepted
            message = `${partnerFullName} has accepted your request! The Husmates team will get in touch with you both`
        }else if(request_meet.receiver_id === profileId && request_meet.accepted === true){
            // request sent by partner and accepted
            message = 'you have accepted the request! The Husmates team will get in touch with you both'
        }
        return [<span className="req-message">{message}</span>, button]
    }

    render() {
        let {id, partner_profile, state, stateFor}=this.props
        return (
            <Page title="Conversations" pageClass="conversations" hideTitle hideFooter={true}>
              <Row>
                    <Col sm={12} md={{size: 6, offset: 3}}>
                    {state === states.FETCHING && stateFor === 'conversation' ? (
                        <Card className='chat-container'>
                           
                            <CardBody className="p-0">
                                <SnapchatThread width={'100%'}/>
                                <CleanChat width={'100%'}/>
                                <CleanChat width={'100%'}/>
                                <CleanChat width={'100%'}/>
                            </CardBody>
                            
                        </Card>  
                    ) : (
                        <Card className='chat-container'>
                            <ChatHead
                                partnerProfile = {partner_profile}
                                cancelFellowship= {this.cancelFellowship}
                                cid={id}
                            />
                            <CardBody className="p-0">
                                {this.meetRequestSection()}
                                <Chat></Chat>
                            </CardBody>
                        </Card>
                    ) }
                    </Col>
               </Row>
            </Page>
        );
    }
}


export default ConversationDetails;