import React, { Component } from 'react';
import states from '../../reduxStates';
import UserModal from './UserModal';
import ReactTable from 'react-table';
import {
  Progress,
  CustomInput,
  Row,
  Col
} from 'reactstrap';

class Answers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showArchived: true,
      filteredCount: null
    };

    if (this.props.answerState === states.NEW) {
      this.props.fetchAnswers();
    }

    if (this.props.profilesState === states.NEW) {
      this.props.fetchProfiles();
    }

    if (this.props.usersState === states.NEW) {
      this.props.fetchUsers();
    }

    if (this.props.questionsState === states.NEW) {
      this.props.fetchQuestions();
    }

    this.tableOptions = {
      filters: {
        boolean: (
          ({ onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}>
              <option value="">All</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
          )
        )
      },
      formatters: {
        tostring: (
          d => d.value.toString()
        ),
        questionAnswer: (
          d => (
            <Progress value={d.value} max="7" className="progress-notransition">
              <span className="p-1">
                {d.value}
              </span>
            </Progress>
          )
        )
      }
    }
  }

  toggleShowArchived = () => {
    this.setState({
      showArchived: !this.state.showArchived
    });
  };

  updateFilteredCount = () => {
    if (this.tableRef.state.filtered.length > 0) {
      this.setState({
        filteredCount: this.tableRef.state.sortedData.length
      });
    } else {
      this.setState({
        filteredCount: null
      });
    }
  };

  render() {
    const answersByUser = this.props.answers.reduce((users, item) => {
      if (!users[item.user_id]) {
        users[item.user_id] = {
          user_id: item.user_id,
          answers: { 
            [item.home_survey_question_id]: item.answer
          }
        };
      } else {
        users[item.user_id].answers[item.home_survey_question_id] = item.answer;
      }
      return users;
    }, {});

    const answerColumns = this.props.questions.reduce((columns, item) => {
      columns.push({
        Header: <small>{item.question} ({item.id})</small>,
        accessor: `answers.${item.id}`,
        Cell: this.tableOptions.formatters.questionAnswer,
        show: item.archived ? this.state.showArchived : true
      });
      return columns;
    }, []);

    const staticColumns = [
      {
        Header: 'user id',
        accessor: 'user_id',
        maxWidth: 50
      },
      { 
        Header: 'Matchable',
        id: 'matchable',
        accessor: row => {
          const profile = this.props.profiles.find(p => p.user_id === row.user_id);
          return profile ? profile.matchable : false;
        },
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean
      }
    ];

    const data = Object.values(answersByUser);
    const count = this.state.filteredCount ? this.state.filteredCount : data.length;
    const columns = staticColumns.concat(answerColumns);

    return (
      <React.Fragment>
        <Row>
          <Col xs={9}>
            <CustomInput
              type="checkbox"
              id="showArchived"
              label="Show archived questions"
              className="mb-3"
              checked={this.state.showArchived}
              onChange={this.toggleShowArchived}
            />
          </Col>
          <Col xs={3} className="text-right">
            Count: { count }
          </Col>
        </Row>
        <ReactTable
          ref={(r) => {this.tableRef = r}}
          data={data}
          columns={columns}
          defaultPageSize={20}
          filterable
          onFilteredChange={this.updateFilteredCount}
          className="-striped"
          defaultSorted={[{ id: 'user_id', desc: true }]}
          SubComponent={row => (
            <div className="p-3">
              <UserModal
                profile={
                  this.props.profiles.find(p => p.user_id === row.original.user_id) || {}
                }
              />
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}

export default Answers;
