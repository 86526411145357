import { connect } from 'react-redux';
import Properties from '../components/pages/Properties/Index.js';
import { fetchProperties, toggleFavorite, fetchSearchSuggestions } from '../actions';

const mapStateToProps = state => ({
    properties: state.properties.properties,
    page: state.properties.page,
    count: state.properties.count,
    totalPages: state.properties.totalPages,
    status: state.properties.state,
    stateFor: state.properties.stateFor,
    suggestions: state.properties.suggestions
});
  
const mapDispatchToProps = dispatch => ({
  fetchProperties: (params,stateFor) => dispatch(fetchProperties(params,stateFor)),
  toggleFavorite: (mslNumber,stateFor) => dispatch(toggleFavorite(mslNumber, stateFor)),
  fetchSearchSuggestions: (search) => dispatch(fetchSearchSuggestions({search: search}))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Properties);
