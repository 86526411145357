import { connect } from 'react-redux';
import MessagesWidget from '../components/MessagesWidget';
import { fetchUnreadCount } from '../actions';
import { withRouter } from 'react-router-dom';


const mapStateToProps = (state) => {
  return {
    unreadCount: (state.conversationMessages) ? state.conversationMessages.unreadCount : 0
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUnreadCount: () => dispatch(fetchUnreadCount()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesWidget));
