import states from '../reduxStates';

const initialState = {
  state: states.NEW
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_REGISTER':
      return {
        ...state,
        registerErrors: [],
        state: states.FETCHING
      };
    case 'RECEIVE_REGISTER_FAIL':
      return {
        ...state,
        ...action,
        state: states.FAILED
      };
    case 'REQUEST_VALIDATE':
      return {
        ...state,
        authErrors: [],
        state: states.VALIDATING
      };
    case 'REQUEST_SIGN_IN':
      return {
        ...state,
        authErrors: [],
        state: states.FETCHING
      };
    case 'RECEIVE_SIGN_IN':
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        state: states.SAVED
      };
    case 'RECEIVE_SIGN_IN_FAIL':
      return {
        ...state,
        ...action,
        state: states.FAILED
      };
    case 'REQUEST_FORGOT_PASSWORD':
      return {
        ...state,
        error: null,
        forgotPasswordState: states.FETCHING,
      };
    case 'RECEIVE_FORGOT_PASSWORD':
      return {
        ...state,
        forgotPasswordState: states.SAVED,
      };
    case 'RECEIVE_FORGOT_PASSWORD_FAIL':
      return {
        ...state,
        ...action,
        forgotPasswordState: states.FAILED,
      };
    case 'REQUEST_INVITE_CODE':
      return {
        ...state,
        inviteErrors: [],
        inviteCodeState: states.FETCHING,
      };
    case 'RECEIVE_INVITE_CODE':
      return {
        ...state,
        ...action.payload,
        inviteCodeState: states.SAVED,
      };
    case 'RECEIVE_INVITE_CODE_FAIL':
      return {
        ...state,
        ...action,
        inviteCodeState: states.FAILED,
      };
    case 'REQUEST_VALIDATE_INVITE_CODE':
      return {
        ...state,
        acceptErrors: [],
        validateCodeState: states.FETCHING,
      };
    case 'RECEIVE_VALIDATE_INVITE_CODE':
      return {
        ...state,
        ...action.payload,
        validateCodeState: states.SAVED,
      };
    case 'RECEIVE_VALIDATE_INVITE_CODE_FAIL':
      return {
        ...state,
        ...action,
        validateCodeState: states.FAILED,
      };
    case 'UPDATE_TERMS':
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default auth;
