
import React, { Component } from 'react';
import { Card, Button,  CardText, Row, Col,CardBody,Alert,FormGroup,Input  } from 'reactstrap';
import Page from './_Page';
import {Link} from 'react-router-dom';
import Terms from '../Terms'
class BetaTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waiting: false
        }

    
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
   

    componentWillUnmount() {
        this.unmounting = true;
    }
    
    handleSubmit() {
        // let code = this.props.match.params.code
        let length=6;
        let code= Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
        this.setState({ submitted: true, waiting: true });
        this.props.updateInviteCode(code.toUpperCase())
        .then((response)=> {
            if(response.ok){
                setTimeout(()=>{
                this.props.history.push('/dashboard');
                },100)

            } else if (!this.unmounting) {
                this.setState({ waiting: false });
            }
        });
    }
    
    render(){
        const errors = (this.props.errors) ? 
            this.props.errors.map((e, i) => (<Alert key={`error-${i}`} color="warning">{e}</Alert>)) :
            [];
        const loadingIcon = this.state.waiting ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;
        return(
            <Page title="BetaTerms" pageClass="invitecode" container="fluid" hideTitle background hideNavbar hideFooter darkMode>
                <Row>
                    <Col  sm={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                        <Card>
                            <Row>
                                
                                <Col sm={{ size: 12 }}>
                                    
                                    <CardBody >
                                        <CardText className="" >Terms & Conditions</CardText>
                                        <div className="beta-terms-container">
                                            <Terms/>
                                        </div>
                                        <div className="accept-terms-and-conditions text-right">
                                            <FormGroup check onClick={()=>{this.props.updateTermsConditions(!this.props.termsAgreed)}}>
                                                <Link to="#" className="label">I have read and accept the terms and conditions</Link>
                                                <Input type="checkbox" className="checkbox" checked={this.props.termsAgreed}/>
                                            </FormGroup>
                                        </div>
                                        <div>
                                            <Button className="theme-button float-right" color="brand-blue" outline disabled={!this.props.termsAgreed} onClick={this.handleSubmit}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AGREE {loadingIcon}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                                        </div>
                                        <br/>   
                                        {errors}
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Page>
        )
    }
}
export default BetaTerms;
