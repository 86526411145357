import React, { Component } from 'react';
import {
  Button,
  Spinner,
  CardBody,
  CardImg,
  Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';

class PhotoUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      deleting: false,
      dragging: false,
      error: null
    }
  }

  dropAccepted = (files) => {
    this.setState({ uploading: true,error: null });
    if(files[0].size > 10000000){
      this.setState({ error: 'File size exceeded that 10 MB',uploading: false });
    }else{
      this.props.savePhoto(files[0]).then(() => {
        this.setState({ uploading: false });
      });

    }
  };

  handleDelete = () => {
    this.setState({ deleting: true });
    this.props.deletePhoto().then(() => {
      this.setState({ deleting: false });
    });
  };

  startDrag = () => {
    this.setState({ dragging: true });
    this.dropzoneRef.node.focus();
  };

  endDrag = () => {
    this.setState({ dragging: false });
    this.dropzoneRef.node.blur();
  };

  onClickPickImage = () => {
    if (this.dropzoneRef) {
      this.dropzoneRef.open();
    }
  };

  render() {
    const photoExists = !this.props.photo.placeholder;
    const responsiveClassName = this.props.aspectRatio ?
      `embed-responsive embed-responsive-${this.props.aspectRatio}` : '';
    const disabledClassName = this.props.disabled ?
      'photo-uploader-outer-disabled' : '';
    const draggingClassName = this.state.dragging ?
      'photo-uploader-outer-dragging' : '';
    const propsClassName = this.props.className ? this.props.className : '';

    let classToAdd = (this.props.errors && this.props.showSeperateButtons && this.props.errors.indexOf('photo') > -1) ? 'is-invalid-photo' : '' ;
    let inner;

    if (this.state.uploading) {
      inner = (
        <CardBody className="d-flex h-100">
          <Spinner className="m-auto" type="grow">
            Uploading...
          </Spinner>
        </CardBody>
      );
    } else if (photoExists) {
      inner = (
        <CardImg
          src={this.props.photo.thumb}
          alt="Profile photo"
        />
      );
    } else {
      inner = (
        <CardBody className={"d-flex h-100 flex-column justify-content-center align-items-center "+ classToAdd}>
          <i className="fas fa-upload fa-lg"></i>
          <small className="text-center mt-3">
            UPLOAD PHOTO {(this.props.showSeperateButtons)? '*' : null}
          </small>
        </CardBody>
      );
    }

    return (
      <div
        className={`photo-uploader ${propsClassName}`}
        style={{...this.props.style, maxWidth: this.props.width}}
      >
        <Dropzone
          ref={(r)=>{this.dropzoneRef = r}}
          onDropAccepted={this.dropAccepted}
          onDragEnter={this.startDrag}
          onDragLeave={this.endDrag}
          onDrop={this.endDrag}
          accept="image/png,image/jpeg,image/jpg,image/gif,image/tiff"
          multiple={true}
          disabled={this.props.disabled}
        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps({
              className: `
                photo-uploader-outer
                ${responsiveClassName}
                ${disabledClassName}
                ${draggingClassName}
              `
            })}>
              <input {...getInputProps({
                id: this.props.id,
                disabled: this.props.disabled
              })} />
              <div className="embed-responsive-item">
                { inner }
              </div>
              
            </div>
          )}
        </Dropzone>
        {this.state.error && <div className="full-width text-center" style={{color: "#ee3f34"}}><small>{this.state.error}</small></div>}
        { this.props.showSeperateButtons ? (
          <> 
          <Row className="mt-3 justify-content-center">
              
              <Button className="theme-button btn-sm" color="success" onClick={this.onClickPickImage}>Change Photo</Button>
              <br />
              <div className="text-muted full-width text-center  font-12 mt-2">Max 10MB: jpg, png, gif, tiff</div>
          </Row>
          <Row className="mt-3 justify-content-center">
            <Link to="#"  
              onClick={this.handleDelete} 
              hidden={!photoExists} 
              disabled={this.state.deleting}
              className="delete-photo"
            >DELETE PHOTO</Link>
          </Row>
          </>
        ) : (
          
          <Button
            aria-label="Delete"
            size="sm"
            onClick={this.handleDelete}
            hidden={!photoExists}
            disabled={this.state.deleting}
            className="photo-uploader-delete-button"
           >
            <i className="fas fa-trash-alt"></i>
          </Button>
        )}
      </div>
    );
  }
}

export default PhotoUploader;
