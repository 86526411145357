import React from 'react';
import {NavItem,NavLink} from 'reactstrap';
import {  ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
const MyMatch = (props) => {
    let fellows =[]
    if(props.records.length){
        fellows = props.records.map((item, i) => (
            <NavItem key={i} className= {(props.profile && props.profile.id === item.id) ? "active" : ""}>
              {/* <NavLink
                tag={Link}
                to={`/conversations/${item.id}`}
                className="nav-link-item p-0 border-0"
                style={{color: "#333333"}}
              > */}
               <ListGroupItem tag={Link} to={`/conversations/${item.id}`} key={i} className={"justify-content-between list-item "+((i === 0) ? 'first-child' : '')}>
                <span className='image-wrapper'>
                <img src={item.photo && item.photo.thumb ? item.photo.thumb : ''} alt="image" />
                </span>
                <span className='name'>{item.first_name} {item.last_name}</span> 
                {
                (item.unread_count > 0) ? (
                  <Badge pill className="float-right counter">{item.unread_count}</Badge>
                ) : null
                }
              </ListGroupItem>
              {/* </NavLink> */}
            </NavItem>
          ));
    }else{
        fellows = [<p className="text-center mt-5" key="no-element">
                You don't have any partners yet. <br/> <br/>You can
                {' '}
                <Link to="/profiles" className="find-people-link">Browse Matches</Link>
                {' '}
                and add them to chat!
        </p>]
    }
    return (
        <>
            {fellows}
        </>
    );
};

export default MyMatch;