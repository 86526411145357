
import React, { Component } from 'react';
import {  Col } from 'reactstrap';
import Property from './Property';

class Properties extends Component {
    render(){
        let render_list = (<div><br /><br />No Records found ...<br /><br /></div>)
        if(this.props.list && this.props.list.length){
            render_list =this.props.list.map((data, index)=>{
                return <Col  sm={{ size: 12 }} md={{ size: 6 }} lg={{ size: 4 }} className='mt-2 property-card-container' key={"list-"+index}>
                        <Property 
                        record={data} 
                        toggleFavorite={this.props.toggleFavorite}
                        />
                    </Col>
            })
        }
        return(
            <>
                {render_list}
            </>
        )
    }
}
export default Properties;
