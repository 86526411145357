
import React, { Component } from 'react';
import { Row, Col  } from 'reactstrap';
import _ from 'lodash';

class Rooms extends Component {
    render(){
        let list = []
        if(this.props && this.props.rooms){
            

            const keys = Object.keys(this.props.rooms);
            keys.forEach((key, index) => {
                list.push (
                    <Row className="mt-3" key={'room-' +key}>
                        <Col sm={12} className="detail-header">{key}</Col>
                        <Col sm={12}>
                            {this.props.property.rooms[key].map((record, index)=>{
                                return <Row  className="mt-3 mb-3" key={"rec-"+index}>
                                            <Col sm={4} md={3} className="text-left detail-subheader">
                                            {record.description} 
                                            </Col>
                                            <Col sm={4} md={3} className="text-left detail-subheader">
                                            {record.dimensions} 
                                            </Col>
                                            <Col sm={4} md={6} className="text-left detail-subheader">
                                            {record.features}
                                            </Col>
                                        </Row>
                            })}
                        </Col>
                    </Row>
                )
            });
        }
        return(
            <Row className="mt-2 mb-2">
                <Col sm={12} className='title mb-2 p-0'>Rooms</Col>
                <Col sm={12} className='p-0'>
                        {list}
                </Col>
            </Row>
        )
    }
}
export default Rooms;
