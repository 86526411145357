
import Util from '../util';

const requestMyMatches = () => ({
    type: 'REQUEST_MY_MATCHES'
});
  
const receiveMyMatchesFail = () => ({
  type: 'RECEIVE_MY_MATCHES_FAIL'
});
  
export const receiveMyMatches = (matches) => ({
    type: 'RECEIVE_MY_MATCHES',
    matches: matches
});

export const updateMyMatches = (matches) => ({
    type: 'UPDATE_MY_MATCHES',
    matches: matches
});
  
  
export const fetchMyMatches = () => dispatch => {
  dispatch(requestMyMatches());
  return Util.apiGetRequest('/fellowships/my_matches').then(response => {
      if (response.ok) {
          return response.json().then(json => {dispatch(receiveMyMatches(json))});
      } else {
          dispatch(receiveMyMatchesFail());
      }
  })
};
  