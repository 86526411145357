import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from '../containers/App';
import DocumentTitle from 'react-document-title';

class Root extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <DocumentTitle title="HusMates.com">
          <BrowserRouter>
            <App />
          </BrowserRouter>	
        </DocumentTitle>
      </Provider>
    );
  }
}

export default Root;
