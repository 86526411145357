import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import Logo from './Logo';
import TermsOfService from './TermsOfService';
import SimpleModal from './utils/SimpleModal';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tosModal: false
    };
  }

  tosToggle = () => {
    this.setState({
      tosModal: !this.state.tosModal
    });
  };

  render() {

    const bgClass = this.props.darkMode ? 'bg-dark' : 'bg-light';
    const textClass = this.props.darkMode ? 'text-white-50' : 'text-muted';
    const linkClass = this.props.darkMode ? 'text-light' : '';

    return (
      <footer className={`${bgClass} text-muted text-center text-sm-left`}>
        <Container>
          <Row className="align-items-center">
            <Col sm={4} className="py-3">
              <Logo
                textBeside
                height="30"
                innerColor="transparent"
                outlineColor={this.props.darkMode ? 'rgba(255,255,255,0.5)' : '#6c757d'}
                textColor={this.props.darkMode ? 'rgba(255,255,255,0.5)' : '#6c757d'}
                className="mt-4 mt-sm-0"
              />
            </Col>
            <Col sm={4} className="py-3">
              <small className={`small ${textClass}`}>Links</small>
              <br />
              <a href="https://www.husmates.com/" className={linkClass}>Home</a><br />
              <Link to="#" onClick={this.tosToggle} className={linkClass}>Terms of Service</Link><br />
            </Col>
            <Col sm={4} className="py-3">
              <small className={`small ${textClass}`}>Help / Feedback</small>
              <br />
              <a href="mailto:contact@husmates.com" className={linkClass}>
                contact@husmates.com
              </a>
              <br />
              <a href="https://twitter.com/weownca" target="_blank" rel="noopener noreferrer" className={linkClass}>
                <i className="fab fa-twitter"></i>
                <span className="sr-only">Twitter</span>
              </a>
              <a href="https://facebook.com/weown.ca" target="_blank" rel="noopener noreferrer" className={`${linkClass} ml-1`}>
                <i className="fab fa-facebook"></i>
                <span className="sr-only">Facebook</span>
              </a>
            </Col>
          </Row>
        </Container>

        <SimpleModal
          isOpen={this.state.tosModal}
          toggle={this.tosToggle}
          className="tosModal"
          title="Terms of Service"
          buttons={[{ text: 'Close' }]}>
          <TermsOfService />
        </SimpleModal>

      </footer>
    );
  }
}

export default Footer;
