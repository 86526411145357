import { connect } from 'react-redux';
import BasicProfileForm from '../components/pages/BasicProfileForm';
import {
    fetchProfile,
    saveProfile,
    updateProfile,
    fetchLocations,
    addLocationSelection, 
    updateLocationSelection,
    deleteProfilePhoto,
    saveProfilePhoto,
    saveImageGalleryPhoto,
    deleteImageGalleryPhoto
} from '../actions';
  

const mapStateToProps = (state) => {
  const locationSelections = state.profile['location_selections_attributes'] ?
  state.profile['location_selections_attributes']
  .map(id => state.locationSelections[id]) :
  [];
  const locationSelection = locationSelections[0] || {};
  const location = (locationSelection.location_id) ?
    state.locations[locationSelection.location_id] :
    {};
  return {
    profile: state.profile,
    profileState: state.states.profile, 
    locations: state.locations,
    locationData: location,
    locationSelection: locationSelection,
    locationSelections: state.locationSelections,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (name, value) => dispatch(updateProfile(name, value)),
  deleteProfilePhoto: () => dispatch(deleteProfilePhoto()),
  saveProfilePhoto: (photo) => dispatch(saveProfilePhoto(photo)),
  saveImageGalleryPhoto: (id,photo) => dispatch(saveImageGalleryPhoto(id, photo)),
  deleteImageGalleryPhoto: (id) => dispatch(deleteImageGalleryPhoto(id)),
  updateLocationSelection: (ls) => (ls.id ? dispatch(updateLocationSelection(ls)) :
    dispatch(addLocationSelection(ls))),
  populatePredictions: (input) => dispatch(fetchLocations(input)),
  saveProfile: () => dispatch(saveProfile()),
  fetchProfile: () => dispatch(fetchProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicProfileForm);
