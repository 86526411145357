import React, { Component } from 'react';

import {
    FormGroup,
    Label,
    Col
  } from 'reactstrap';
import MultiChoice from './MultiChoice.js'

class QuestionWithMultiChoice extends Component {
  render() {
    return (
        <FormGroup row >
            <Label for="exampleText" sm={12}>{this.props.question}</Label>
            <Col sm={12}>
                <MultiChoice 
                    value={this.props.value}
                    options={this.props.options}
                    setChoice={this.props.handleChange}
                    invalid={this.props.invalid}
                    disabled={this.props.disabled}
                    
                />
            </Col>
        </FormGroup>
    );
  }
}
export default QuestionWithMultiChoice;
