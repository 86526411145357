import React, { Component } from 'react';
import { Spinner, Row,Col } from 'reactstrap';
import states from '../../../reduxStates';
import Page from '../_Page';
import { Link } from 'react-router-dom';
import ProfileCardSmall from '../../Profiles/ProfileCardSmall';
import ProfileView from './ProfileView';
import _ from 'lodash'
import CardSlider from "../../Profiles/CardSlider";
import Util from '../../../util'
import { Redirect } from 'react-router-dom';
export default class Profiles extends Component {
  componentDidMount() {
    let paginations = localStorage.getItem('pagination')
    if(!paginations){
      this.props.fetchFellows(1);
    }else{
      let currentPage = paginations['current_page'] ? paginations['current_page'] : 1
      this.props.fetchFellows(currentPage).then(()=>{
        localStorage.removeItem('pagination')
      });
    }
  }

  fetchFellowsByPageNumber = (page) =>{
    let index = (((page - 1) * 9 ) + 1)
    localStorage.setItem('lastVisitedIndex', index)
    this.props.fetchFellows(page)
  }
  saveIndex = (index) =>{
    if(index)
      localStorage.setItem('lastVisitedIndex', index)
  }
  render() {
    const localAuth = Object.keys(Util.getLocalAuthObj());
    if(_.isEmpty(localAuth)){
      return (
        <Redirect to={{
          pathname: "/login",
          state: {redirectUrl: this.props.location.pathname}
        }} />
      )
    }else if (!this.props.profile.complete) {
      return (
        <Page title="People looking for housing in your area" pageClass="profiles" hideFooter={true}>
          <p className="text-center">
            You can see other HusMates users once
            you <Link to="/profile" className="complete-profile-link">complete your profile</Link>.
          </p>
        </Page>
      );
    } else if (this.props.fellowsState === states.FETCHING && !this.props.paginations) {
      return (
        <Page title={`People looking for housing in ${this.props.city}`} pageClass="profiles" hideFooter={true}>
          <Spinner className="m-auto" type="grow" />
        </Page>
      );
    } else if (Object.keys(this.props.fellows).length) {
      let fellowshipId = '' + this.props.match.params.id
      fellowshipId = (fellowshipId.indexOf('_') > -1 ) ? fellowshipId : `_${fellowshipId}`
      const fellowProfile = _.find(this.props.fellows, { id: fellowshipId }); 
      if(this.props.match.params.id && !fellowProfile){
        return <Redirect to={`/profile-details/${fellowshipId}`} />

      }if (fellowProfile) {
        const locationSelection = this.props.locationSelections[fellowProfile.location_selections_attributes[0]] || {};
        const location = this.props.locations[locationSelection.location_id] || {};
        const fellowship = fellowProfile.fellowships_attributes ?
        this.props.fellowships[fellowProfile.fellowships_attributes[0]] :
        {};
        return (
          <Page title="" pageClass="profile-show" hideFooter={true}>
              <ProfileView
                locationData={location}
                locationSelectionData={locationSelection}
                profile={fellowProfile}
                createFellowship={(stringId) => this.props.createFellowship(_.last(_.split(stringId,'_')))}
                cancelFellowship={(stringId) => this.props.cancelFellowship(_.last(_.split(stringId,'_')))}
                fellowship={fellowship}
                authProfile={this.props.authState}
                currentProfile={this.props.profile}
              />
          </Page>
        );
      } else {
        const fellows = Object.values(this.props.fellows);
        const path = this.props.match.path.split('/')[1];
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 2
        };

        const fellowCards = fellows
          .map((item, i) => {
            const locationSelection = this.props.locationSelections[item.location_selections_attributes[0]] || {};
            const location = this.props.locations[locationSelection.location_id] || {};
            const matchingIntrest = Util.findMatchingIntrest(item, this.props.profile)
            return (
              <Link
                key={i}
                onClick={()=> this.saveIndex(i)}
                to={`/${path}/${item.id}`}
                className="text-dark card-link"
                style={{textDecoration: "none"}}>
                <ProfileCardSmall
                  profile={item}
                  locationSelection={locationSelection}
                  location={location}
                  interests={matchingIntrest}
                  showCompatibility
                  
                />
              </Link>
              
            );
          });
        return (
          <Page title={""} pageClass="profiles" hideFooter={true}>
            <Row>
              <Col xs={12} md={12}>
                <h5>Matches for you in {this.props.city}</h5>
              </Col>
            </Row>
            
            <Row>
              <Col xs={12} md={11} key={'id' + (new Date()).getTime()}>
                <CardSlider 
                count={fellows.length} 
                pagination={this.props.pagination}
                fetchFellowsByPageNumber={this.fetchFellowsByPageNumber}
                initialSlide={9}
                isNextFetching={(this.props.fellowsState === states.FETCHING)}
                >
                  {fellowCards}
                </CardSlider>
              </Col>
            </Row>
            <p className="text-muted small text-right pt-3 mt-5 hide">
              Inappropriate behaviour?
              Please <a href="mailto:contact@husmates.com">contact us</a>.
            </p>
          </Page>
        );
      }
    } else {
      return (
        <Page title={`People looking for housing in ${this.props.city}`} pageClass="profiles" hideFooter={true}>
          <p className="text-center">
            There is nobody in your area yet. Help spread the word about HusMates!
          </p>
        </Page>
      );
    }
  }
}

