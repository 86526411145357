import states from '../reduxStates';

const initialState = {
  state: states.NEW,
  matches: {}
};

const myMatches = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_MY_MATCHES':
      return {
        ...state,
        state: states.FETCHING
      };
    case 'RECEIVE_MY_MATCHES_FAIL':
      return {
        ...state,
        state: states.FAILED
      };
    case 'RECEIVE_MY_MATCHES':
      return {
        matches: action.matches,
        state: states.SAVED
      };
    case 'UPDATE_MY_MATCHES':
      return {
        matches: Object.assign([], state.matches, action.matches),
        state: states.SAVED
      };
    default:
      return state;
  }
};

export default myMatches;
