import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';

class SimpleModal extends Component {

  actionAndClose = (action) => {
    if (action) {
      action();
      this.props.toggle();
    } else {
      this.props.toggle();
    }
  };

  render() {
    const {
      isOpen,
      toggle,
      className,
      title,
      buttons,
      children
    } = this.props;
    
    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          { title }
        </ModalHeader>
        <ModalBody>
          { children }
        </ModalBody>
        <ModalFooter>
          { buttons.map((item, index) => (
            <Button key={index} color={item.color} onClick={() => { this.actionAndClose(item.action); }}>
              { item.text }
            </Button>
          ))}
        </ModalFooter>
      </Modal>
    );
  }
}

export default SimpleModal;
