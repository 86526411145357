import states from '../reduxStates';

const initialState = {
  state: states.NEW,
  match: {}
};

const match = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_MATCH':
      return {
        ...state,
        state: states.FETCHING
      };
    case 'RECEIVE_MATCH_FAIL':
      return {
        ...state,
        state: states.FAILED
      };
    case 'RECEIVE_MATCH':
      return {
        match: action.match,
        state: states.SAVED
      };
    case 'UPDATE_MATCH':
      return {
        match: {
          ...state.match,
          [action.name]: action.value
        },
        state: states.UNSAVED
      };
    default:
      return state;
  }
};

export default match;
