import React, { Component } from 'react';

import {  withRouter } from 'react-router-dom';
import ProfileCard from '../ProfileCard';
import _ from 'lodash';
class ProfileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: false
    }
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  createFellowship = (id) => {
    this.setState({ waiting: true });
    let stringId = this.props.profile.id
    stringId = _.last(_.split(stringId,'_'))
    this.props.createFellowship(stringId).then(()=> {
      if (!this.unmounting) {
        this.setState({ waiting: false });
      }
      // window.location.reload();

    });
  };

  acceptFellowship = (id) => {
    this.setState({ waiting: true });
    let stringId = this.props.profile.id
    stringId = _.last(_.split(stringId,'_'))
    // let page = this.props.match.path.split('/')[1]
    let page = 'profiles'
    this.props.createFellowship(stringId).then((res)=> {
      // let path = page === 'profile-details' ? `/profile-details/${id}` : `/partners/${id}`
      // this.props.history.push(path)
      window.location.reload();

    });
  };

  cancelFellowship = (id) => {
    this.setState({ waiting: true });
    this.props.cancelFellowship(this.props.fellowship.id).then(()=> {
      if (!this.unmounting) {
        this.setState({ waiting: false });
      }
      window.location.reload();

    });
  };

  render() {
    // const path = this.props.match.path.split('/')[1];
    const path = 'profiles';
    return (
      <ProfileCard profile={this.props.profile}
        locationSelection={this.props.locationSelectionData}
        location={this.props.locationData} 
        createFellowship = {this.createFellowship}
        acceptFellowship = {this.acceptFellowship}
        cancelFellowship = {this.cancelFellowship}
        approved={this.props.fellowship && this.props.fellowship.approved}
        sentByViewer={this.props.fellowship && this.props.fellowship.sent_by_viewer}
        senderId={this.props.fellowship && this.props.fellowship.sender_id}
        receiverId={this.props.fellowship && this.props.fellowship.receiver_id}
        fellowship={this.props.fellowship}
        waiting={this.state.waiting}
        path={path}
        showBackButton={true}
        page = "partner-profile-show"
        currentProfileId = {this.props.currentProfile && this.props.currentProfile.id}
      /> 
    );
  }
}

export default withRouter(ProfileView);
