
import React, { Component } from 'react';
import { Card,  Row, Col,CardBody } from 'reactstrap';
import NumberFormat from 'react-number-format';
import ImageSlider from '../../ImageSlider'
class Property extends Component {
    render(){
        const property = this.props.record
        return(
            <Card body className="p-0 property-card">
                <ImageSlider 
                    property={property}
                    toggleFavorite={this.props.toggleFavorite}
                    myLiked={this.props.myLiked}
                >
                </ImageSlider>
                {/* <div  className="image-container">
                    

                </div> */}
                <CardBody className="p-0">
                    <Row className="margin-top-10">
                        <Col className="amount"><NumberFormat value={property.list_price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                        <Col className="time">Listed {property.list_date} ago</Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="details">
                            {property.beds ? (<span><strong>{property.beds}</strong> <span className="lighten-text">&nbsp;bd</span></span>) : null}  
                            {property.bath ? (<span> <strong>{property.bath}</strong> <span className="lighten-text">&nbsp;ba</span></span>) : null}  
                            {property.kitchens ? (<span> <strong>{property.kitchens}</strong> <span className="lighten-text">&nbsp;kit</span></span>) : null}  
                            {property.sqft ? (<span> <strong>{property.sqft}</strong> <span className="lighten-text">&nbsp;sqft</span></span>) : null}  
                            {property.prkng ? (<span> <strong>{property.prkng}</strong> <span className="lighten-text">&nbsp;prkng</span></span>) : null}  
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-3">
                        <Col md="12" className="sub-details">{property.street} {property.propertyType ? "("+property.propertyType+")" : null}</Col>
                        <Col  md="12" className="location">{property.city_state}</Col>
                    </Row>
                </CardBody>

            </Card>
        )
    }
}
export default Property;
