import { connect } from 'react-redux';
import BetaTerms from '../components/pages/BetaTerms';
import { updateInviteCode, updateTermsConditions } from '../actions';

const mapStateToProps = state => ({
    errors: state.auth.acceptErrors,
    termsAgreed: state.auth.termsAgreed
});
  
const mapDispatchToProps = dispatch => ({
    updateInviteCode: (code) => dispatch(updateInviteCode(code)),
    updateTermsConditions: (termsAgreed) => dispatch(updateTermsConditions(termsAgreed)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetaTerms);
