import { schema } from 'normalizr';

// export const homeSurveyQuestion = new schema.Entity('homeSurveyQuestions');
// export const homeSurveyAnswer = new schema.Entity('homeSurveyAnswers', {
//   'home_survey_question': homeSurveyQuestion
// });

export const location = new schema.Entity('locations');

export const locationSelection = new schema.Entity('locationSelections', {
  'location': location
});

export const message = new schema.Entity('messages');

export const notification = new schema.Entity('notifications');

export const conversation = new schema.Entity('conversations', {
  'messages': [message],
});

export const fellowship = new schema.Entity('fellowships', {
  'conversation': conversation
});

export const profile = new schema.Object({
  // 'home_survey_answers_attributes': [homeSurveyAnswer],
  'location_selections_attributes': [locationSelection],
  // 'fellowships_attributes': [fellowship],
});

export const pagination = new schema.Entity('pagination',{
  current_page: {type:Number, required: true},
  total_pages: {type:Number, required: true}
});


export const fellow = new schema.Entity('fellows', {
  'location_selections_attributes': [locationSelection],
  'fellowships_attributes': [fellowship]
});
