/**
 * An object's state can be any one of:
 * 'new' - object has not yet been saved to database
 * 'failed' - retrieving object from backend failed
 * 'fetching' - object is in transit between DB and front end
 * 'saved' - object is synchronized with DB
 * 'unsaved' - object exists in DB but has been modified since last update
 * 'validating' - for User object only: user's credentials are being validated
 *                by the backend
 */
const states = {
  NEW: 'new',
  FAILED: 'failed',
  FETCHING: 'fetching',
  SAVED: 'saved',
  UNSAVED: 'unsaved',
  VALIDATING: 'validating',
};

export default states;
