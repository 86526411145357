
import { connect } from 'react-redux';
import EditProfile from '../components/pages/EditProfile';
import {
  addAlert,
  clearAlerts,
  clearFellows,
  fetchProfile,
  saveProfile,
  updateProfile
} from '../actions';

const mapStateToProps = (state) => ({
  profile: state.profile,
  profileState: state.states.profile,
  homeSurveyAnswers: state.homeSurveyAnswers,
  homeSurveyQuestions: state.homeSurveyQuestions,
  locationSelections: state.locationSelections,
});

const mapDispatchToProps = (dispatch) => ({
  addAlert: (alert) => dispatch(addAlert(alert)),
  clearAlerts: () => dispatch(clearAlerts()),
  clearFellows: () => dispatch(clearFellows()),
  saveProfile: () => dispatch(saveProfile()),
  fetchProfile: () => dispatch(fetchProfile()),
  updateProfile: () => dispatch(updateProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile);


