import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Button
} from 'reactstrap';
import FloatingLabelInput from '../../components/utils/FloatingLabelInput';
import SimpleModal from '../../components/utils/SimpleModal';

class EditQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: this.props.question,
      editing: false,
      saveModal: false,
      archiveModal: false,
      deleteModal: false
    };
  }

  toggleEditing = () => {
    this.setState({
      editing: !this.state.editing
    });
  };

  toggleSaveModal = () => {
    this.setState({
      saveModal: !this.state.saveModal
   });
  };

  handleArchive = () => {
    this.setState({
      question: {
        ...this.state.question,
        archived: !this.state.question.archived
      }
    }, this.handleSave);
  };

  toggleArchiveModal = () => {
    this.setState({
      archiveModal: !this.state.archiveModal
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
  };

  handleChange = (event) => {
    this.setState({
      question: {
        ...this.state.question,
        [event.target.name]: event.target.value
      }
    });
  };

  handleDelete = (event) => {
    this.setState({
      editing: false
    });
    this.props.deleteQuestion(this.state.question).then(() => {
      this.props.fetchQuestions();
    });
  };

  handleSave = (event) => {
    this.setState({
      editing: false
    });
    this.props.updateQuestion(this.state.question).then(() => {
      this.props.fetchQuestions();
    });
  };

  render() {
    const archiveText = this.props.question.archived ? 'Unarchive' : 'Archive';
    const editButton = (
      <Button onClick={this.toggleEditing} color="info" className="mr-2"> 
        { this.state.editing ? "Cancel" : "Edit" }
      </Button>
    );
    const saveButton = this.state.editing ? (
      <Button onClick={this.toggleSaveModal} color="primary" className="mr-2"> 
        Save
      </Button>
    ) : null;

    return (
      <React.Fragment>
        <Form noValidate onSubmit={ (event) => {event.preventDefault();} }>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Question"
              onChange={this.handleChange}
              name="question"
              value={this.state.question.question}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Subtext"
              onChange={this.handleChange}
              name="subtext"
              value={this.state.question.subtext}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Short Name"
              onChange={this.handleChange}
              name="short_name"
              value={this.state.question.short_name}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Type"
              onChange={this.handleChange}
              name="answer_type"
              value={this.state.question.answer_type}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Order"
              type="number"
              onChange={this.handleChange}
              name="questionnaire_order"
              value={this.state.question.questionnaire_order}
              disabled={!this.state.editing}
            />
          </FormGroup>
          <FormGroup>
            { editButton }
            { saveButton }
            <Button onClick={this.toggleArchiveModal} size="sm" className="mr-2">
              { archiveText }
            </Button>
            <Button onClick={this.toggleDeleteModal} color="danger" size="sm"> 
              Delete
            </Button>
          </FormGroup>
        </Form>

        <SimpleModal
          isOpen={this.state.saveModal}
          toggle={this.toggleSaveModal}
          title="Are you sure?"
          buttons={[
            { text: 'Save Question', color: 'success', action: this.handleSave },
            { text: 'Cancel' }
          ]}>
          Users will see this change.
        </SimpleModal>
        <SimpleModal
          isOpen={this.state.archiveModal}
          toggle={this.toggleArchiveModal}
          title="Are you sure?"
          buttons={[
            { text: `${archiveText} Question`, color: 'warning', action: this.handleArchive },
            { text: 'Cancel' }
          ]}>
          Users will see this change.
        </SimpleModal>
        <SimpleModal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeleteModal}
          title="Are you sure?"
          buttons={[
            { text: 'Delete Question', color: 'danger', action: this.handleDelete },
            { text: 'Cancel' }
          ]}>
          This change is permanant and cannot be undone.
        </SimpleModal>
      </React.Fragment>
    );
  }
}

export default EditQuestion;
