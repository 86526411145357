import Util from '../util';
import { openWebsockets, closeWebsockets, processEntity } from './common'
import { profile as profileSchema } from '../schema'
import { addAlert } from './alerts';

/**
 * Everything to do with authentication
 */

/**
 * Sign in
 */
const requestSignIn = () => ({
  type: 'REQUEST_SIGN_IN'
});

const receiveSignIn = (dispatch, getState, auth) => {
  processEntity(dispatch, 'profile', profileSchema, auth.profile);

  // TODO remove once no longer necessary
  if (new Date(auth.created_at) < new Date('July 5 2019') && !auth.profile.complete) {
    dispatch(addAlert({
      message: "Welcome back to HusMates! We've made some changes since you last visited. Please take a minute to complete your profile again.",
      color: 'success'
    }));
  }

  // we don't want to save this in the auth state
  delete auth.profile;
  dispatch({
    type: 'RECEIVE_SIGN_IN',
    payload: auth
  });

  openWebsockets(dispatch, getState);
};

const receiveSignInFail = (authErrors) => ({
  type: 'RECEIVE_SIGN_IN_FAIL',
  authErrors
});

export const signOut = () => dispatch => {
  closeWebsockets();

  Util.apiRequest('DELETE', '/auth/sign_out');
  Util.clearLocalAuth();

  dispatch({
    type: 'SIGNOUT_USER'
  });
};

export const validateToken = () => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST_VALIDATE'
  });
  return Util.apiGetRequest('/auth/validate_token').then(response => {
    if (response.ok) {
      return response.json()
        .then(json => receiveSignIn(dispatch, getState, json));
    } else if (response.status === 404) {
      dispatch(receiveSignInFail(["No response from server. Please try again."]));
    } else {
      response.json().then(resp =>
        dispatch(receiveSignInFail(resp.errors)));
    }
  });
}

export const signInUser = (email, password) => (dispatch, getState) => {
  dispatch(requestSignIn());
  return Util.apiRequest('POST', '/auth/sign_in', null, {
    email: email,
    password: password,
  }).then(response => {
    if (response.ok) {
      return response.json()
        .then(json => receiveSignIn(dispatch, getState, json));
    } else if (response.status === 404) {
      dispatch(receiveSignInFail(["No response from server. Please try again."]));
    } else {
      response.json().then(resp =>
        dispatch(receiveSignInFail(resp.errors)));
    }
  });
};

export const forgotPassword = (email) => dispatch => {
  dispatch({
    type: 'REQUEST_FORGOT_PASSWORD'
  })
  return Util.apiRequest('POST', '/auth/password', null, {
    email: email,
    redirect_url: `${process.env.REACT_APP_PUBLIC_URL}/reset-password`
  }).then(response => {
    if (response.ok) {
      dispatch({
        type: 'RECEIVE_FORGOT_PASSWORD'
      })
    } else if (response.status === 404) {
      dispatch({
        type: 'RECEIVE_FORGOT_PASSWORD_FAIL'
      })
    } else if (response.status === 422) {
      dispatch({
        type: 'RECEIVE_FORGOT_PASSWORD_FAIL',
        error: "This account does not require a password. Sign in using your 'Google' account instead."
      })
    }
  });
};

export const updatePassword = (
  password,
  passwordConfirmation
) => (dispatch, getState) => {
  dispatch(requestSignIn());
  return Util.apiRequest('PUT', '/auth/password', null, {
    password: password,
    password_confirmation: passwordConfirmation
  }).then(response => {
    if (response.ok) {
      response.json().then(json => receiveSignIn(dispatch, getState, json.data));
      Util.clearLocalAuth();
      window.location.href="/login"
      dispatch(addAlert({
        message: 'Your password has been updated.',
        color: 'success'
      }));
    } else if (response.status === 404) {
      dispatch(receiveSignInFail(["No response from server. Please try again."]));
    } else if (response.status === 401) {
      dispatch(receiveSignInFail(["Unauthorized to update password."]));
    } else {
      response.json().then(resp =>
        dispatch(receiveSignInFail((resp.errors && resp.errors.full_messages) ? resp.errors.full_messages : resp.errors)));
    }
    return response;
  });
};

export const registerUser = (
  email,
  firstName,
  lastName,
  password,
  passwordConfirmation,
  history
) => dispatch => {
  dispatch({
    type: 'REQUEST_REGISTER'
  });
  return Util.apiRequest('POST', '/auth', null, {
    email: email,
    first_name: firstName,
    last_name: lastName,
    password: password,
    password_confirmation: passwordConfirmation,
    confirm_success_url: `${process.env.REACT_APP_PUBLIC_URL}/confirm-email-received`
  }).then(response => {
    if (response.ok) {
      dispatch({
        type: 'RECEIVE_REGISTER'
      });
      history.push('confirm-email-sent');
    } else if (response.status === 404) {
      dispatch({
        type: 'RECEIVE_REGISTER_FAIL',
        registerErrors: ["No response from server. Please try again."]
      });
    } else {
      response.json().then(resp => dispatch({
        type: 'RECEIVE_REGISTER_FAIL',
        registerErrors: resp.errors.full_messages
      }));
    }
  });
};

/**
 * Admin user impersonation
 */

/**
 * Called when admin impersonates a user. Does not open websockets for
 * impersonated user.
 */
export const signInLite = (dispatch, auth) => {
  processEntity(dispatch, 'profile', profileSchema, auth.profile);

  delete auth.profile;
  dispatch({
    type: 'RECEIVE_SIGN_IN',
    payload: auth
  });
};

/**
 * Called when admin impersonates a user. Need to clear admin's state, and close
 * admin's websockets.
 */
export const signOutLite = (dispatch) => {
  closeWebsockets();

  dispatch({
    type: 'SIGNOUT_USER'
  });
};



export const validateInviteCode = (
  inviteCode
) => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST_VALIDATE_INVITE_CODE'
  });
  return Util.apiRequest('POST', '/validate_invite_code', null, {
    code: inviteCode
  }).then(response => {
    if (response.ok) {
      response.json()
        .then(json => {
          dispatch({
            type: 'RECEIVE_VALIDATE_INVITE_CODE'
          });
        });
    } else {
        response.json().then(resp =>{
          dispatch({
            type: 'RECEIVE_VALIDATE_INVITE_CODE_FAIL',
            inviteErrors: resp
          });
        })
     
    }
    return response;
  });
};


export const updateInviteCode = (
  inviteCode
) => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST_INVITE_CODE'
  });
  return Util.apiRequest('POST', '/update_invite_code', null, {
    code: inviteCode
  }).then(response => {
    if (response.ok) {
      response.json().then(json => {receiveSignIn(dispatch, getState, json)});
      dispatch({
        type: 'RECEIVE_INVITE_CODE'
      });
    } else {
        response.json().then(resp =>{
          dispatch({
            type: 'RECEIVE_INVITE_CODE_FAIL',
            inviteErrors: resp
          });
        })
     
    }
    return response;
  });
};

export const updateTermsConditions = (
  termsAgreed
  ) => (dispatch, getState) => {
  dispatch({
      type: 'UPDATE_TERMS',
      payload: {termsAgreed}
  });
};