import states from '../../reduxStates';

const initialState = {
  state: states.NEW,
  items: []
};

const matches = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_ADMIN_MATCHES':
      return {
        ...state,
        state: states.FETCHING
      };
    case 'RECEIVE_ADMIN_MATCHES':
      return {
        items: action.items,
        state: states.SAVED
      };
    case 'RECEIVE_ADMIN_MATCHES_FAIL':
      return {
        ...state,
        state: states.FAILED
      };
    default:
      return state;
  }
};

export default matches;
