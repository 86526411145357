import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from './_Page';
import {
  Card,
  CardBody,
  Button
} from 'reactstrap';

class NotFound extends Component {
  render() {
    const loginButton = <Button tag={Link} to="/login">Log In</Button>;
    const dashboardButton = <Link to="/dashboard">Back to Dashboard</Link>

    return (
    	<Page title="Page not found" pageClass="notfound" background hideFooter>
        <Card>
          <CardBody className="text-center">
            <p>The requested page could not be found.</p>
            { this.props.loggedIn ? dashboardButton : loginButton }
          </CardBody>
        </Card>
      </Page>
    );
  }
}

export default NotFound;
