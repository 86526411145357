import { connect } from 'react-redux';
import PartnerDetails from '../components/pages/Partners/PartnerDetails';
import { fetchPartnerDetails } from '../actions';

const mapStateToProps = (state) => {
  // this will need to change once fellowships can have more than two people
  // debugger;
  let location=null;
  let locationSelection=null;
  if(state.fellow.partnerDetails){
    let locationSelections = state.fellow.partnerDetails['location_selections_attributes'] ?
      state.fellow.partnerDetails['location_selections_attributes']
      .map(id => state.locationSelections[id]) :
      [];
    locationSelection = locationSelections[0] || {};
    location = (locationSelection.location_id) ?
      state.locations[locationSelection.location_id] :
      {};
  }
  return{
    profile: state.fellow.partnerDetails,
    state: state.fellow.state,
    location: location,
    locationSelection: locationSelection
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchPartnerDetails: (id) => dispatch(fetchPartnerDetails(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerDetails);
