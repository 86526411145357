import { connect } from 'react-redux';
import {
  createMatch,
  deleteMatch,
  fetchAdminMatches,
  updateAdminMatch
} from '../../actions';
import Matches from '../components/Matches';

const mapStateToProps = (state) => ({
  matches: state.adminMatches.items,
  matchState: state.adminMatches.state,
});

const mapDispatchToProps = (dispatch) => ({
  createMatch: (match) => dispatch(createMatch(match)),
  deleteMatch: (match) => dispatch(deleteMatch(match)),
  updateMatch: (match) => dispatch(updateAdminMatch(match)),
  fetchMatches: () => dispatch(fetchAdminMatches()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Matches);
