
import React, { Component } from 'react';
import { Card, Button, CardTitle, CardText, Row, Col,FormGroup,Input,CardBody,Alert  } from 'reactstrap';
import Page from './_Page';
import Logo from '../Logo';
import { Redirect } from 'react-router-dom';


class AssignInviteCode extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          accessCode: null,
          submitted: false,
          waiting: false
        }
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        const value = event.target.value;
        this.setState({
          accessCode: value
        });
    }

    componentWillUnmount() {
        this.unmounting = true;
    }
    
    handleSubmit() {
        this.setState({ submitted: true, waiting: true });
        this.props.validateInviteCode(this.state.accessCode).then((response) => {
            if (response.ok) {
                this.props.history.push(`/beta_terms/${this.state.accessCode}`);
            }
            
            if (!this.unmounting) {
                this.setState({ waiting: false });
            }
        });
    }
    
    render(){
        const errors = (this.props.errors) ? 
            this.props.errors.map((e, i) => (<Alert key={`error-${i}`} color="warning">{e}</Alert>)) :
            [];
        const loadingIcon = this.state.waiting ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;
        return(
            <Page title="AssignInviteCode" pageClass="invitecode" container="fluid" hideTitle background hideNavbar hideFooter darkMode>
               
                <Row>
                    <Col  sm={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                        <Card body>
                            <Row>
                                <Col sm={{ size: 12 }} md={{ size: 10, offset: 1 }} >
                                    <CardTitle >
                                        <h1 className="text-center text-light mb-4">
                                            <Logo height="60" textBeside textColor="#000" />
                                        </h1>
                                        <CardText className="text-center para" >Welcome to HusMates! We're currently in Beta and we will be launching in early 2021</CardText>
                                        <CardText className="text-center para ">If you would like to take part in our beta testing program or early release please reach out to us at contact@husmates.com </CardText>
                                        <CardText className="text-center para">If you have a beta access code please enter it below</CardText>
                                    </CardTitle>
                                    
                                </Col>
                                <Col sm={{ size: 12 }} md={{ size: 8, offset: 2 }}>
                                    <CardBody className="text-center">
                                        <FormGroup className="">
                                            <Input type="text" name="text" placeholder="access code" onChange={this.handleChange} className="full-width"/>
                                        </FormGroup>
                                        <Button  className="theme-button" color="brand-blue full-width" outline onClick={this.handleSubmit}>SUBMIT {loadingIcon}</Button>
                                        <br/>
                                        <br/>
                                        {errors}
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Page>
        )
    }
}
export default AssignInviteCode;
