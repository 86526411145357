import { connect } from 'react-redux';
import GlobalAlerts from '../components/GlobalAlerts';
import { withRouter } from 'react-router-dom';
import { removeAlert, updateAlert } from '../actions';

const mapStateToProps = (state) => ({
  alerts: state.alerts
});

const mapDispatchToProps = (dispatch) => ({
  removeAlert: (index) => dispatch(removeAlert(index)),
  updateAlert: (index, data) => dispatch(updateAlert(index, data))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalAlerts));
