import { connect } from 'react-redux';
import NotFound from '../components/pages/NotFound';


const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn
});

export default connect(
  mapStateToProps,
  null
)(NotFound);
