import React, { Component } from 'react';
import {
    Card,
    CardBody,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom';

export default class PropertiesList extends Component {
    render() {
        return (
            <Card className="properties-card hide">
                <CardBody>
                    <span className="header-text" >
                        Husmates Exclusive Property Listings (Coming Soon)
                    </span>
                    <div>
                        <img src="/images/listings_coming_soon.png" alt="list-coming" className="image"/>
                    </div>
                </CardBody>
            </Card>
        )
    }
}