import { connect } from 'react-redux';
import { fetchAdminProfiles, signInLite, signOutLite } from '../../actions';
import Profile from '../components/Profile';

const mapStateToProps = (state) => ({
  profiles: state.adminProfiles.items,
  profileState: state.adminProfiles.state,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProfiles: () => dispatch(fetchAdminProfiles()),
  switchUser: (user) => {
    signOutLite(dispatch);
    signInLite(dispatch, user);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
