import React, { Component } from 'react';
import Select from 'react-select'

class SingleSelectDropdown extends Component {
  setChoice = (item) => {
    let choice = this.props.useGivenList ? item.value : item.label
    this.props.setChoice(choice);
  };

  render() {
    let options = this.props.useGivenList ? this.props.options : [], selected = [] , selectedArr= this.props.useGivenList ? this.props.value : (this.props.value ? this.props.value.split(",") : [])
    if(!this.props.useGivenList){
      this.props.options.map((item, i) => {
        options.push({value: item.title, label: item.title}) 
        if(selectedArr.indexOf(item.title) > -1)
          selected.push({value: item.title, label: item.title}) 
      });
    }
    return (
      <Select 
        options={options}
        className={"full-width dropdown-options "+ (this.props.invalid ? 'is-invalid' : '')}
        defaultValue={
          this.props.useGivenList ? options.filter(option => option.value === this.props.value) : this.props.value
        }
        isDisabled={this.props.disabled}
        onChange={(e) => this.setChoice(e)}
      />
       
      
    );
  }
}
export default SingleSelectDropdown;
