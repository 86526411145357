import React, { Component } from 'react';
import states from '../../../reduxStates';
import Page from '../_Page';
import _ from 'lodash'

import {  withRouter } from 'react-router-dom';
import ProfileCard from '../ProfileCard';
import {
    CardBody,
    Spinner,
  } from 'reactstrap';

class PartnerDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: false
    }
  }
  componentDidMount() {
    if(this.props.match.params.id){
      let fellowshipId = this.props.match.params.id
      fellowshipId = (fellowshipId.indexOf('_') > -1 ) ? _.last(_.split(fellowshipId,'_')) : fellowshipId
        this.props.fetchPartnerDetails(fellowshipId);
    }
  }
  render() {
      const contentFetching = (
          <CardBody className="text-center">
          <Spinner className="m-auto" type="grow" />
          </CardBody>
      );
      const path = (this.props.location && this.props.location.state && this.props.location.state.backPath) ? this.props.location.state.backPath : `partners/${this.props.match.params.id}`;
      const pageContent = (
        (this.props.state === states.FETCHING || this.props.state === states.NEW) ? contentFetching : (
        <ProfileCard profile={this.props.profile}
                    locationSelection={this.props.locationSelection}
                    location={this.props.location}
                    page = "partner-show"
                    path= {path}
                    showBackButton={true}
        /> )
      );

      return(
          <Page title="" pageClass="partner-show" hideFooter={true}>
              {pageContent}
          </Page>
      )
    }
}

export default withRouter(PartnerDetails);
