import React, { Component } from 'react';
import {
  CardBody,
  Button,
  Container,
  Col,
  Row,
  Card,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NewsCard from './NewsCard';
import _ from 'lodash'
export default class NewProfileView extends Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col xs="12" sm="12" md={{ size: 5, offset: 3 }} className="mt-3 ">
            <Card className="welcome-card">
                <CardBody>
                    <span className="header-text" >
                      Welcome to Husmates, {this.props.profile.first_name}!
                    </span>
                    <p className="welcome-content">
                      Before we begin finding you a co-owner, let’s build your profile and learn about your housing requirements.
                    </p>
                    <div className="text-right">
                        <Button className="theme-button" tag={Link} to="/edit-profile" >
                        Get Started
                        </Button>
                    </div>
                </CardBody>
            </Card>
          </Col>
        </Row>
       { this.props.news && !_.isEmpty(this.props.news) &&  <NewsCard news={this.props.news}/>}

      </Container>
    );
  }
}
