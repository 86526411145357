import lifecycle from '../reduxStates';
import { types } from './common';

const initialState = (() => {
  const state = {
    profile: lifecycle.NEW
  };
  types.forEach(type => {
    state[type] = lifecycle.NEW
  });
  return state;
})();

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR':
      return {
        ...state,
        [action.meta.type]: lifecycle.NEW
      }
    case 'REQUEST':
      return {
        ...state,
        [action.meta.type]: lifecycle.FETCHING
      }
    case 'RECEIVE':
      return {
        ...state,
        [action.meta.type]: lifecycle.SAVED
      }
    case 'RECEIVE_FAIL':
      return {
        ...state,
        [action.meta.type]: lifecycle.FAILED
      }
    default:
      return state;
  }
}
