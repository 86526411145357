import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';

class CityInput extends Component {
  loadOptions = (input) => this.props.populatePredictions(input).then(ids => {
    return ids
      .filter(id => id !== null)
      .map(id => this.props.locations[id])
      .map(suggestion => ({
        value: suggestion.id,
        label: this.joinLocation(suggestion)
      }));
  });

  joinLocation = location => [location.city, location.region, location.country]
    .filter(Boolean)
    .join(', ');

  handleChange = (event) => this.props.updateLocationSelection({
    ...this.props.locationSelection,
    location_id: (event ? event.value : null)
  });

  render() {
    return (
      <AsyncSelect
        inputId={this.props.id}
        placeholder={this.props.placeholder}
        isDisabled={this.props.disabled}
        loadOptions={this.loadOptions}
        cacheOptions
        value={
          this.props.location.id ? {
            value: this.props.location.id,
            label: (this.joinLocation(this.props.location))
          } : null
        }
        onChange={this.handleChange}
        className="react-select"
        classNamePrefix="react-select"
        noOptionsMessage={()=>"Start typing to find cities"}
        maxMenuHeight={120}
        isClearable
      />
    );
  }

}

export default CityInput;
