import { connect } from 'react-redux';
import { validateToken } from '../actions';
import App from '../components/App';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  validateToken: () => dispatch(validateToken()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
