import React, { Component } from 'react';
import {
    Card,
    CardBody,
    Progress,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom';


export default class ProfileCompletionInfo extends Component {
    render() {
        return (
            <Card className="profile-progress-card">
                <CardBody>

                    <span className="header-text" >
                        You’re almost there! Continue building your profile to find a better match.
                    </span>
                    <div className="mt-3">
                        <Progress value={this.props.profile.completion_percentage} className="progressbar-wrapper"></Progress>
                    </div>
                    <div className="mt-3 text-right">
                        <Button className="theme-button" tag={Link} to="edit-profile">
                            Continue
                        </Button>
                    </div>
                </CardBody>
            </Card>
        )
    }
}