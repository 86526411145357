import React, { Component } from 'react';
import Page from './_Page';
import {
	Alert
} from 'reactstrap';
import Logo from '../Logo';

class ConfirmEmailSent extends Component {
  render() {
    return (
      <Page title="Account Created" pageClass="confirm-email-sent" hideTitle background hideNavbar darkMode hideFooter>
        <h1 className="text-center text-light mb-4">
          <Logo height="60" textBeside textColor="#fff" />
        </h1>
        <Alert color="success" className="text-center">
          <h5>Your account has been created!</h5>
          Please check your email and follow the link provided to confirm it and sign in.
        </Alert>
      </Page>
    );
  }
}

export default ConfirmEmailSent;
