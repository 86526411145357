import React, { Component } from 'react';
import states from '../reduxStates';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';
import moment from 'moment';

class Notifications extends Component {
  constructor(props) {
    super(props);
    if (this.props.notificationsState === states.NEW) {
      this.props.fetchNotifications();
    }
  }

  formatTimestamp = (timestamp) => {
    return moment(timestamp).fromNow();
  };

  render() {
    const notificationsArray = Object.values(this.props.notifications).sort((a, b)=>{
      return new Date(b.created_at) - new Date(a.created_at);
    });

    const unread = notificationsArray.filter(n =>
      n.seen_at === null
    ).length;

    const icon = unread ? (
      <span  className="notification-icon">
        <img src="/icons/bell.svg" alt="Notifications" className="image"/>
        {/* {unread} */}
        <i className="fa fa-circle unread" ></i>
      </span>
    ) : (
      <span className="notification-icon">
        <img src="/icons/bell.svg" alt="Notifications" className="image"/>
      </span>
      
    );

    return (
      <UncontrolledDropdown nav inNavbar className="notifications-dropdown">
        <DropdownToggle onClick={this.props.markNotificationsSeen} nav aria-label="Notifications"  >
          {icon}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header>Notifications</DropdownItem>
          <DropdownItem divider />
          <div className="notifications-scroll">
            {notificationsArray.map((item, i) => (
              <DropdownItem key={i} tag={Link} to={item.url}>
                {item.message}
                <div className="text-muted small">
                  {this.formatTimestamp(item.created_at)}
                </div>
              </DropdownItem>
            ))}
            { notificationsArray.length === 0 ? (
              <DropdownItem disabled className="small">
                You have no new notifications.
              </DropdownItem>
            ) : null}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default Notifications;
