import React, { Component } from 'react';
import states from '../../../reduxStates';
import Page from '../_Page';
import ProfileCard from '../ProfileCard';
import {
    CardBody,
    Spinner,
  } from 'reactstrap';
export default class Profiles extends Component {
    constructor(props) {
        super(props);
        this.props.fetchProfile();
    }
    render() {
        const contentFetching = (
            <CardBody className="text-center">
              <Spinner className="m-auto" type="grow" />
            </CardBody>
          );
      
          const pageContent = (
            (this.props.profileState === states.FETCHING) ? contentFetching : (
            <ProfileCard profile={this.props.profile}
                        locationSelection={this.props.locationSelection}
                        location={this.props.location}
                        page = "profile-show"
            /> )
          );

        return(
            <Page title="" pageClass="profile-show" hideFooter={true}>
               {pageContent}
            </Page>
        )
    }
}
