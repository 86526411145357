import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    CardBody,
    Button,
    Col,
    Row,
    Card,
    CardTitle,
    CardText,
  } from 'reactstrap';

export default class NewsCard extends Component {

  render() {
    let news = this.props.news
    return (
        <Row>
            <Col xs="12" sm="12" md={{ size: 5, offset: 3 }} className="mt-3 ">
            <Card className="news-card">
                <CardBody className="pb-0">
                <CardTitle tag="h5">
                {news.title}
                </CardTitle>
                </CardBody>
            
                <CardBody className="pt-0">
                {news.image && news.image.url && <img
                    alt="Card Image"
                    src={`${process.env.REACT_APP_API_URL}${news.image.url}`}
                    width="100%"
                    className='news-image'

                />}
                <CardText className="para" dangerouslySetInnerHTML={{__html: news.description}}/>

                {news.show_button && <Button className="theme-button float-right" tag={Link} to={news.button_link} target="_blank">
                    {news.button_text}
                </Button>}
                </CardBody>
            </Card>
            </Col>
        </Row>
    );
  }
}
