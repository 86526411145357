import { fetchConversations } from './conversations';
import { updateConversationMessages } from './conversationMessages';
import { updateMyMatches } from './myMatches';
import Util from '../util';
import _ from 'lodash'
var chat = null;

export const sub = (cable, dispatch, getState) => {
  chat = cable.subscriptions.create('ChatChannel', {
    received: (data) => {
      const key = Object.keys(data)[0];
      switch (key) {
        case 'message':
          receiveMessage(dispatch, getState, data[key])
          break;
        case 'seen':
          receiveSeen(dispatch, getState, data[key])
          break;
        default:
          throw Error('Unknown broadcast received from ChatChannel');
      }
    }
  });
};

export const unsub = () => chat.unsubscribe();

export const receiveMessage = (dispatch, getState, message) => {
  console.log(message)
  const conversation = getState().conversationMessages
  if(conversation.id === message.conversation_id){
    let messages = conversation.messages
    let dateKey = Util.getMessageDate(message.created_at)
    if(!messages[dateKey]){
      messages[dateKey] = []
    }
    messages[dateKey].push(message)
    dispatch(updateConversationMessages(messages))
  }
  
  const myMatches = getState().myMatches
  console.log(myMatches)
  if(myMatches && myMatches.matches){
    let matches = myMatches.matches
    let index = _.findIndex(matches, { 'profile_id': ''+message.profile_id });
    if(index > -1){
      let match = matches[index]
      match.unread_count +=1
      matches.splice(index, 1, match);
      console.log(matches)
      dispatch(updateMyMatches(matches))
    }

  }
};

const receiveSeen = (dispatch, getState, seen) => {
  // console.log(seen)
  // const conversation = getState().conversations[seen.conversation_id];
  // if (conversation) {
  //   conversation.seen = {
  //     ...conversation.seen,
  //     [seen.profile]: seen.message
  //   };
  //   dispatch({
  //     type: 'UPDATE',
  //     meta: { type: 'conversations' },
  //     payload: conversation
  //   });
  // } else {
  //   dispatch(fetchConversations);
  // }
};

export const sendMessage = (cid, message) => chat.perform('send_message', {
  conversation_id: cid,
  body: message
});

export const markSeen = (cid) =>{
  if(chat){
    chat.perform('mark_seen', {
      conversation_id: cid
    });
  }
}
