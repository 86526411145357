import { location as locationSchema } from '../schema';
import { fetchEntity } from './common';

/**
 * Fetch locations from server
 */

export const fetchLocations = (input) => fetchEntity(
  'locations',
  [locationSchema],
  `/location_suggestions?input=${input}`
);
