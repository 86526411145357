import { connect } from 'react-redux';
import { registerUser } from '../actions';
import RegistrationForm from '../components/RegistrationForm';
import { withRouter } from 'react-router-dom';

const mapDispatchToProps = (dispatch) => ({
  registerUser: (
    email,
    firstName,
    lastName,
    password,
    passwordConfirmation,
    history
  ) => dispatch(registerUser(
    email,
    firstName,
    lastName,
    password,
    passwordConfirmation,
    history
  ))
});

const mapStateToProps = (state) => ({
  errors: state.auth.registerErrors
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationForm));
