import React from "react";
import PropTypes from "prop-types";
import Logo from './Logo';
import { Link } from 'react-router-dom'


const SidebarContent = props => {
    const addActiveClass = (page) =>{
        return ((props.currentPage.indexOf(page) > -1) ? 'active' : '')
    }
    return (
        <div className="sidebar">
            <div className="sidebar-content">
                <Link to="/dashboard" className="sidebar-icon">
                    <Logo  textBeside textColor="#fff" className="mb-2" />
                </Link> 
                <Link to="/dashboard" className={"sidebar-link "+ addActiveClass('/dashboard')}>
                    <img src= {'/icons/house.svg'} alt="house" className="link-icon"/>
                    Dashboard
                </Link> 
                <Link to="/profiles" className={"sidebar-link "+ addActiveClass('/profiles')}>
                    <img src= {'/icons/find_people.svg'} alt="Browse Matches" className="link-icon"/>
                    Browse Matches
                </Link>
                {/* <Link to="/partners" className={"sidebar-link "+ addActiveClass('/partners')}>
                    <img src= {'/icons/star.svg'} alt="my matches" className="link-icon"/>
                    My Matches
                </Link> */}
                <Link to="/properties" className={"sidebar-link "+ addActiveClass('/properties')}>
                    <img src= {'/icons/searches.svg'} alt="my matches" className="link-icon"/>
                    Search Properties
                </Link>
                <a href="mailto:contact@HusMates.com" target="_blank" rel="noopener noreferrer" className={"sidebar-link "+ addActiveClass('/help')}>
                    <img src= {'/icons/help_feedback.svg'} alt="help" className="link-icon"/>
                    Help & Feedback
                </a>
            </div>
        </div>

    );
};

SidebarContent.propTypes = {
    style: PropTypes.object
};

export default SidebarContent;