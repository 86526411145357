import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  Row,
  Col
} from 'reactstrap';
import Util from '../../util.js';
import _ from 'lodash'

class ProfileCardSmall extends Component {

  render() {
    const { profile, location , interests} = this.props;

    const photo = profile.photo.thumb;
    
    const city = `${location.city}`
    const age= Util.getAge(profile.birthday) || ''
    return (
      <Card outline color="" className="mt-3 profile-card-container">
        <div style={{position: 'relative'}}>
          <img
            className={"img-fluid mr-2 profile-image " + (profile.photo.placeholder ? 'default-pic' : '')}
            src={photo}
            alt=""
          />
          {interests && interests.length > 0 && <Row className="intrest-list-container">
            {Util.intrestList(interests)}
          </Row>}
        </div>
        <CardHeader className="profile-header-wrapper">
          <Row>
            
            <Col xs={10} className="icon-and-name">
                {profile.first_name} {profile.last_name}
            </Col>
            <Col xs={12} className="age-city">
                {_.compact([age,city]).join(" , ")}
            </Col>
            
          </Row>
         
        </CardHeader>
      </Card>
    );
  }
}

export default ProfileCardSmall;
