import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    Row
  } from 'reactstrap';
import {Link} from 'react-router-dom'
import {  CardTitle, CardText, CardImg, CardImgOverlay } from 'reactstrap';
import Util from '../../util';
import _ from 'lodash';

class ProfileCard extends Component {
    
    imageGalleryList = (image_galleries)=>{
        let list =[]
        if(image_galleries.length > 0){
            list = image_galleries.map((rec,index)=>{
                return ( 
                    rec.placeholder ? null : (
                        <Card inverse className="mt-2" key={'ig-'+index}>
                            <CardImg width="100%" src={rec.full} alt="" />
                        </Card>
                    )
                )
            })

        }
        return list
    }
    getToggleComponents = (page)=>{
        let res = {
            backButton: false,
            cancelButton: false,
            addButton: false,
            requestMeetButton: false,
            removePartnerButton: false,
            editButton: false
        }
        console.log(page)
        switch (page) {
            case 'profile-show':
                res= {...res, ...{editButton: true, backButton: false, cancelButton:false, addButton: false, requestMeetButton: false, removePartnerButton:false}}
                break;
            case 'partner-show':
                res= {...res, ...{editButton: false, backButton: true, cancelButton:false, addButton: false, requestMeetButton: false, removePartnerButton:false}}
                break;
            case 'partner-profile-show':
                res= {...res, ...{backButton: true, cancelButton: true, addButton: true}}
                break;
            case 'partner-profile-details':
                res= {...res, ...{requestMeetButton: true, removePartnerButton: true}}
                break;
            default:
                break;
        }

        return res
    }
    render() {
    const {
        id,
        bio,
        photo,
        interests,
        duration,
        birthday,
        first_name,
        last_name,
        instagram_link,
        twitter_link,
        image_galleries,
        gender,
        profession,
        sexuality,
        pets,
        childrens,
        tiktok_link,
        relationship_status

    } = this.props.profile

    const {senderId, receiverId,  currentProfileId,sentByViewer} = this.props;
    const city = `${this.props.location.city}`
    const neighbourhood = this.props.locationSelection.neighbourhood
    const age= Util.getAge(birthday) || ''
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
    var reactElement = htmlToReactParser.parse(bio);
    let durationToDisplay = Util.durationLabel(duration)
    const addIcon = this.props.waiting ?
      <i className="fas fa-fw fa-spinner fa-spin"></i> :
      null;
    const cancelIcon = this.props.waiting ?
      <i className="fas fa-fw fa-spinner fa-spin"></i> :
      null;
    const requestIcon = this.props.waiting ?
      <i className="fas fa-fw fa-spinner fa-spin"></i> :
      null;
    const toggleComponents= this.getToggleComponents(this.props.page)
    const addButton = (senderId, receiverId,currentProfileId,sentByViewer, approved) =>{
        let button =null
        console.log(senderId)
        console.log(currentProfileId)
        console.log(approved)
        if((senderId && (senderId  === currentProfileId)) && approved === false){
            button = (<Button outline color="primary" size="sm" className="card-buttons" onClick={()=>this.props.createFellowship(id)}>{addIcon} Match</Button>)
        }else if((senderId && (senderId  === currentProfileId)) && approved === true){
            button = (<Button outline color="primary" size="sm" className="card-buttons" onClick={()=>this.props.cancelFellowship(id)}>{cancelIcon} Cancel</Button>)
        }else if((receiverId && (receiverId  === currentProfileId)) && approved === false){
            button = (<Button outline color="primary" size="sm" className="card-buttons width-150" onClick={()=>this.props.acceptFellowship(id)}>{addIcon} Accept Match</Button>)
        }else if((receiverId && (receiverId  === currentProfileId)) && approved === true){
            button = (<Button outline color="primary" size="sm" className="card-buttons" onClick={()=>this.props.cancelFellowship(id)}>{cancelIcon} Cancel</Button>)
        }else if(approved){
            return <Button outline color="primary" size="sm" className="card-buttons" onClick={()=>this.props.cancelFellowship(id)}>{cancelIcon} Cancel</Button>
        }else{
            return <Button outline color="primary" size="sm" className="card-buttons" onClick={()=>this.props.createFellowship(id)}>{addIcon} Match</Button>
        }
        return button
    }
    return (
        <Row className="image-wrapper-row ">
            {toggleComponents.backButton && <Col xs={12} md={{size: 6, offset: 3}} className="pull-right p-0 mb-2">
                <Button tag={Link}
                        to={`/${this.props.path}`}
                        outline  
                        size="md" 
                        className=" theme-button-inverse b-100 btn btn-secondary back-buttons border-0" 
                ><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Back
                </Button>
            </Col>}
            <Col xs={12} md={(this.props.page === 'partner-profile-details') ? {size: 8, offset: 0} : {size: 6, offset: 3}} className="card-wrapper">
                <Card inverse className="img-card">
                    <CardImg width="100%" src={photo.full} alt="" className={"profile-pic " + (photo.placeholder ? 'default-pic' : '')}/>
                    <div className="gradientback"></div>
                    <CardImgOverlay>
                    {(toggleComponents.cancelButton || toggleComponents.addButton) && <CardText className="mb-0 d-flex justify-content-left">
                        {addButton(senderId, receiverId,currentProfileId,sentByViewer,this.props.approved)}
                    </CardText>}

                    {(toggleComponents.requestMeetButton || toggleComponents.removePartnerButton) && <CardText className="mb-0 d-flex justify-content-left">
                        {(this.props.requestMeet) ? (
                         <Button outline color="primary" size="sm" className="card-buttons"> Meeting Requested</Button>) : ( 
                        <Button outline color="primary" size="sm" className="card-buttons" onClick={()=>this.props.requestMeetClick(id)}>{requestIcon}Request Meet</Button>)
                        }
                        <Button outline color="primary" size="sm" className="card-buttons unmatch" onClick={()=>this.props.removePartnerClick(id)}>Unmatch</Button>
                    </CardText>}
                    {toggleComponents.editButton &&
                        <Link className="edit-profile-button"  to='/edit-profile' >
                        Edit <br/>
                        Profile
                        </Link>
                    }
                    
                    
                    </CardImgOverlay>
                </Card>
                <Card>
                    <CardBody>
                    <CardTitle tag="h4" className="full-name mb-0">{first_name} {last_name}</CardTitle>
                    <CardText>{_.compact([age,city]).join(" , ")}</CardText>
                    <Row className="m-0 flex-column"> 
                        {(gender || sexuality) && <p className="mb-0"><strong>Gender & Sexuality:</strong> {_.compact([gender,sexuality]).join(" , ")} </p>}
                        { profession && <p className="mb-0"><strong>Profession:</strong> {profession}  </p>}
                        { relationship_status && <p className="mb-0"><strong>Relationship:</strong> {relationship_status} </p>}
                        { <p className="mb-0"><strong>Children:</strong> {childrens || 0}  </p>}
                        { pets && <p className="mb-0"><strong>Pets:</strong> {pets} </p>}
                    </Row>
                    <Row className="m-0 mt-1"> 
                        {Util.intrestList(interests)}
                    </Row>
                    <Row className="m-0 mt-4 bioTextWrapper">
                        {reactElement}
                    </Row>
                    {neighbourhood && <Row className="m-0 mt-3">
                        <strong>Neighbourhoods:&nbsp;</strong>{neighbourhood}
                    </Row>}
                    {durationToDisplay && <Row className="m-0 mt-1">
                        <strong>Ready to co-own:&nbsp;</strong>{durationToDisplay}
                    </Row>}
                    </CardBody>
                </Card>
                {this.imageGalleryList(image_galleries)}
                <Card className={(!instagram_link && !twitter_link && !tiktok_link) ? 'hide' : ''}>
                    <CardBody>
                    {/* <Row className="m-0">
                        <Col className="p-0"><strong>Socials</strong></Col>
                    </Row> */}
                    <Row className="m-0 mt-1 p-lg-1 font-16">
                        {tiktok_link && 
                            <Col xs="12" sm="12" md="12" className="d-flex p-0 align-items-center">
                                <img src='/icons/tiktok.svg' className="social-icon" alt="tiktok-icon"/>
                                {tiktok_link}
                            </Col>
                        }
                        {instagram_link && 
                            <Col xs="12" sm="12" md="12" className="d-flex p-0 align-items-center">
                                <img src='/icons/instagram.svg' className="social-icon" alt="instagram-icon"/>
                                {instagram_link}
                            </Col>
                        }
                        {twitter_link && 
                            <Col xs="12" sm="12" md="12" className="d-flex p-0 align-items-center">
                                <img src='/icons/twitter.svg' className="social-icon" alt="twitter-icon"/>
                                {twitter_link}
                            </Col>
                        }
                    </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
  }
}

export default ProfileCard;
