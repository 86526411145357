import { connect } from 'react-redux';
import { signOut } from '../actions';
import Logout from '../components/pages/Logout';

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(signOut())
});

export default connect(
  null,
  mapDispatchToProps
)(Logout);
