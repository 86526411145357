import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Col,
  Row,
  Input
} from 'reactstrap';
import PhotoUploader from './PhotoUploader';
import BirthdayInput from './BirthdayInput';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import SingleSelectDropdown from '../components/SingleSelectDropdown'
import {PROFESSIONS, GENDERS, SEXUALITY, PETS, RELATIONSHIP, CHILDREN} from '../constants'
class PersonalInformationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState:  EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.profile.bio || '')
        ),
      )
    };
  }
  renderList = (imageGalleries, fetching) =>{
    let list =[];
    if(imageGalleries.length > 0){
      list = imageGalleries.map((image, index)=>{
        return (
          <Col sm={6} md={3} className="col-6" key={index}>
            <PhotoUploader
              id="profile-photo"
              photo={image}
              savePhoto={(photo)=>this.props.saveImageGalleryPhoto(image.id, photo)}
              deletePhoto={()=>this.props.deleteImageGalleryPhoto(image.id)}
              disabled={fetching}
              aspectRatio="1by1"
              showSeperateButtons={false}
              width="100%"
            />
          </Col>
        )
      })

    }
    return list;
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
 
  onContentStateChange = contentState => {
    this.props.handleChange('bio', draftToHtml(contentState))
    this.setState({ contentState});
  }

  render() {
    const fetching = this.props.fetching;
    let {image_galleries,pets, sexuality, childrens,first_name,last_name, relationship_status, bio, birthday, gender,profession} = this.props.profile
    const birthdayInvalid = this.props.errors && this.props.errors.includes('birthday');
    return (
      <>
        <Row>
          <Col sm={12}>
            <strong className="font-weight-bold">Your Bio</strong>
          </Col>
        </Row>
        <FormGroup row >
          <Label for="exampleText" sm={12}>Tell everyone about yourself. <img src="/intrests/image-033.png" alt="" style={{ width: '20px', height: '20px' }} /></Label>
          <Col sm={12}>
            

            <Editor
              toolbarHidden= {true}
              editorState={this.state.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onEditorStateChange}
              onContentStateChange={this.onContentStateChange}
            />

          </Col>
        </FormGroup>
        <Row>
          <Col md={6} sm={12}>
            <FormGroup row >
                <Label for="exampleText" sm={12}>First name *</Label>
                <Col sm={12}>
                    <Input
                        type="text"
                        name="first_name"
                        defaultValue={first_name}
                        disabled={fetching}
                        onChange={(event)=> this.props.handleChange('first_name', event.target.value)}
                        invalid={this.props.errors && this.props.errors.includes('first_name')}
                    />
                </Col>
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup row >
                <Label for="exampleText" sm={12}>Last Name *</Label>
                <Col sm={12}>
                    <Input
                        type="text"
                        name="last_name"
                        defaultValue={last_name}
                        disabled={fetching}
                        onChange={(event)=> this.props.handleChange('last_name', event.target.value)}
                        invalid={this.props.errors && this.props.errors.includes('last_name')}
                    />
                </Col>
            </FormGroup>

          </Col>
        </Row>
        <FormGroup row>
          <Label for="birthday" sm={12}>Birthday *</Label>
          <Col sm={12}>
            <BirthdayInput
              id="birthday"
              value={birthday}
              onChange={this.props.handleChange}
              disabled={fetching}
              invalid={birthdayInvalid}
            />
          </Col>
        </FormGroup>
        {/* <FormGroup row>
          <Label for="gender" sm={12}>Gender Identity</Label>
          <Col sm={12}>
            <GenderInput
              id="gender"
              value={gender}
              onChange={this.props.handleChange}
              disabled={fetching}
              invalid={genderInvalid}
            />
          </Col>          
        </FormGroup> */}
        <Row>
          <Col md={6} sm={12}>
            <FormGroup row  >
              <Label sm={12}>Gender Identity</Label>
              <Col sm={12}>
                  <SingleSelectDropdown
                      value={gender}
                      useGivenList={true}
                      options={GENDERS}
                      setChoice={(gender) => this.props.handleChange('gender', gender)}
                      invalid={this.props.errors && this.props.errors.includes('gender')}
                      disabled={fetching}
                  />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup row  >
              <Label sm={12}>Sexuality</Label>
              <Col sm={12}>
                  <SingleSelectDropdown
                      value={sexuality}
                      useGivenList={true}
                      options={SEXUALITY}
                      setChoice={(sexuality) => this.props.handleChange('sexuality', sexuality)}
                      invalid={this.props.errors && this.props.errors.includes('sexuality')}
                      disabled={fetching}
                  />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormGroup row  >
              <Label sm={12}>Profession *</Label>
              <Col sm={12}>
                  <SingleSelectDropdown
                      value={profession}
                      useGivenList={true}
                      options={PROFESSIONS}
                      setChoice={(profession) => this.props.handleChange('profession', profession)}
                      invalid={this.props.errors && this.props.errors.includes('profession')}
                      disabled={fetching}
                  />
              </Col>
            </FormGroup>

          </Col>
          <Col md={6} sm={12}>
            <FormGroup row  >
              <Label sm={12}>Relationship *</Label>
              <Col sm={12}>
                  <SingleSelectDropdown
                      value={relationship_status}
                      useGivenList={true}
                      options={RELATIONSHIP}
                      setChoice={(relationship_status) => this.props.handleChange('relationship_status', relationship_status)}
                      invalid={this.props.errors && this.props.errors.includes('relationship_status')}
                      disabled={fetching}
                  />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormGroup row  >
              <Label sm={12}>Children</Label>
              <Col sm={12}>
                  <SingleSelectDropdown
                      value={childrens}
                      useGivenList={true}
                      options={CHILDREN}
                      setChoice={(childrens) => this.props.handleChange('childrens', childrens)}
                      invalid={this.props.errors && this.props.errors.includes('childrens')}
                      disabled={fetching}
                  />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup row  >
              <Label sm={12}>Pets *</Label>
              <Col sm={12}>
                  <SingleSelectDropdown
                      value={pets}
                      useGivenList={true}
                      options={PETS}
                      setChoice={(pets) => this.props.handleChange('pets', pets)}
                      invalid={this.props.errors && this.props.errors.includes('pets')}
                      disabled={fetching}
                  />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        
        
        <FormGroup row>
          <Label for="profile-photo" sm={12}>
            Add up to 4 photos to your profile:
          </Label>
          {this.renderList(image_galleries, fetching)}
         
        </FormGroup>
      </>
    );
  }
}

export default PersonalInformationForm;
