import React, { Component } from 'react';

class Logo extends Component {
  /*
    This component accepts 12 props:
      textBelow (bool): adds weOwn text below the logo
      textBeside (bool): adds weOwn text beside the logo
      textOnly (bool): displays weOwn text without the icon
      innerColor (string): if present, overrides colours for the entire inside of icon
      textColor (string): custom colour for the logo text.
      outlineColor (string): custom colour for the logo outline.
      colorA (string): custom colour for the right parts of icon.
      colorB (string): custom colour for the top parts of icon.
      colorC (string): custom colour for the left parts of icon.
      height: html height for <svg>
      width: html width for <svg>
      className: additional classes for <svg>
  */

  // constructor(props) {
  //   super(props);

    
  // }

  render() {
    let url = (this.props.textColor === "#fff") ? "/images/logo_light.svg" : "/images/logo_dark.svg"
    return (
      <img src={url} alt="" className={`logo ${this.props.className ? this.props.className : ''}`} height={this.props.height} width={this.props.width}/>
    );
  }
}

export default Logo;
