
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import states from '../../../reduxStates';
import Page from '../_Page';

import Properties from './Properties';


class WishLists extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
         
        }
    
        this.toggleFavorite = this.toggleFavorite.bind(this);
    }
    
    componentWillUnmount() {
        this.unmounting = true;
    }
    componentDidMount() {
        this.props.fetchProperties({}, 'WISHLISTS');
    }

    toggleFavorite = (msl_number) =>{
        this.props.toggleFavorite(msl_number, 'TOGGLE_FAVORITE_LIST')
    }

    render(){
        const {properties, stateFor} =this.props
        const loadingIcon =  <i className="fas fa-fw fa-spinner fa-spin"></i> 
        return(
            <Page title="WishLists" pageClass="WishLists" container hideTitle background  hideFooter darkMode>
                <Row className="list-wrapper">
                    <Col sm={{size: 12}} >
                        <Row className="m-0 mt-4 mb-3">
                            <Col className="text-left p-0">
                                <span className='my-saved-list'>
                                    Saved Properties
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col sm={{size: 12}}  >
                        {(this.props.status === states.FETCHING && stateFor === 'WISHLISTS') ? (
                            <Row sm={{size: 12}} >
                                <div className='m-auto pt-5 pb-5'>
                                    Fetching properties please wait {loadingIcon}
                                </div>
                            </Row>
                        ) : (
                            <Row className="m-0">
                                <Properties 
                                    list ={properties}
                                    toggleFavorite={this.toggleFavorite}
                                ></Properties>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Page>
        )
    }
}
export default WishLists;
