import React, { Component } from 'react';
// import GlobalAlert from './GlobalAlert';
import ImpostorAlert from './ImpostorAlert';
import '../resources/styles/css/react-redux-toastr.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ReduxToastr from 'react-redux-toastr';
import {toastr} from 'react-redux-toastr'

class GlobalAlerts extends Component {
  render() {
    const impostorAlert = this.props.impostorId ?
      <ImpostorAlert /> : null;

    if(this.props.alerts.length > 0 ){
      this.props.alerts.map((item, i)=>(
        (item.color === "success") ? (
          toastr.success(item.message, {onHideComplete: () => this.props.removeAlert(i)})
        ) : 
        ((item.color === "error") ? (
          toastr.error(item.message, {onHideComplete: () => this.props.removeAlert(i)})
        ) : 
          (toastr.warning(item.message), {onHideComplete: () => this.props.removeAlert(i)})
        )
        

      ))
    }
    return (impostorAlert || this.props.alerts) ? (
      <div id="global-alerts">
        { impostorAlert }
      
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            transitionIn="bounceIn"
            transitionOut="bounceOut"
            showCloseButton={false}
            progressBar={false}
            closeOnToastrClick 
            />
      </div>
    ) : null;
  }
}

export default GlobalAlerts;
