import React, { Component } from 'react';
import features from '../../featureFlags';
import {
  Form,
  CustomInput,
  Button,
  FormGroup,
  Alert
} from 'reactstrap';
import Util from '../../util.js';

class Features extends Component {
  constructor(props) {
    super(props);
    
    this.state = {};
  }

  handleSubmit = (event) => {
    event.preventDefault();
    Util.setFeatures(this.state);
    window.location.reload(true);
  };

  handleClear = () => {
    Util.clearFeatures();
    window.location.reload(true);
  };

  handleChange = (event) => {
    const value = this.state.hasOwnProperty(event.target.id) ? !this.state[event.target.id] : !Util.checkFeature(event.target.id);
    this.setState({
      [event.target.id]: value
    });
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Alert color="warning">
          Changes to these settings will affect <em>your browser </em>
          until you <strong>reset to default</strong> (even after logging out).
        </Alert>
        { features.map((item, i)=>(
          <CustomInput
            key={i}
            type="checkbox"
            id={item}
            label={item}
            checked={this.state.hasOwnProperty(item) ? this.state[item] : Util.checkFeature(item)}
            onChange={this.handleChange}
          />
        )) }
        <FormGroup className="mt-4">
          <Button type="submit" color="primary" disabled={Object.keys(this.state).length === 0}>
            Save
          </Button>
          <Button className="ml-2" onClick={this.handleClear} disabled={!Util.getFeatures()}>
            Reset to default
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

export default Features;
