/**
 * Alerts
 */

export const addAlert = (alert) => ({
  type: 'ADD_ALERT',
  payload: alert
});

export const removeAlert = (index) => ({
  type: 'REMOVE_ALERT',
  payload: index
});

export const updateAlert = (index, data) => ({
  type: 'UPDATE_ALERT',
  payload: {
    index,
    data
  }
});

export const clearAlerts = () => ({
  type: 'CLEAR_ALERTS'
});
