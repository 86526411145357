import { connect } from 'react-redux';
import { fetchConversationMessages, sendRequestMeetup, cancelFellowship, sendMessage, updateConversationMessages,getMessages } from '../actions';

import ConversationDetails from '../components/pages/Conversations/show';

const mapStateToProps = (state) => {
  return {
    state: state.conversationMessages.state,
    id: state.conversationMessages.id,
    messages: state.conversationMessages.messages,
    request_meet: state.conversationMessages.request_meet,
    partner_profile: state.conversationMessages.partner_profile,
    profile: state.profile,
    stateFor: state.conversationMessages.stateFor,
    fellowship_id: state.conversationMessages.fellowship_id
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchConversationMessages: (id) => dispatch(fetchConversationMessages(id)),
  sendRequestMeetup: (id) => dispatch(sendRequestMeetup(id)),
  cancelFellowship: (id) => dispatch(cancelFellowship(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationDetails);
