import React, { Component } from 'react';
import states from '../../reduxStates';
import { 
  CustomInput,
  Row,
  Col
} from 'reactstrap';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

class Profiles extends Component {
  constructor(props) {
    super(props);

    if (this.props.profileState === states.NEW) {
      this.props.fetchProfiles();
    }

    this.state = {
      advancedMode: false,
      filteredCount: null
    };

    this.tableOptions = {
      filters: {
        boolean: (
          ({ onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}>
              <option value="">All</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
          )
        )
      },
      formatters: {
        tostring: (
          d => d.value.toString()
        ),
        datetime: (
          d => new Date(d.value).toLocaleString("en-CA", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit'
          })
        ),
        number: (
          d => (
            <div className="text-right">
              { Number(d.value).toLocaleString('en-CA') }
            </div>
          )
        )
      }
    }
  }

  toggleAdvanced = () => {
    this.setState({
      advancedMode: !this.state.advancedMode
    });
  };

  updateFilteredCount = () => {
    if (this.tableRef.state.filtered.length > 0) {
      this.setState({
        filteredCount: this.tableRef.state.sortedData.length
      });
    } else {
      this.setState({
        filteredCount: null
      });
    }
  };

  render() {
    const data = this.props.profiles;
    const count = this.state.filteredCount ? this.state.filteredCount : data.length;
    const columns = [
      {
        Header: 'id',
        accessor: 'id',
        maxWidth: 50,
        show: this.state.advancedMode
      },
      {
        Header: 'user id',
        accessor: 'user_id',
        maxWidth: 50
      },
      { 
        Header: 'Matchable',
        accessor: 'matchable',
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean
      },
      {
        Header: 'Complete',
        accessor: 'complete',
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean
      },
      {
        Header: 'Demo',
        accessor: 'demo_account',
        maxWidth: 60,
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean
      },
      {
        Header: 'Preferred Down',
        accessor: 'preferred_down_payment',
        Cell: this.tableOptions.formatters.number
      },
      {
        Header: 'Maximum Down',
        accessor: 'maximum_down_payment',
        Cell: this.tableOptions.formatters.number
      },
      {
        Header: 'Preferred Monthly',
        accessor: 'preferred_monthly_payment',
        Cell: this.tableOptions.formatters.number
      },
      {
        Header: 'Maximum Monthly',
        accessor: 'maximum_monthly_payment',
        Cell: this.tableOptions.formatters.number
      },
      {
        Header: 'Neighbourhood',
        accessor: 'neighbourhood'
      },
      {
        Header: 'Ownership Term',
        accessor: 'ownership_term'
      },
      {
        Header: 'Dependants',
        accessor: 'dependants',
        Cell: this.tableOptions.formatters.number
      },
      { Header: 'Birthday', accessor: 'birthday' },
      { Header: 'Gender', accessor: 'gender' },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: this.tableOptions.formatters.datetime,
        show: this.state.advancedMode
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: this.tableOptions.formatters.datetime,
        show: this.state.advancedMode
      }
    ];

    return (
      <React.Fragment>
        <Row>
          <Col xs={9}>
            <CustomInput
              type="checkbox"
              id="advancedMode"
              label="Advanced Mode (show all columns)"
              className="mb-3"
              checked={this.state.advancedMode}
              onChange={this.toggleAdvanced}
            />
          </Col>
          <Col xs={3} className="text-right">
            Count: { count }
          </Col>
        </Row>
        <ReactTable
          ref={(r) => {this.tableRef = r}}
          data={data}
          columns={columns}
          defaultPageSize={20}
          filterable
          onFilteredChange={this.updateFilteredCount}
          className="-striped"
          defaultSorted={[{ id: 'user_id', desc: true }]}
          SubComponent={row => (
            <Link to={`/admin/profile/${row.original.id}`}>View</Link>
          )}
        />
      </React.Fragment>
    );
  }
}

export default Profiles;
