import React, { Component } from 'react';
import {
    FormGroup,
    Input,
    Label,
    Col,
    Form
} from 'reactstrap';

class SocialForm extends Component {

    render() {
        const fetching = this.props.fetching;

        return (
            <>
                <FormGroup row className="mt-5 mb-0 pb-0">
                    <Label for="gender" sm={12}>
                        <strong className="font-weight-bold">Social</strong>
                    </Label>
                </FormGroup>
                <FormGroup row >
                    <Label for="exampleText" sm={12}>Instagram handle</Label>
                    <Col sm={12}>
                        <Input
                            type="text"
                            name="instagram_link"
                            defaultValue={this.props.instagram}
                            disabled={fetching}
                            onChange={(event)=> this.props.handleChange('instagram_link', event.target.value)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row >
                    <Label for="exampleText" sm={12}>Twitter handle</Label>
                    <Col sm={12}>
                        <Input
                            type="text"
                            name="twitter_link"
                            defaultValue={this.props.twitter}
                            disabled={fetching}
                            onChange={(event)=> this.props.handleChange('twitter_link', event.target.value)}
                        />

                    </Col>
                </FormGroup>
                <FormGroup row >
                    <Label for="exampleText" sm={12}>TikTok handle</Label>
                    <Col sm={12}>
                        <Input
                            type="text"
                            name="tiktok_link"
                            defaultValue={this.props.tiktok}
                            disabled={fetching}
                            onChange={(event)=> this.props.handleChange('tiktok_link', event.target.value)}
                        />

                    </Col>
                </FormGroup>
            </>
        );
    }
}

export default SocialForm;
