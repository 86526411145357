import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from './_Page';
import LoginForm from '../../containers/LoginForm';
import ForgotPasswordForm from '../../containers/ForgotPasswordForm';
import {
  Button,
  Card,
  CardBody
} from 'reactstrap';
import Logo from '../Logo';
import SimpleModal from '../utils/SimpleModal';
import PublicFooter from './PublicFooter';
import Util from '../../util'

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forgotPasswordModal: false
    };
  }

  forgotPasswordToggle = () => {
    this.setState({
      forgotPasswordModal: !this.state.forgotPasswordModal
    });
  }

  redirectToUrl = (url) =>{
    this.props.history.push(url);
  }

  render() {
    let redirectUrl = this.props.location.state && this.props.location.state.redirectUrl;
    let queryObj = Util.parseSearchHash(this.props.location.search)
    let oauthError = (queryObj && queryObj.errors === 'user_exist') ? 'An account with this email address already exists. Please login with your password.' : null;
    return (
      <Page title="Log In" pageClass="login" hideTitle background hideNavbar darkMode hideFooter>
        <h1 className="text-center text-light mb-5">
          <Logo height="70px" textColor="#fff" />
        </h1>
        <Card className="login-signup-card ">
          <CardBody>
            <div className="text-center mb-3 header-text font-20">Log In</div>
            <LoginForm 
            redirectUrl={redirectUrl}
            redirectToUrl={this.redirectToUrl}
            oauthError={oauthError}
            />
            <p className="text-center">
              <Link to="#" onClick={this.forgotPasswordToggle} className="forgot-password-link">Forgot password?</Link>
            </p>
            <hr />
            <p className="text-center  mt-3 new-account">
              Don’t have an account? 
            </p>
            <p className="text-center mb-3">
              <Button tag={Link} to="/signup" size="sm" className="theme-button-inverse signup-login" >Sign Up Here</Button>
            </p>
          </CardBody>
        </Card>
        <PublicFooter/>

        <SimpleModal
          isOpen={this.state.forgotPasswordModal}
          toggle={this.forgotPasswordToggle}
          title="Forgot Password?"
          buttons={[{ text: 'Close' }]}>
          <p>We’ll send you a link to reset your account password!</p>
          <ForgotPasswordForm />
        </SimpleModal>

      </Page>
    );
  }
}

export default Login;
