import React, { Component } from 'react';
import states from '../../reduxStates';
import UserModal from './UserModal';
import {
  Button,
  CustomInput,
  Row,
  Col
} from 'reactstrap';
import ReactTable from 'react-table';
import Util from '../../util';

class Users extends Component {
  constructor(props) {
    super(props);

    if (this.props.userState === states.NEW) {
      this.props.fetchUsers();
    }

    if (this.props.profileState === states.NEW) {
      this.props.fetchProfiles();
    }

    this.state = {
      advancedMode: false,
      filteredCount: null
    }

    this.tableOptions = {
      filters: {
        boolean: (
          ({ onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}>
              <option value="">All</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
          )
        )
      },
      formatters: {
        tostring: (
          d => d.value.toString()
        ),
        datetime: (
          d => new Date(d.value).toLocaleString("en-CA", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit'
          })
        )
      }
    }
  }

  toggleAdvanced = () => {
    this.setState({
      advancedMode: !this.state.advancedMode
    });
  };

  updateFilteredCount = () => {
    if (this.tableRef.state.filtered.length > 0) {
      this.setState({
        filteredCount: this.tableRef.state.sortedData.length
      });
    } else {
      this.setState({
        filteredCount: null
      });
    }
  };

  downloadUsers = () => Util.fetchCSV('/admin/users');

  render() {
    const data = this.props.users;
    const count = this.state.filteredCount ? this.state.filteredCount : data.length;
    const columns = [
      {
        Header: 'id',
        accessor: 'id',
        maxWidth: 50
      },
      {
        Header: 'First Name',
        accessor: 'first_name'
      },
      {
        Header: 'Last Name',
        accessor: 'last_name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Role',
        accessor: 'role'
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        minWidth: 150,
        Cell: this.tableOptions.formatters.datetime
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: this.tableOptions.formatters.datetime,
        show: this.state.advancedMode
      },
      {
        Header: 'provider',
        accessor: 'provider',
        show: this.state.advancedMode
      },
      {
        Header: 'uid',
        accessor: 'uid',
        show: this.state.advancedMode
      },
      {
        Header: 'allow_password_change',
        accessor: 'allow_password_change',
        Cell: this.tableOptions.formatters.tostring,
        Filter: this.tableOptions.filters.boolean,
        show: this.state.advancedMode
      }
    ];

    return (
      <React.Fragment>
        <Row>
          <Col xs={9}>
            <CustomInput
              type="checkbox"
              id="advancedMode"
              label="Advanced Mode (show all columns)"
              className="mb-3"
              checked={this.state.advancedMode}
              onChange={this.toggleAdvanced}
            />
          </Col>
          <Col xs={3} className="text-right">
            Count: { count }
          </Col>
        </Row>
        <Button onClick={this.downloadUsers}>
          Download
        </Button>
        <ReactTable
          ref={(r) => {this.tableRef = r}}
          data={data}
          columns={columns}
          defaultPageSize={20}
          filterable
          onFilteredChange={this.updateFilteredCount}
          className="-striped"
          defaultSorted={[{ id: 'id', desc: true }]}
          SubComponent={row => (
            <div className="p-3">
              <UserModal
                profile={
                  this.props.profiles.find(p => p.user_id === row.original.id) || {}
                }
              />
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}

export default Users;
