
import React, { Component } from 'react';
import { Button, Row, Col  } from 'reactstrap';
import _ from 'lodash';
import ImageLightBox from '../../ImageLightBox';

class PropertyImages extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isOpen: false,
          photoIndex: null
        };
    }
    toggleLightBox = (photoIndex)=>{
        this.setState({ isOpen: !this.state.isOpen, photoIndex: photoIndex });
    }
    render(){
        const {images} = this.props
        return(
            <Row className='m-0 mt-2'>
                <Col sm={12} md={6} className="main-image p-0 cursor-pointer ">
                    <img src={images[0] || '/images/missing-image.png'} alt="main image" className="img-fluid" onClick={()=> {images[0] && this.toggleLightBox(0)}} />
                </Col>
                <Col sm={12} md={6} className="gallery-right-section">
                    <Row className="m-0">
                        <Col md={6} sm={6} xs={6} className="sub-images cursor-pointer col-sm-12 col-md-6 p-0 pr-2 pb-2">
                            <img src={images[1] || '/images/missing-image.png'} alt="image" className="img-fluid" onClick={()=> {images[1] && this.toggleLightBox(1)}} />
                        </Col>
                        <Col md={6} sm={6} xs={6} className="sub-images cursor-pointer col-sm-12 col-md-6  p-0 pb-2">
                            <img src={images[2] || '/images/missing-image.png'} alt="image" className="img-fluid" onClick={()=> {images[2] && this.toggleLightBox(2)}} />
                        </Col>
                        <Col md={6}  className="d-none d-lg-block d-md-block sub-images  cursor-pointer col-sm-12 col-md-6  p-0 p-0 pr-2 ">
                            <img src={images[3] || '/images/missing-image.png'} alt="image" className="img-fluid" onClick={()=> {images[3] && this.toggleLightBox(3)}}/>
                        </Col>
                        <Col md={6}  className="d-none d-lg-block d-md-block sub-images cursor-pointer padding-top-10 col-sm-12 col-md-6  p-0">
                            <img src={images[4] || '/images/missing-image.png'} alt="image" className="img-fluid" onClick={()=> {images[4] && this.toggleLightBox(4)}}/>
                        </Col>
                        {(images.length > 0) && <Button className="d-none d-lg-block d-md-block property-rounded-button view-all-btn" onClick ={()=>this.toggleLightBox(0)}>
                            <img src="/icons/gallery.png" alt="icon" className='image'/>
                            View All
                        </Button>}
                        {(images.length > 0) && <Button className="d-none d-xs-block property-rounded-button view-all-btn mobile" onClick ={()=>this.toggleLightBox(0)}>
                            <img src="/icons/gallery.png" alt="icon" className='image'/>{images.length}
                        </Button>}
                        {this.state.isOpen && <ImageLightBox
                        images= {images}
                        photoIndex={this.state.photoIndex}
                        toggleClose={this.toggleLightBox}
                        />}
                    </Row>
                </Col>
            </Row>
        )
    }
}
export default PropertyImages;
