import { connect } from 'react-redux';
import { fetchAdminQuestions, createQuestion, updateQuestion, deleteQuestion } from '../../actions';
import Questions from '../components/Questions';

const mapStateToProps = (state) => ({
  questions: state.adminQuestions.items,
  questionsState: state.adminQuestions.state
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuestions: () => dispatch(fetchAdminQuestions()),
  createQuestion: (question) => dispatch(createQuestion(question)),
  updateQuestion: (question) => dispatch(updateQuestion(question)),
  deleteQuestion: (question) => dispatch(deleteQuestion(question))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Questions);
