import React, { Component } from 'react';
import Terms from './Terms'

class TermsOfService extends Component {

  render(){
    return (
      <div className="terms-of-service">
        <Terms/>
      </div>
    );
  }
}

export default TermsOfService;

