import React, { Component } from 'react';
import {
  NavLink as RRNavLink,
  Route,
  Switch
} from 'react-router-dom';
import Page from '../../components/pages/_Page';
import Answers from '../containers/Answers';
import Questions from '../containers/Questions';
import Matches from '../containers/Matches';
import Profiles from '../containers/Profiles';
import Profile from '../containers/Profile';
import Users from '../containers/Users';
import Features from './Features.js';
import {
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';

class AdminDashboard extends Component {
  render() {
    const path = this.props.match.path;

    const sections = [
      { title: 'Users', path: '/users', component: Users },
      { title: 'Profiles', path: '/profiles', component: Profiles },
      { title: 'Answers', path: '/answers', component: Answers },
      { title: 'Questions', path: '/questions', component: Questions },
      { title: 'Matches', path: '/matches', component: Matches },
      { title: 'Features', path: '/features', component: Features },
    ];

    const routes = sections.map((item, key) =>
      <Route key={key} path={path+item.path} component={item.component} />
    );
    routes.push(
      <Route key='profile' path={path+'/profile/:id'} component={Profile} />
    );
    const links = sections.map((item, key) => (
      <NavItem key={key}>
        <NavLink tag={RRNavLink} to={path+item.path}>
          { item.title }
        </NavLink>
      </NavItem>
    ));

    return (
      <Page title="Admin Dashboard" container="fluid" background>
        <Card>
          <CardHeader>
            <Nav pills card>
              { links }
            </Nav>
          </CardHeader>
          <CardBody>
            <Switch>
              { routes }
            </Switch>
          </CardBody>
        </Card>
      </Page>
    );
  };
}

export default AdminDashboard;
