import { connect } from 'react-redux';
import PropertyDetails from '../components/pages/Properties/Show';
import { fetchProperty, toggleFavorite, setBookTour } from '../actions';

const mapStateToProps = state => ({
    property: state.properties.property,
    state: state.properties.state,
    stateFor: state.properties.stateFor
});
  
const mapDispatchToProps = dispatch => ({
  fetchProperty: (mslNumber) => dispatch(fetchProperty(mslNumber)),
  toggleFavorite: (mslNumber,stateFor) => dispatch(toggleFavorite(mslNumber, stateFor)),
  setBookTour: (mslNumber,stateFor) => dispatch(setBookTour(mslNumber, stateFor)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyDetails);
