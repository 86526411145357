import { connect } from 'react-redux';
import { updatePassword } from '../actions';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import { withRouter } from 'react-router-dom';

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (
    password,
    passwordConfirmation
  ) => dispatch(updatePassword(
    password,
    passwordConfirmation
  ))
});

const mapStateToProps = (state) => ({
  errors: state.auth.authErrors,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePasswordForm));
