import React, { Component } from 'react';
import { 
  Row, 
  Col,
  Badge
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import ChatInput from './ChatInput'
class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrolledToEnd: true
    }
  }

  componentDidMount() {
    this.markSeen();
    this.scrollChat();
    window.addEventListener("focus", this.markSeen);
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.markSeen);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.markSeen();
      this.scrollChat();
    }
  }

  scrollChat = () => {
    if (this.state.scrolledToEnd) {
      this.chatRef.scrollTo({
        top: this.chatRef.scrollHeight,
        behavior: 'auto'
      });
    }
  };

  markSeen = () => {
    if (document.hasFocus()) {
      this.props.markSeen(this.props.cid);
    }
  };

  sendMessage = (cid, message) =>{
      this.props.sendMessage(cid, message)
  }

  handleScroll = (event) => {
    const e = event.target;
    if (e.scrollHeight === e.clientHeight + e.scrollTop) {
      this.setState({
        scrolledToEnd: true
      });
    } else if (this.state.scrolledToEnd) {
      this.setState({
        scrolledToEnd: false
      });
    }
  };
  
  getMessages = (messages,profile) =>{
    let messageList=[]
    if(!_.isEmpty( messages )){
       _.forOwn(messages, function(value, key) {
        messageList.push(
                        <div className="date-area" key={key}>
                          <div className="divider">
                            <span>{key.toString()}</span>
                          </div>
                          <>
                            {value.map((record, index) =>{
                              return <div className={"message " + (record.profile_id == profile.id ? 'right' : 'left')} key={index}>
                                <div className="message-text">{record.content}</div>
                                <small>{moment(record.created_at).format("h:mm")}</small>
                              </div>
                            })}
                          </>
                          
                          <div className="spacer">&nbsp;</div>
                        </div>
                        )
       } );
     
    }
    return messageList
  }

  render() {
    let {messages, profile, sendMessage, cid} = this.props
    return (
      <>
      <Row className="chat-area">
         <div
            id="messages"
            className="messages-area-scroll messages-area"
            ref={(r)=>{this.chatRef=r}}
            onScroll={this.handleScroll}
          >
            {this.getMessages(messages, profile)}
          </div>
            
            <ChatInput
              sendMessage={sendMessage}
              conversationId={cid}
              disabled={false}
            />
      </Row>
      </>
    );
  }
}

export default Chat;