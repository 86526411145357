import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import {
  Container
} from 'reactstrap';
import ReactGA from 'react-ga';
import Navbar from '../../containers/Navbar';
import Footer from '../Footer';
// import SidebarContent from "../SidebarContent";
// import Sidebar from 'react-sidebar'

// const mql = window.matchMedia(`(min-width: 800px)`);
class Page extends Component {
  // constructor(props) {
  //   super(props);
    
  // }
  componentDidMount() {
    // mql.addListener(this.mediaQueryChanged);
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0,0);
  }

  // componentWillUnmount() {
  //   mql.removeListener(this.mediaQueryChanged);
  // }

  // onSetOpen(open) {
  //   this.setState({ open });
  // }

  // mediaQueryChanged() {
  //   this.setState({
  //     docked: mql.matches,
  //     open: false
  //   });
  // }

  // toggleOpen(ev) {
  //   this.setState({ open: !this.state.open });

  //   if (ev) {
  //     ev.preventDefault();
  //   }
  // }

  render() {
    const pageTitle = this.props.title && !this.props.hideTitle ? (<h1 className="pagetitle">{this.props.title}</h1>) : null;
    const documentTitle = this.props.title ? `husmates.com | ${this.props.title}` : "husmates.com";
    const background = this.props.background ? (
      <div className="background">
        <div className="background-top"></div>
        <div className="background-bottom"></div>
        <div className="background-overlay"></div>
      </div>
    ) : null;

    // const sidebar = <SidebarContent currentPage={this.props.pageClass} />;
    // const sidebarProps = {
    //   sidebar,
    //   docked: this.state.docked,
    //   open: this.state.open,
    //   onSetOpen: this.onSetOpen,
    // };
    return (
      <>
        <DocumentTitle title={documentTitle}>
        <React.Fragment>
          { !this.props.hideNavbar ? <Navbar /> : null }
          <main
            id="main-content"
            className={`
              page
              page-${this.props.pageClass ? this.props.pageClass : 'default'}
              page-theme-${this.props.darkMode ? 'dark' : 'light'}
            `}
          >
            {background}
            <Container fluid={this.props.container ==='fluid'}>
              {pageTitle}
              {this.props.children}
            </Container>
          </main>
          { !this.props.hideFooter ? <Footer darkMode={this.props.darkMode} /> : null }
        </React.Fragment>
      </DocumentTitle>
        
      </>
    );
  }
}

export default Page;
