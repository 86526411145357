import React, { Component } from 'react';
import Page from './_Page';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
} from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';

import BasicProfileForm from '../../containers/BasicProfileForm';
import AboutProfileForm from '../../containers/AboutProfileForm';
import UserSettings from '../../containers/UserSettings';
import states from '../../reduxStates';

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      waiting: false,
      done: false,
      errors:[]
    };
  }

  
  handleSave = () => {
    const promises = [];
    if(this.state.activeTab === 1){
      promises.push( BasicProfileForm.validate(this.props.profile,
        this.props.profile['location_selections_attributes']
        .map(id => this.props.locationSelections[id])
        ))
    }else if(this.state.activeTab === 2){
      promises.push( BasicProfileForm.validate(this.props.profile,
        this.props.profile['location_selections_attributes']
        .map(id => this.props.locationSelections[id])
        ))
      promises.push(AboutProfileForm.validate(this.props.profile))
    }
    this.setState({ waiting: true });
    let tabRef = this.tabRef
    Promise.all(promises).then(results => {
      if (!results.flat().length) {
        this.props.saveProfile().then(() => {
          if (this.props.profileState === states.SAVED) {
            if(this.state.activeTab === 1){
              this.props.clearAlerts()
              this.setState({activeTab: 2, waiting: false, errors: [] });
              const sectionToScrollIn = document.querySelector(`#main-content`);
              sectionToScrollIn.scrollIntoView({ behavior: 'smooth' })
            }
            else{
              this.handleDone()
              const sectionToScrollIn = document.querySelector(`#main-content`);
              sectionToScrollIn.scrollIntoView({ behavior: 'smooth' })
            }
          } else {
            this.props.addAlert({
              message: "Something went wrong. Please try again."
            });
          }
        });
      } else {
        this.setState({
          errors: results.flat(),
          waiting:false
        }) 
        // ()=>{
        //   this.props.addAlert({
        //     message: "Could not save profile because it is incomplete. Please fill out all fields and try again.",
        //     color: "warning"
        //   });
        // });
        // this.props.addAlert({
        //   message: "Could not save profile because it is incomplete. Please fill out all fields and try again.",
        //   color: "warning"
        // });
      }
    });
  };

  handleChange = (key,value)=>{
    this.props.updateProfile(key,value)
  }

  handleCancel = () => {
    this.props.fetchProfile();
    this.props.history.goBack();
  };

  toggle = (tab) => this.setState({ 
    activeTab: tab
  });

  // getTabName = (index) => {
  //   if(index === 1){
  //     return "Edit Profile"
  //   }else if(index === 2){
  //     return "About You"
  //   }else if(index === 3){
  //     return "Account Settings"
  //   }
  // }

  handleDone = () => {
    this.setState({ waiting: true });
    if (this.props.profile.complete) {
      this.props.clearAlerts()
      this.props.updateProfile('matchable', true);
      this.props.saveProfile().then(() => {
        this.setState({
          waiting: false,
          done: true,
          errors: []
        });
        this.props.addAlert({
          message: "Profile saved!",
          duration: 2,
          color: "success"
        });
      });
    } else {
      // this.props.addAlert({
      //   message: "Your profile isn't quite complete yet, go back and make sure you filled everything!"
      // });
    }
  };

  render() {
    const waitingIcon = this.state.waiting ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;
    const doneIcon = null;
    if (this.state.done) {
      return (
        <Redirect to="/dashboard" />
      );
    }
    return (
      <Page title="Edit Profile" pageClass="editProfile" hideTitle hideFooter background={false}  >
        <Card>
          <CardHeader >
            <Row>
              <Col sm="6" >
                <strong className="page-header my-1">Your Profile</strong>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={12} md={6} className="navigarion-with-actions">
                <Nav tabs >
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === 1}
                      onClick={() => { this.toggle(1); }}
                      href="#">
                      Edit Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === 2}
                      onClick={() => { this.toggle(2); }}
                      href="#">
                      About You
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === 3}
                      onClick={() => { this.toggle(3); }}
                      href="#">
                      Account Settings
                    </NavLink>
                  </NavItem>
                </Nav>
                
              </Col>
              <Col md={6} className={"d-none d-md-block text-right "+ (this.state.activeTab === 3 ? 'hide' : '')}>
                <Button className="btn-sm theme-button-inverse b-100"  onClick={this.handleCancel}>Cancel</Button>
                { (this.state.activeTab !== 2) ? 
                  <Button onClick={this.handleSave} color="primary" className="btn-sm theme-button ml-3 b-100">Next {waitingIcon}</Button> :
                  <Button onClick={this.handleSave} color="success" className="btn-sm theme-button ml-3 b-100">Save {waitingIcon}</Button> 
                }
              </Col>
            </Row>
            <TabContent activeTab={this.state.activeTab} className="p-3"  ref={(r)=>{this.tabRef=r}}>
              <TabPane tabId={1}>
                <BasicProfileForm errors={this.state.errors}/>
              </TabPane>
              <TabPane tabId={2}>
                <AboutProfileForm errors={this.state.errors}/>
              </TabPane> 
              <TabPane tabId={3}>
                <UserSettings/>
              </TabPane> 
            </TabContent>
          </CardBody>
          <CardFooter className={"text-right " + (this.state.activeTab === 3 ? 'hide' : '')}>
            <Button onClick={this.handleCancel} className="mr-2 theme-button-inverse b-100">Cancel</Button>
            { (this.state.activeTab !== 2) ? 
                <Button onClick={this.handleSave} color="primary" className="theme-button b-100">Next {waitingIcon}</Button> :
                <Button onClick={this.handleSave} color="success" className="theme-button b-100">Save {waitingIcon}</Button> 
              }
          </CardFooter>
        </Card>
      </Page>
    );
  }
}

export default withRouter(EditProfile);
