import React, { Component } from 'react';
import _ from 'lodash';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import ProfileCompletionInfo from './ProfileCompletionInfo';
import WelcomeInfo from './WelcomeInfo';
import PropertiesList from './PropertiesList';
import NewsCard from './NewsCard';

export default class CompleteProfileView extends Component {

  render() {
    let profile = this.props.profile
    return (
    <Container fluid>
      <Row>
        <Col xs="12" sm="12" md={{ size: 5, offset: 3 }} className="mt-3 ">
          <WelcomeInfo firstName={profile.first_name} />
        </Col>
      </Row>
      { this.props.news && !_.isEmpty(this.props.news) &&  <NewsCard news={this.props.news}/>}

      {(profile.completion_percentage < 100) && <Row>
        <Col xs="12" sm="12" md={{ size: 5, offset: 3 }} className="mt-3 ">
            <ProfileCompletionInfo {...this.props} />
        </Col>
      </Row>}
      <Row>
        <Col xs="12" sm="12" md={{ size: 5, offset: 3 }} className="mt-3 ">
            <PropertiesList />
        </Col>
      </Row>
      
    </Container>
    );
  }
}
