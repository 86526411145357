const profile = (state = {}, action) => {
  if (!action.meta || action.meta.type !== 'profile') {
    return state;
  }

  switch (action.type) {
    case 'RECEIVE':
      if (action.payload) {
        return {
          ...action.payload,
        };
      } else {
        return state;
      }
    case 'UPDATE':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default profile;
