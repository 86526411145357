import React, { Component } from 'react';
import states from '../../../reduxStates';
import Page from '../_Page';
import _ from 'lodash'
import Util from '../../../util'
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {  withRouter } from 'react-router-dom';
import ProfileView from '../../pages/Profiles/ProfileView';
import {
    CardBody,
    Spinner,
  } from 'reactstrap';

class ProfileInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: false
    }
  }
  componentDidMount() {
    const localAuth = Object.keys(Util.getLocalAuthObj());
    if(this.props.match.params.id && !_.isEmpty(localAuth)){
      let fellowshipId = this.props.match.params.id
      fellowshipId = (fellowshipId.indexOf('_') > -1 ) ? _.last(_.split(fellowshipId,'_')) : fellowshipId
      console.log(fellowshipId)
      this.props.fetchPartnerDetails(fellowshipId);
    }
  }

  
  render() {
    console.log(this.props.state)
    console.log(this.props.profile)
      const contentFetching = (
          <CardBody className="text-center">
            <Spinner className="m-auto" type="grow" />
          </CardBody>
      );
      const path = (this.props.location && this.props.location.state && this.props.location.state.backPath) ? this.props.location.state.backPath : `partners/${this.props.match.params.id}`;
      const pageContent = (
        (this.props.state === states.FETCHING || this.props.state === states.NEW) ? contentFetching : (
        <ProfileView
          locationData={this.props.location}
          locationSelectionData={this.props.locationSelection}
          profile={this.props.profile}
          createFellowship={(stringId) => this.props.createFellowship(_.last(_.split(stringId,'_'))) }
          cancelFellowship={(stringId) => this.props.cancelFellowship(_.last(_.split(stringId,'_')))}
          fellowship={this.props.profile.fellowship}
          currentProfile={this.props.currentProfile}
        />)
      );

      const localAuth = Object.keys(Util.getLocalAuthObj());
      console.log(localAuth)
      if(_.isEmpty(localAuth)){
        return (
          <Redirect to={{
            pathname: "/login",
            state: {redirectUrl: this.props.location.pathname}
          }} />
        )
      }else if (this.props.state === states.FETCHING) {
        return (
          <Page title={`Fetching profile details`} pageClass="profiles" hideFooter={true}>
            <Spinner className="m-auto" type="grow" />
          </Page>
        );
      }else{
        return(
            <Page title="" pageClass="partner-show" hideFooter={true}>
                {pageContent}
            </Page>
        )
      }
    }
}

export default withRouter(ProfileInfo);
