import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from './_Page';
import RegistrationForm from '../../containers/RegistrationForm';
import {
  Card,
  CardBody,
  Button
} from 'reactstrap';
import Logo from '../Logo';
import TermsOfService from '../TermsOfService';
import SimpleModal from '../utils/SimpleModal';
import PublicFooter from './PublicFooter';
import Util from '../../util'
class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tosModal: false
    };
  }

  tosToggle = () => {
    this.setState({
      tosModal: !this.state.tosModal
    });
  }

  render() {
    let queryObj = Util.parseSearchHash(this.props.location.search)
    let oauthError = (queryObj && queryObj.errors === 'user_exist') ? 'An account with this email address already exists. Please login with your password.' : null;
    return (
      <Page title="Sign Up" pageClass="signup" hideTitle background hideNavbar darkMode hideFooter>
        <h1 className="text-center text-light mb-5">
          <Logo height="70px" textBeside textColor="#fff" />
        </h1>
        <Card className="login-signup-card">
          <CardBody>
            <div className="text-center mb-3 header-text font-20">Sign Up</div>
            <RegistrationForm oauthError={oauthError}/>
            <p className="text-center forgot-password-link">
              By signing up, you agree to the <Link to="#" onClick={this.tosToggle} className="term-of-service">Terms of Service</Link>.
            </p>
            <hr />
            <p className="text-center  mt-3 new-account">
              Already have an account?
            </p>
            <p className="text-center mb-3">
              <Button tag={Link} to="/login" size="sm" outline className="theme-button-inverse signup-login">Log in here</Button>
            </p>
          </CardBody>
        </Card>
        <PublicFooter/>
        
        <SimpleModal
          isOpen={this.state.tosModal}
          toggle={this.tosToggle}
          className="tosModal"
          title="Terms of Service"
          buttons={[{ text: 'Close' }]}>
          <TermsOfService />
        </SimpleModal>

      </Page>
    );
  }
}

export default Signup;
