
import React, { Component } from 'react';
import {  Button, Row, Col,Input  } from 'reactstrap';
import Page from '../_Page';
import states from '../../../reduxStates';
import Rooms from './Rooms';
import PropertyImages from './PropertyImages';
import NumberFormat from 'react-number-format';


class PropertyShow extends Component {

    componentWillUnmount() {
        this.unmounting = true;
    }
    componentDidMount() {
        if(this.props.match && this.props.match.params){
            this.props.fetchProperty(this.props.match.params.id);
        }
    }
    goBack = () =>{
        this.props.history.goBack();
    }
    toggleFavorite = (msl_number) =>{
        this.props.toggleFavorite(msl_number, 'TOGGLE_FAVORITE_SINGLE')
    }
    setBookTour = (msl_number) =>{
        this.props.setBookTour(msl_number, 'BOOK_TOUR')
    }
    render(){
        // const errors = (this.props.errors) ? 
        //     this.props.errors.map((e, i) => (<Alert key={`error-${i}`} color="warning">{e}</Alert>)) :
        //     [];
        // const loadingIcon = this.state.waiting ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;
        const {property, state,stateFor} = this.props
        const loadingIcon =  <i className="fas fa-fw fa-spinner fa-spin"></i> 
        // const keys = Object.keys(property.rooms);
        return(
            <Page title="PropertyShow" pageClass="PropertyShow" container hideTitle background  hideFooter darkMode>
                
                <Row className="action">
                    <Col className="text-left p-0">
                        <Button  className="property-rounded-button margin-10 like-button hide">
                            <img src="/icons/like.svg" alt="icon" className='image'/>
                            &nbsp;Like&nbsp;
                        </Button>
                        {property && <Button className="property-rounded-button margin-10 add-to-wishlist" onClick={()=>this.toggleFavorite(property.msl_number)}>
                                {property.is_favorite ? (
                                    <img src="/icons/heart-fill.png" alt="icon" className='image'/>
                                ) : (
                                    <img src="/icons/heart.png" alt="icon" className='image'/>
                                )}
                                {property.is_favorite ? "Saved" : 'Save' }
                        </Button>}
                    </Col>
                    <Col className="text-right p-0">
                        {property && <Button className={"property-rounded-button dark margin-10 "+ (property.tour_requested ? 'tour-requested-button' : 'book-tour-button')} onClick={()=>this.setBookTour(property.msl_number)}>
                            {stateFor === 'BOOK_TOUR' ? loadingIcon : null } {property.tour_requested ? 'Tour Requested' : 'Book a Tour' }
                        </Button>}
                    </Col>
                </Row>
                <Row className="details-wrapper">
                    {(property) ? (
                        <Col className="">
                            <Row>
                                <Col className="text-left">
                                    <div className='title'>{property.street} | {property.propertyType}</div>
                                    <div className='text-muted sub-title'>{property.city_state}</div>
                                </Col>
                                <Col className="text-right">
                                    <span className='date-listed text-muted'>Listed {property.list_date} ago</span>
                                    <span className='property-status ml-2'>
                                    <span className="for-sale-circle"></span>
                                        For Sale
                                    </span>
                                </Col>
                            </Row>
                            <PropertyImages images={property.images}></PropertyImages>
                            
                            <Row className='m-0 mt-1'>
                                <Col md={8} sm={12}>
                                    <Row className="amount-and-status-details">
                                        <Col sm={12} className="p-0">
                                            <span className="amount">
                                                <NumberFormat value={property.list_price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </span>
                                            <span className="property-status ml-2">
                                                <span className="for-sale-circle"></span>
                                                For Sale
                                            </span>
                                            <span className='date-listed text-muted ml-2'>Listed {property.list_date} ago</span>
                                        </Col>
                                        <Col sm={12} className="p-0">
                                            {property.beds ? (<span><strong>{property.beds}</strong><span className="lighten-text">&nbsp;bd</span></span>) : null}  
                                            {property.bath ? (<span> <strong>{property.bath}</strong><span className="lighten-text">&nbsp;ba</span></span>) : null}  
                                            {property.kitchens ? (<span> <strong>{property.kitchens}</strong><span className="lighten-text">&nbsp;kit</span></span>) : null}  
                                            {property.sqft ? (<span> <strong>{property.sqft}</strong><span className="lighten-text">&nbsp;sqft</span></span>) : null}  
                                            {property.prkng ? (<span> <strong>{property.prkng}</strong><span className="lighten-text">&nbsp;prkng</span></span>) : null}  
                                        </Col>
                                    </Row>
                                    <Row className="mt-4 mb-2">
                                        <Col sm={12} className='title mb-2 p-0'>Description</Col>
                                        <Col sm={12} className='property-description p-0'>{property.description}</Col>
                                    </Row>
                                    <hr/>
                                    <Row className="mt-2 mb-2">
                                        <Col sm={12} className='title mb-2 p-0'>Details</Col>
                                        <Col sm={12} className='p-0'>
                                            {property.propertyType && <Row className="mt-2 mb-3">
                                                <Col sm={4} md={3} className="detail-header">Type</Col>
                                                <Col sm={8} md={9} className="text-left detail-subheader">{property.propertyType}</Col>
                                            </Row>}
                                            {property.basements && <Row className="mt-3 mb-3">
                                                <Col sm={4} md={3} className="detail-header">Basement</Col>
                                                <Col sm={8} md={9} className="text-left detail-subheader">{property.basements}</Col>
                                            </Row>}
                                            {property.sqft && <Row className="mt-3 mb-3">
                                                <Col sm={4} md={3} className="detail-header">Sq Ft.</Col>
                                                <Col sm={8} md={9} className="text-left detail-subheader">{property.sqft} Sq ft. </Col>
                                            </Row>}
                                        {property.beds && <Row className="mt-3 mb-3">
                                                <Col sm={4} md={3} className="detail-header">Bedrooms</Col>
                                                <Col sm={8} md={9} className="text-left detail-subheader">{property.beds}</Col>
                                            </Row>}
                                        { property.baths && <Row className="mt-3 mb-3">
                                                <Col sm={4} md={3} className="detail-header">Bathrooms</Col>
                                                <Col sm={8} md={9} className="text-left detail-subheader">{property.baths}</Col>
                                            </Row>}
                                            
                                            {<Row className="mt-3 mb-3">
                                                <Col sm={4} md={3} className="detail-header">Parking</Col>
                                                <Col sm={8} md={9} className="text-left detail-subheader">{property.parking}</Col>
                                            </Row>}
                                            {property.airConditioning && <Row className="mt-3 mb-3">
                                                <Col sm={4} md={3} className="detail-header">Air-Conditioning</Col>
                                                <Col sm={8} md={9} className="text-left detail-subheader">{property.airConditioning}</Col>
                                            </Row>}
                                            {property.exteriorConstruction && <Row className="mt-3 mb-2">
                                                <Col sm={4} md={3} className="detail-header">External</Col>
                                                <Col sm={8} md={9} className="text-left detail-subheader">{property.exteriorConstruction}</Col>
                                            </Row>}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Rooms rooms={property.rooms} property={property}></Rooms>
                                    <hr />
                                    {property.extras && <Row className="mt-2 mb-4">
                                        <Col sm={12} className='title mb-2 p-0'>Extras</Col>
                                        <Col sm={12} className='property-description p-0'>{property.extras}</Col>
                                    </Row>}
                                </Col>
                                
                            </Row>
                        </Col>
                    ) :  ((state === states.FETCHING && stateFor === "REQUEST_PROPERTY")? (
                            <Col md={12} sm={12} >
                                <Row>
                                    <div className='m-auto pt-5 pb-5'>
                                        Fetching property details please wait {loadingIcon}
                                    </div>
                                </Row>
                            </Col>
                    ) : null ) }
                </Row>
            </Page>
        )
    }
}
export default PropertyShow;
