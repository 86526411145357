import { connect } from 'react-redux';
import { forgotPassword } from '../actions';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const mapStateToProps = state => ({
  state: state.auth.forgotPasswordState,
  error: state.auth.error
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: (email) => dispatch(forgotPassword(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordForm);
