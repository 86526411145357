import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Button
} from 'reactstrap';
import FloatingLabelInput from '../../components/utils/FloatingLabelInput';
import SimpleModal from '../../components/utils/SimpleModal';

class AddMatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addModal: false,
      first_user_id: '',
      second_user_id: '',
      value: '',
      visible: '',
    };
  }

  handleChange = (event) => this.setState({
    [event.target.name]: event.target.value
  });

  handleSave = (event) => {
    this.props.createMatch({
      first_user_id: this.state.first_user_id,
      second_user_id: this.state.second_user_id,
      value: this.state.value,
      visible: this.state.visible,
    }).then(this.props.fetchMatches);
    this.setState({
      first_user_id: '',
      second_user_id: '',
      value: '',
      visible: '',
    });
  };

  toggleAddModal = () => this.setState({
    addModal: !this.state.addModal
  });

  render() {
    return (
      <React.Fragment>
        <Form noValidate onSubmit={ (event) => event.preventDefault() }>
          <FormGroup>
            <FloatingLabelInput
              placeholder="First User ID"
              onChange={this.handleChange}
              name="first_user_id"
              value={this.state.first_user_id}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Second User ID"
              onChange={this.handleChange}
              name="second_user_id"
              value={this.state.second_user_id}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Match Value"
              onChange={this.handleChange}
              name="value"
              value={this.state.value}
            />
          </FormGroup>
          <FormGroup>
            <FloatingLabelInput
              placeholder="Visible?"
              onChange={this.handleChange}
              name="visible"
              value={this.state.visible}
            />
          </FormGroup>
          <FormGroup>
            <Button onClick={this.toggleAddModal} color="primary">
              Add Match
            </Button>
          </FormGroup>
        </Form>

        <SimpleModal
          isOpen={this.state.addModal}
          toggle={this.toggleAddModal}
          title="Are you sure?"
          buttons={[
            { text: 'Add Match', color: 'success', action: this.handleSave },
            { text: 'Cancel' }
          ]}>
          This change may be visible to users.
        </SimpleModal>
      </React.Fragment>
    );
  }
}

export default AddMatch;
