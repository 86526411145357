import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Button,
  Alert
} from 'reactstrap';
import FloatingLabelInput from './utils/FloatingLabelInput';

class UpdatePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirmation: "",
      success: false,
      waiting: false
    };
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;

    this.setState({
      [id]: value
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      success: false,
      waiting: true
    });

    this.props.updatePassword(
      this.state.password,
      this.state.passwordConfirmation,
    ).then((response) => {
      if (!this.unmounting) {
        if (response.ok) {
          this.setState({
            password: "",
            passwordConfirmation: "",
            success: true,
            waiting: false
          });
        } else {
          this.setState({
            waiting: false
          });
        }
      }
    });
  };

  render() {
    const errors = (this.props.errors) ?
      this.props.errors.map((e, i) =>
        (<Alert key={`error-${i}`} color="warning">{e}</Alert>)) :
        [];

    const loadingIcon = this.state.waiting ? (
      <i className="fas fa-fw fa-spinner fa-spin"></i>
    ) : null;

    return (
      <Form onSubmit={this.handleSubmit} noValidate>
        <FormGroup>
          <FloatingLabelInput
            id="password"
            type="password"
            placeholder="New password"
            onChange={this.handleChange}
            value={this.state.password}
            disabled={this.state.waiting}
            required />
        </FormGroup>
        <FormGroup>
          <FloatingLabelInput
            id="passwordConfirmation"
            type="password"
            placeholder="Confirm new password"
            onChange={this.handleChange}
            value={this.state.passwordConfirmation}
            disabled={this.state.waiting}
            required />
        </FormGroup>
        <FormGroup>
          <Button type="submit" color="primary" className="button-tall btn btn-primary btn-block">
            Update Password {loadingIcon}
          </Button>
        </FormGroup>
        {errors}
      </Form>
    );
  }
}

export default UpdatePasswordForm;
