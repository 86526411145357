import React, { Component } from 'react';

class PublicFooter extends Component {
  render() {
    return (
    	<div className="text-center mt-5 mb-5 d-flex justify-content-center align-items-center flex-column">
          <img src="/images/foresthill.png" alt="foresthill" className="foresthill-logo" />
          <span className="foresthill-text mt-3">Forest Hill Real Estate Brokerage<br/> 384 Queen St E,<br/>Toronto, ON M5A 1T1</span>
        </div>
    );
  }
}

export default PublicFooter;
