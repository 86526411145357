import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';
import { applyMiddleware, createStore, compose } from 'redux';
import rootReducer from './reducers';
import Root from './components/Root';
import thunk from 'redux-thunk';
import './resources/styles/css/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-table/react-table.css';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Root store={store} />
  , document.getElementById('root'));

unregister();
