import states from '../reduxStates';
import _ from 'lodash';

const initialState = {
    state: states.NEW,
    stateFor: null,
    properties: [],
    page: 1,
    pageSize: 10,
    count: 0,
    myLiked: [],
    property: null,
    suggestions: []
};
const updateProperty =(properties, mslNumber) =>{
  let index = _.findIndex(properties, {msl_number: mslNumber});
  if(index > -1){
    let property = properties[index]
    property.is_favorite = !property.is_favorite
    properties.splice(index, 1, property)
  }
  return properties
}
const match = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_PROPERTIES':
      return {
        ...state,
        state: states.FETCHING,
        stateFor: action.stateFor
      };
    case 'RECEIVE_PROPERTIES_FAIL':
      return {
        ...state,
        state: states.FAILED,
        stateFor: null
      };
    case 'RECEIVE_PROPERTIES':
      return {
        ...state,
        properties: action.page == 1 ? action.properties : _.concat(...state.properties, action.properties),
        page: action.page,
        count: action.count,
        totalPages: action.totalPages,
        state: states.SAVED,
        stateFor: null
      };
    case 'REQUEST_BOOK_TOUR':
      return {
        ...state,
        state: states.FETCHING,
        stateFor: action.stateFor
      };
    case 'RECEIVE_BOOK_TOUR_FAIL':
      return {
        ...state,
        state: states.FAILED,
        stateFor: null
      };
    case 'RECEIVE_BOOK_TOUR':
      let property ={...state.property}
      property.tour_requested = !property.tour_requested
      return {
        ...state,
        property: property,
        state: states.SAVED,
        stateFor: null
      };
    case 'REQUEST_WISHLISTS_PROPERTIES':
      return {
        ...state,
        state: states.FETCHING,
        stateFor: action.stateFor
      };
    case 'RECEIVE_WISHLIST_PROPERTIES_FAIL':
      return {
        ...state,
        state: states.FAILED,
        stateFor: null
      };
    case 'RECEIVE_WISHLIST_PROPERTIES':
      return {
        ...state,
        properties: action.page == 1 ? action.properties : _.concat(...state.properties, action.properties),
        page: action.page,
        count: action.count,
        totalPages: action.totalPages,
        state: states.SAVED,
        stateFor: null
      };
    case 'REQUEST_SEARCH_SUGGESTIONS':
      return {
        ...state,
        state: states.FETCHING,
        stateFor: action.stateFor
      };
    case 'RECEIVE_SEARCH_SUGGESTIONS_FAIL':
      return {
        ...state,
        state: states.FAILED,
        stateFor: null
      };
    case 'RECEIVE_SEARCH_SUGGESTIONS':
      return {
        ...state,
        suggestions: action.suggestions,
        state: states.SAVED,
        stateFor: null
      };
    case 'REQUEST_PROPERTY':
      return {
        ...state,
        state: states.FETCHING,
        stateFor: action.stateFor
      };
    case 'RECEIVE_PROPERTY_FAIL':
      return {
        ...state,
        state: states.FAILED,
        stateFor: null
      };
    case 'RECEIVE_PROPERTY':
      return {
        ...state,
        property: action.property,
        state: states.SAVED,
        stateFor: null
      };
    case 'REQUEST_TOGGLE_FAVORITE':
      return {
        ...state,
        state: states.FETCHING,
        stateFor: action.stateFor
      };
    case 'RECEIVE_TOGGLE_FAVORITE_FAIL':
      return {
        ...state,
        state: states.FAILED,
        stateFor: null
      };
    case 'TOGGLE_FAVORITE':
      if(state.stateFor === "TOGGLE_FAVORITE_LIST"){
        return {
          ...state,
          state: states.SAVED,
          properties : updateProperty([...state.properties], action.mslNumber),
          stateFor: null
        };
        
      }else{
        let property ={...state.property}
        property.is_favorite = !property.is_favorite
        return {
          ...state,
          property: property,
          state: states.SAVED,
          stateFor: null
        };
        
      }
    default:
      return state;
  }
};

export default match;


