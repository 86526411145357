import Util from '../util';
import { processEntity } from './common';
import { updateFellow } from './fellows';
import { updateProfile } from './profile';
import { fellowship as fellowshipSchema } from '../schema';

/**
 * Users who have formally connected
 */

export const createFellowship = (id) => (dispatch, getState) => {
  dispatch({
    type: 'REQUEST',
    meta: {type: 'fellowships'}
  });
  return Util.apiRequest(
    'POST',
    '/fellowships',
    null,
    {'fellowship': {'profile_ids': [parseInt(id)]}}
  ).then(response => {
    if (response.status === 204) {
      dispatch({
        type: 'RECEIVE',
        meta: {type: 'fellowships'}
      });
    } else if (response.ok) {
      return response.json().then(json => processEntity(
        dispatch,
        'fellowships',
        fellowshipSchema,
        json
      )).then(res => {
        const newIds = getState().profile.fellowships_attributes;
        const fellow = getState().fellows['_'+id];

        if (newIds.indexOf(res) === -1) {
          newIds.push(res);
          if(!fellow){

            return dispatch(updateProfile('fellowships_attributes', newIds));
          }else{
            dispatch(updateProfile('fellowships_attributes', newIds));

          }
        }
        
        
        if (fellow['fellowships_attributes'] === undefined) {
          fellow['fellowships_attributes'] = [];
        }
        fellow['fellowships_attributes'].push(res);
        dispatch(updateFellow(fellow));
        
      });
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: {type: 'fellowships'}
      });
    }
  });
};

export const cancelFellowship = (id) => (dispatch) => {
  dispatch({
    type: 'REQUEST',
    meta: {type: 'fellowships'}
  });
  return Util.apiRequest(
    'PUT',
    `/fellowships/${parseInt(id)}`,
    null,
    {'fellowship': {'approved': false}}
  ).then(response => {
    if (response.status === 204) {
      dispatch({
        type: 'RECEIVE',
        meta: {type: 'fellowships'}
      });
    } else if (response.ok) {
      return response.json().then(json => processEntity(
        dispatch,
        'fellowships',
        fellowshipSchema,
        json
      ));
    } else {
      dispatch({
        type: 'RECEIVE_FAIL',
        meta: {type: 'fellowships'}
      });
    }
  });
};


// export const requestMeetup = ( profileId) => (dispatch) => {
//   dispatch({
//     type: 'REQUEST',
//     meta: {type: 'fellowships'}
//   });
//   return Util.apiRequest(
//     'POST',
//     `/request_meetup`,
//     null,
//     {'fellowship': {profile_id: profileId }}
//   ).then(response => {
//     if (response.status === 204) {
//       dispatch({
//         type: 'RECEIVE',
//         meta: {type: 'fellowships'}
//       });
//     } else if (response.ok) {
//       return response.json().then(json =>
//         dispatch({
//           type: 'RECEIVE',
//           meta: {type: 'fellowships'}
//         })
//       );
//     } else {
//       dispatch({
//         type: 'RECEIVE_FAIL',
//         meta: {type: 'fellowships'}
//       });
//     }
//   });
// };


// const requestMeetStatusCheck = () => ({
//   type: 'REQUEST_MEET_STATUS_CHECK',
//   stateFor: "MEET_STATUS_CHECK"
// });

// const receiveMeetStatusCheckFail = () => ({
// type: 'RECEIVE_MEET_STATUS_CHECK_FAIL'
// });

// export const receiveMeetStatusCheck = (requestMeet) => ({
//   type: 'RECEIVE_MEET_STATUS_CHECK',
//   requestMeet
// });


// export const fetchMeetStatusCheck = (profileId) => dispatch => {
//   dispatch(requestMeetStatusCheck());
//   return Util.apiGetRequest(`/profiles/${profileId}/check_meet_requests`).then(response => {
//       if (response.ok) {
//           return response.json().then(json => {dispatch(receiveMeetStatusCheck(json))});
//       } else {
//           dispatch(receiveMeetStatusCheckFail());
//       }
//   })
// };


const requestPartnerDetails = () => ({
  type: 'REQUEST_PARTNER_DETAILS',
  stateFor: "PARTNER_DETAILS"
});

const receivePartnerDetailsFail = () => ({
type: 'RECEIVE_PARTNER_DETAILS_FAIL'
});

export const receivePartnerDetails = (profile) => ({
  type: 'RECEIVE_PARTNER_DETAILS',
  profile
});


export const fetchPartnerDetails = (profileId) => dispatch => {
  dispatch(requestPartnerDetails());
  return Util.apiGetRequest(`/profiles/${profileId}/details`).then(response => {
      if (response.ok) {
          return response.json().then(json => {dispatch(receivePartnerDetails(json))});
      } else {
          dispatch(receivePartnerDetailsFail());
      }
  })
};

