import React, { Component } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Form
} from 'reactstrap';

class ChatInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.message !== '') {
      this.props.sendMessage(this.props.conversationId, this.state.message);
      this.setState({
        message: ''
      });
    }
  };

  handleChange = (event) => this.setState({
    message: event.target.value
  });

  render() {
    return (
      <Form onSubmit={this.handleSubmit} className="submit-form">
        <InputGroup>
          <Input
            id="message"
            placeholder="Type your message..."
            aria-label="Type your message..."
            autoComplete="off"
            disabled={this.props.disabled}
            value={this.state.message}
            onChange={this.handleChange}
          />
          <InputGroupAddon >
            <Button
              type="submit"
              aria-label="Send"
              color="light"
              disabled={this.props.disabled}
              className="send-message"
            >
              <img src="/icons/send.svg" alt="" />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </Form>
    );
  }
}

export default ChatInput;
