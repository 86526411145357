import { connect } from 'react-redux';
import {
  fetchAdminProfiles,
  fetchUsers
} from '../../actions';
import Users from '../components/Users';

const mapStateToProps = (state) => ({
  users: state.adminUsers.items,
  userState: state.adminUsers.state,
  profiles: state.adminProfiles.items,
  profileState: state.adminProfiles.state,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProfiles: () => dispatch(fetchAdminProfiles()),
  fetchUsers: () => dispatch(fetchUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
