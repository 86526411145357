import Util from '../util';

const requestConversationMessages = () => ({
    type: 'REQUEST_CONVERSATION_MESSAGES',
    stateFor: 'conversation'
});
  
const receiveConversationMessagesFail = () => ({
  type: 'RECEIVE_CONVERSATION_MESSAGES_FAIL'
});
  
export const receiveConversationMessages = (record) => ({
    type: 'RECEIVE_CONVERSATION_MESSAGES',
    messages: record.messages,
    request_meet: record.request_meet,
    fellowship_id: record.fellowship_id,
    partner_profile: record.partner_profile,
    id: record.id
});
  
  
export const fetchConversationMessages = (id) => dispatch => {
    dispatch(requestConversationMessages());
    return Util.apiGetRequest(`/conversations/${id}/messages`).then(response => {
        if (response.ok) {
            return response.json().then(json => {dispatch(receiveConversationMessages(json))});
        } else {
            dispatch(receiveConversationMessagesFail());
        }
    })
};

const requestMeetup = () => ({
    type: 'REQUEST_MEETUP',
    stateFor: 'requestMeet'
});
  
const receiveMeetupFail = () => ({
  type: 'RECEIVE_MEETUP_FAIL'
});
  
export const receiveMeetup = (request_meet) => ({
    type: 'RECEIVE_MEETUP',
    request_meet,
});
  
  
export const sendRequestMeetup = (id) => dispatch => {
    dispatch(requestMeetup());
    
    return Util.apiRequest('POST',`/request_meetup`,null,{'fellowship': {profile_id: id }})
    .then(response => {
        if (response.ok) {
            return response.json().then(json => {dispatch(receiveMeetup(json.request_meet))});
        } else {
            dispatch(receiveMeetupFail());
        }
    })
};

const requestUnreadCount = () => ({
    type: 'REQUEST_UNREAD_COUNT',
    stateFor: 'requestMeet'
});
  
const receiveUnreadCountFail = () => ({
  type: 'RECEIVE_UNREAD_COUNT_FAIL'
});
  
export const receiveUnreadCount = (unread_count) => ({
    type: 'RECEIVE_UNREAD_COUNT',
    unread_count,
});
  
  
export const fetchUnreadCount = (id) => dispatch => {
    dispatch(requestUnreadCount());
    return Util.apiGetRequest(`/conversations/unread_messages`).then(response => {
        if (response.ok) {
            return response.json().then(json => {dispatch(receiveUnreadCount(json.data))});
        } else {
            dispatch(receiveUnreadCountFail());
        }
    })
};


export const updateConversationMessages = (messages) => dispatch => {
    dispatch({
        type: 'UPDATE_CONVERSATION_MESSAGES',
        messages: messages
    })
};