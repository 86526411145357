import Util from '../util';
import _ from 'lodash'

const requestProperties = (stateFor) => ({
    type: 'REQUEST_PROPERTIES',
    stateFor: stateFor
  });
  
  const receivePropertiesFail = () => ({
    type: 'RECEIVE_PROPERTIES_FAIL'
  });
  
  export const receiveProperties = (properties, page,  count,totalPages) => ({
    type: 'RECEIVE_PROPERTIES',
    properties, 
    page, 
    count,
    totalPages
  });
  
  
export const fetchProperties = (params, stateFor) => dispatch => {
    dispatch(requestProperties(stateFor));
    return Util.apiGetRequest('/properties'+Util.objectToQueryString(params)).then(response => {
        if (response.ok) {
            return response.json().then(json => {dispatch(receiveProperties(json.data, json.page, json.count,json.totalPages))});
        } else {
            dispatch(receivePropertiesFail());
        }
    })
};

const requestWishlistsProperties = (stateFor) => ({
    type: 'REQUEST_WISHLISTS_PROPERTIES',
    stateFor: stateFor
  });
  
  const receiveWishlistsPropertiesFail = () => ({
    type: 'RECEIVE_WISHLISTS_PROPERTIES_FAIL'
  });
  
  export const receiveWishlistsProperties = (properties, page,  count,totalPages) => ({
    type: 'RECEIVE_WISHLIST_PROPERTIES',
    properties, 
    page, 
    count,
    totalPages
  });
  
  
export const fetchWishlistsProperties = (params, stateFor) => dispatch => {
    dispatch(requestWishlistsProperties(stateFor));
    return Util.apiGetRequest('/wishlists'+Util.objectToQueryString(params)).then(response => {
        if (response.ok) {
            return response.json().then(json => {console.log(json);dispatch(receiveWishlistsProperties(json.data, json.page, json.count,json.totalPages))});
        } else {
            dispatch(receiveWishlistsPropertiesFail());
        }
    })
};

const requestProperty = (stateFor) => ({
    type: 'REQUEST_PROPERTY',
    stateFor: 'REQUEST_PROPERTY'
  });
  
  const receivePropertyFail = () => ({
    type: 'RECEIVE_PROPERTY_FAIL'
  });
  
  export const receiveProperty = (property) => ({
    type: 'RECEIVE_PROPERTY',
    property
  });
  
  
export const fetchProperty = (mslNumber) => dispatch => {
    dispatch(requestProperty());
    return Util.apiGetRequest('/properties/'+mslNumber).then(response => {
        if (response.ok) {
            return response.json().then(json => {
              dispatch(receiveProperty(json))
            });
        } else {
            dispatch(receivePropertyFail());
        }
    })
};

const requestToggleFavorite = (stateFor) => ({
  type: 'REQUEST_TOGGLE_FAVORITE',
  stateFor: stateFor
});
  
const receiveToggleFavoriteFail = () => ({
    type: 'RECEIVE_TOGGLE_FAVORITE_FAIL'
});
  
export const receiveFavorite = (mslNumber) => ({
    type: 'TOGGLE_FAVORITE',
    mslNumber
});
  
  
export const toggleFavorite = (mslNumber,stateFor) => dispatch => {
    dispatch(requestToggleFavorite(stateFor));
    return Util.apiGetRequest(`/favorites/toggle?msl_number=${mslNumber}`).then(response => {
        if (response.ok) {
            return response.json().then(json => dispatch(receiveFavorite(json.mslNumber)));
        } else {
            dispatch(receiveToggleFavoriteFail());
        }
    })
};


const requestSearchSuggestions = () => ({
    type: 'REQUEST_SEARCH_SUGGESTIONS',
    stateFor: "SEARCH_SUGGESTIONS"
  });
  
const receiveSearchSuggestionsFail = () => ({
  type: 'RECEIVE_SEARCH_SUGGESTIONS_FAIL'
});
  
export const receiveSearchSuggestions = (suggestions) => ({
  type: 'RECEIVE_SEARCH_SUGGESTIONS',
  suggestions
});
  
  
export const fetchSearchSuggestions = (params) => dispatch => {
    dispatch(requestSearchSuggestions());
    return Util.apiGetRequest('/properties/search_suggestions'+Util.objectToQueryString(params)).then(response => {
        if (response.ok) {
            return response.json().then(json => {dispatch(receiveSearchSuggestions(json))});
        } else {
            dispatch(receiveSearchSuggestionsFail());
        }
    })
};

const requestBookTour = (stateFor) => ({
  type: 'REQUEST_BOOK_TOUR',
  stateFor: stateFor
});
  
const receiveBookTourFail = () => ({
    type: 'RECEIVE_BOOK_TOUR_FAIL'
});
  
export const receiveBookTour = (mslNumber) => ({
    type: 'RECEIVE_BOOK_TOUR',
    mslNumber
});
  
  
export const setBookTour = (mslNumber,stateFor) => dispatch => {
    dispatch(requestBookTour(stateFor));
    return Util.apiRequest(
      'POST',
      '/tour_requests',
      null,
      {tour_request: {msl_number: mslNumber}}
    ).then(response => {
      if (response.ok) {
        return response.json().then(json => dispatch(receiveBookTour(json.mslNumber)));
      } else {
        dispatch(receiveBookTourFail());
      }
    });
};
