import React, { Component } from 'react';
import states from '../reduxStates';
import {
  Form,
  FormGroup,
  Button,
  Alert
} from 'reactstrap';
import FloatingLabelInput from './utils/FloatingLabelInput';


class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      waiting: false
    }
  }

  handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;

    this.setState({
      [id]: value
    });
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ waiting: true });
    this.props.forgotPassword(this.state.email).then(() => {
      if (!this.unmounting) {
        if(this.props.state === states.SAVED){
          this.setState({
            email: ''
          });
        }
        this.setState({ waiting: false });
      }
    });
  }

  render() {
    let message;
    if (this.props.state === states.SAVED){
      message = (
        <Alert color="success">
          An email has been sent to your address with instructions on how to reset your password.
        </Alert>
      );
    } else if (this.props.state === states.FAILED) {
      message = (
        <Alert color="warning">
          {this.props.error ? this.props.error : 'No account found with the specified email address.' }
        </Alert>
      );
    } else {
      message = null;
    }
    
    const loadingIcon = this.state.waiting ? <i className="fas fa-fw fa-spinner fa-spin"></i> : null;

    return (
      <Form onSubmit={this.handleSubmit} noValidate>
        <FormGroup>
          <FloatingLabelInput
            id="email"
            type="email"
            placeholder="Email"
            onChange={this.handleChange}
            value={this.state.email}
            required />
        </FormGroup>
        <FormGroup className="text-center">
          <Button type="submit" color="primary"  className="theme-button">
            Reset Password {loadingIcon}
          </Button>
        </FormGroup>
        {message}
      </Form>
    );
  }
}

export default ForgotPasswordForm; 
