import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import Modal from '../../components/utils/SimpleModal';

class UserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  toggle = () => this.setState({
    open: !this.state.open
  });

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.toggle}>View Profile</Button>
        <Modal
          isOpen={this.state.open}
          toggle={this.toggle}
          title={this.props.profile.user_id}
          buttons={[
            { text: 'Close' }
          ]}>
          <Table bordered striped>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(this.props.profile).map(key =>
                  (typeof this.props.profile[key] !== "object") ? (
                    <tr key={key}>
                      <td>{ key }</td>
                      <td>{ this.props.profile[key] }</td>
                    </tr>
                  ) : null
                )
              }
            </tbody>
          </Table>
        </Modal>
      </React.Fragment>
    );
  }
}

export default UserModal;
