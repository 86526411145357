import React, { Component } from 'react';
import {
  Col,
  Row,
  Form
} from 'reactstrap';
import states from '../../reduxStates';
import InterestList from '../InterestList';
import LocationForm from '../LocationForm';
import SocialForm from '../SocialForm';
import PersonalInformationForm from '../PersonalInformationForm';
import PhotoUploader from '../PhotoUploader';

class BasicProfileForm extends Component {
  handleChange = (key,value)=>{
    this.props.updateProfile(key,value)
  }
  handleFormSubmit = (event) => {
    event.preventDefault();
  };

  static validate(profile, locationSelections) {
    let req = [
      'photo',
      'profession',
      'pets',
      'location_selections_attributes',
      'birthday',
      'duration',
      'relationship_status',
      'first_name',
      'last_name'
    ]
   
    const errors = req.reduce((acc, cur) => {
      if (profile[cur] === null ||
        profile[cur] === undefined ||
        profile[cur] === ''
      ) {
        acc.push(cur);
      }
      return acc;
    }, []);

    if (!locationSelections.length || !locationSelections[0].location_id) {
      errors.push('location_selections_attributes');
    }
    if(profile['photo'] && profile['photo'].placeholder){
      errors.push('photo');
    }
    
    return errors;
  }


  render() {
    let {interests,duration,first_name,last_name,twitter_link, instagram_link,tiktok_link} = this.props.profile
    let {locationSelection,profileState,locationData,locations} = this.props
    const fetching = (profileState === states.FETCHING) ? true : false;
    return (
        <Form noValidate onSubmit={this.handleFormSubmit}>
        <Row>
          <Col xs={12} md={3}>
            <Row>
              <Col sm={12} className="text-center">
                <strong className="name">{first_name} {last_name}</strong>
              </Col>

            </Row>
            <PhotoUploader
              id="profile-photo"
              photo={this.props.profile.photo}
              savePhoto={this.props.saveProfilePhoto}
              deletePhoto={this.props.deleteProfilePhoto}
              disabled={fetching}
              aspectRatio="1by1"
              showSeperateButtons={true}
              width="100%"
              className="profile-picture-uploader"
              errors={this.props.errors}
            />
          </Col>
          <Col xs={12} md={9} className="form-container">
            <PersonalInformationForm 
              profile={this.props.profile}
              fetching={fetching}
              saveImageGalleryPhoto={this.props.saveImageGalleryPhoto}
              deleteImageGalleryPhoto={this.props.deleteImageGalleryPhoto}
              handleChange={this.handleChange}
              errors={this.props.errors}
            />
            <InterestList 
              selected={interests}
              fetching={fetching}
              handleChange={this.handleChange} />
            

            <LocationForm 
              updateLocationSelection={this.props.updateLocationSelection}
              locationSelection= {locationSelection}
              fetching={fetching}
              locations={locations}
              location={locationData}
              populatePredictions= {this.props.populatePredictions}
              duration={duration}
              handleChange={this.handleChange}
              errors={this.props.errors}
            
            />
          
            <SocialForm 
              twitter={twitter_link}
              instagram={instagram_link}
              tiktok={tiktok_link}
              fetching={fetching}
              handleChange={this.handleChange}
            />
            
          </Col>
        </Row>
      </Form>
    );
  }
}

export default BasicProfileForm;
