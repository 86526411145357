import states from '../reduxStates';

const initialState = {
  state: states.NEW,
  id: null,
  messages: [],
  request_meet: {},
  partner_profile: {},
  stateFor: null,
  unreadCount: 0,
};


const conversationMessages = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_CONVERSATION_MESSAGES':
      return {
        ...state,
        state: states.FETCHING,
        stateFor: action.stateFor
      };
    case 'RECEIVE_CONVERSATION_MESSAGES_FAIL':
      return {
        ...state,
        state: states.FAILED,
        stateFor: null
      };
    case 'RECEIVE_CONVERSATION_MESSAGES':
      return {
        id: action.id,
        messages: action.messages,
        request_meet: action.request_meet,
        fellowship_id: action.fellowship_id,
        partner_profile: action.partner_profile,
        state: states.SAVED,
        stateFor: null
      };
    case 'REQUEST_MEETUP':
        return {
          ...state,
          state: states.FETCHING,
          stateFor: action.stateFor
        };
    case 'RECEIVE_MEETUP_FAIL':
        return {
          ...state,
          state: states.FAILED,
          stateFor: null
        };
    case 'RECEIVE_MEETUP':
        return {
          ...state,
          request_meet: action.request_meet,
          state: states.SAVED,
          stateFor: null
        };
    case 'REQUEST_UNREAD_COUNT':
        return {
          ...state,
          state: states.FETCHING,
          stateFor: action.stateFor
        };
    case 'RECEIVE_UNREAD_COUNT_FAIL':
        return {
          ...state,
          state: states.FAILED,
          stateFor: null
        };
    case 'RECEIVE_UNREAD_COUNT':

        return {
          ...state,
          unreadCount: action.unread_count ,
          state: states.SAVED,
        };
    case 'REQUEST_UPDATE_CONVERSATION_MESSAGES':
        return {
          ...state,
          state: states.FETCHING,
          stateFor: 'messages'
        };
    case 'UPDATE_CONVERSATION_MESSAGES':
        return {
          ...state,
          messages: Object.assign({}, state.messages, action.messages),
          state: states.SAVED,
          stateFor: null
        };
    default:
      return state;
  }
};

export default conversationMessages;
