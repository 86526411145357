import React, { Component } from 'react';
import {
  Button,
  ButtonGroup,
} from 'reactstrap';
import _ from 'lodash'

class MultiChoice extends Component {
  setChoice = (choice) => {
    let selected = [...this.props.value];
    let index = _.indexOf(selected, choice);

    if(index > -1)
        selected.splice(index,1)
    else
        selected.push(choice)

    this.props.setChoice(selected);
  };

  render() {
    return (
      <ButtonGroup style={{verticalAlign: 'baseline'}} className="button-group multiselect-btn-group">
        {this.props.options.map((item, i) => {
          return (
            <Button
              key={i}
              size="sm"
              outline={_.indexOf(this.props.value, item.value) === -1}
              color={this.props.invalid ? 'danger' : 'primary'}
              onClick={() => this.setChoice(item.value)}
              disabled={this.props.disabled}
              className="multiselect"
            >
              { item.label }
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}
export default MultiChoice;
