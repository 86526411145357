import {DURATION_LIST,INTRESTS} from './constants';
import React from 'react';
import { Badge } from 'reactstrap';
import _ from 'lodash';
export class Util {
  static snakeCase(input) {
    return input.replace(/[\w]([A-Z])/g, (m) => m[0] + "_" + m[1]).toLowerCase();
  }

  static getMessageDate(date){
    let dateArr = date.slice(0,10).replace(/-/g,'-').split('-');
    dateArr = _.reverse(dateArr);
    let yr = dateArr[2].slice(2,4)
    dateArr[2] = yr
    return dateArr.join('-');
  }

  static objectToQueryString(params){
    const qs = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    return qs.length > 0 ? `?${qs}` : qs
  }

  static parseSearchHash(search) {
    const hash = {};
    search.slice(1)
      .split('&')
      .forEach(item => {
        const tuple = item.split("=");
        hash[tuple[0]] = decodeURIComponent(tuple[1]);
      });
    return hash;
  }

  static apiGetRequest(path, headers = null) {
    return this.apiRequest('GET', path, headers);
  }

  static apiRequest(method, path, headers = null, body = null) {
    const auth = this.getLocalAuthObj();
    const options = {
      method: method,
      credentials: 'include',
      mode:  'cors',//'cors', // no-cors, cors, *same-origin
      headers: {
        ...headers,
        ...auth,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    if (body) {
      options['body'] = JSON.stringify(body);
    }
    return fetch(`${process.env.REACT_APP_API_URL}${path}`, options)
      .then(response => {
        this.parseAndSaveAuthHeaders(response.headers);
        return response;
      });
  }

  static fetchCSV(path) {
    const auth = this.getLocalAuthObj();
    const params = Object.keys(auth).map(key => `${key}=${auth[key]}`).join('&');
    window.open(`${process.env.REACT_APP_API_URL}${path}.csv/?${params}`)
  }
  
  static uploadProfilePhoto(path, photo) {
    const auth = this.getLocalAuthObj();
    const data = new FormData();
    data.append('profile[photo]', photo);
    
    const options = {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      body: data,
      headers: {
        ...auth,
      }
    };
    return fetch(`${process.env.REACT_APP_API_URL}${path}`, options)
      .then(response => {
        this.parseAndSaveAuthHeaders(response.headers)
        return response;
      });
  }

  static uploadImagegalleryPhoto(path, id, photo) {
    const auth = this.getLocalAuthObj();
    const data = new FormData();
    
    data.append('profile[image_galleries_attributes][0][photo]',  photo);
    data.append('profile[image_galleries_attributes][0][id]',  id);
    
    const options = {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      body: data,
      headers: {
        ...auth,
      }
    };
    return fetch(`${process.env.REACT_APP_API_URL}${path}`, options)
      .then(response => {
        this.parseAndSaveAuthHeaders(response.headers)
        return response;
      });
  }

  static parseAndSaveAuthHeaders(headers) {
    const accessToken = headers.get('access-token');
    const client = headers.get('client');
    const uid = headers.get('uid');
    this.setLocalAuth(accessToken, client, uid);

    return this.getLocalAuth();
  }

  static setLocalAuth(accessToken, client = null, uid = null) {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    }
    if (client) {
      localStorage.setItem('client', client);
    }
    if (uid) {
      localStorage.setItem('uid', uid);
    }
  }

  static getLocalAuth() {
    return [
      localStorage.getItem('accessToken'),
      localStorage.getItem('client'),
      localStorage.getItem('uid')
    ];
  }

  static getLocalAuthObj() {
    const [accessToken, client, uid] = this.getLocalAuth();
    const auth = {};
    if (accessToken) {
      auth['access-token'] = accessToken;
    }
    if (client) {
      auth['client'] = client;
    }
    if (uid) {
      auth['uid'] = uid;
    }

    return auth;
  }

  static clearLocalAuth() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
  }

  static getAuthObjFromURL(url, oauth = false) {
    const hash = this.parseSearchHash(url);
    let accessToken, client;
    if (oauth) {
      accessToken = hash['auth_token'];
      client = hash['client_id'];
    } else {
      accessToken = hash['access-token'];
      client = hash['client'];
    }
    const uid = hash['uid'];
    const auth = {};
    if (accessToken) {
      auth['access-token'] = accessToken;
    }
    if (client) {
      auth['client'] = client;
    }
    if (uid) {
      auth['uid'] = uid;
    }

    return auth;
  }

  static checkFeature(flag) {
    const ls = localStorage.getItem('features');
    const inEnv = process.env.hasOwnProperty(flag);
    const inLocalStorage = ls ? JSON.parse(ls)[flag] : undefined;
    if (inLocalStorage !== undefined) {
      return inLocalStorage;
    } else {
      return inEnv;
    }
  }

  static setFeatures(features) {
    localStorage.setItem('features', JSON.stringify({
      ...JSON.parse(localStorage.getItem('features')),
      ...features
    }));
  }

  static clearFeatures(flag) {
    localStorage.removeItem('features');
  }
  
  static getFeatures() {
    const ls = localStorage.getItem('features');
    return ls ? JSON.parse(ls) : undefined;
  }

  // Trims strings without cutting off words
  static trimString(string, length) {
    if (string && length < string.length) {
      let trim = string.substr(0, length);
      trim = trim.substr(0, Math.min(trim.length, trim.lastIndexOf(' ')));
      trim += '...';
      return trim;
    } else {
      return string;
    }
  }

  static getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  static durationLabel(duration) {
    let record = _.find(DURATION_LIST, { 'value': duration });
    return record ? record.displayLabel : null
  }

  static findMatchingIntrest(fellowProfile, profile){
    return _.intersection(fellowProfile.interests, profile.interests).slice(0, 3);
  }
  
  static intrestList = (intrests) =>{
    let list =[]
    if(intrests.length > 0){
        list = intrests.map((rec,index)=>{
            let record = _.find(INTRESTS, { 'value': rec });
            return ( 
                record && <Badge   key={'it-'+index} color="light" className="interest-badge">{record.name} <img src={`/intrests/${record.icon}.png`} alt="" /></Badge>
            )
        })
    }
    return list
  }

  static debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };
}

export default Util;
