import React, { Component } from 'react';
import states from '../../../reduxStates';
import Page from '../_Page';
import {
  CardBody,
  Progress,
} from 'reactstrap';
import CompleteProfileView from './CompleteProfileView';
import NewProfileView from './NewProfileView';

class Dashboard extends Component {
  componentDidMount() {

    if (this.props.location.state && this.props.location.state.registered) {
      this.props.addAlert({
        message: 'Your account has been created!'
      });
    }
    this.props.fetchNews()
  }

  render() {
    const contentFetching = (
      <CardBody>
        <Progress animated value={100} className="my-4 mx-5" />
      </CardBody>
    );

    const pageContent = (
      (this.props.matchState === states.FETCHING) ? contentFetching :
      (this.props.profile.complete) ? <CompleteProfileView {...this.props} /> :
      <NewProfileView  profile={this.props.profile} news={this.props.news}/>
    );

    return (
      <Page title="Dashboard" hideTitle pageClass="dashboard" container="fluid" hideFooter>
          { pageContent }
      </Page>
    );
  };
}

export default Dashboard;
