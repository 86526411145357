import states from '../../reduxStates';

const initialState = {
  state: states.NEW,
  items: []
};

const profiles = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_ADMIN_PROFILES':
      return {
        ...state,
        state: states.FETCHING
      };
    case 'RECEIVE_ADMIN_PROFILES':
      return {
        items: action.items,
        state: states.SAVED
      };
    case 'RECEIVE_ADMIN_PROFILES_FAIL':
      return {
        ...state,
        state: states.FAILED
      };
    default:
      return state;
  }
};

export default profiles;
