import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

export default class ImageLightBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: null
    };
  }
  toggleClose = ()=>{
    this.setState({photoIndex: null}, ()=>{
      this.props.toggleClose();
    })
  }
  render() {
    let { photoIndex } = this.state;
    let propsPhotoIndex = this.props.photoIndex;
    const { images } = this.props;
    // if(this.props.photoIndex){
    //   photoIndex = this.props.photoIndex
    // }
    let pIndex = (photoIndex || propsPhotoIndex || 0)
    return (
        <Lightbox
          mainSrc={images[pIndex]}
          nextSrc={images[((pIndex) + 1) % images.length]}
          prevSrc={images[((pIndex) + images.length - 1) % images.length]}
          onCloseRequest={() => this.toggleClose()}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: ((pIndex) + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: ((pIndex) + 1) % images.length,
            })
          }
        />
    );
  }
}