import { connect } from 'react-redux';
import Dashboard from '../components/pages/Dashboard';
import {
  fetchFellows,
  fetchProfile,
  updateProfile,
  saveProfile,
  addAlert,
  fetchNews
} from '../actions';

const mapStateToProps = (state) => {
  const locationSelections = state.profile['location_selections_attributes'] ?
    state.profile['location_selections_attributes']
    .map(id => state.locationSelections[id]) :
    [];
  const locationSelection = locationSelections[0] || {};
  const location = (locationSelection.location_id) ?
    state.locations[locationSelection.location_id] :
    {};
  // this will need to change once fellowships can have more than two people
  const fellows = Object.values(state.fellowships).reduce((acc, f) => {
    const id = (f.profile_ids[0] === state.profile.id) ?
      f.profile_ids[1] :
      f.profile_ids[0];

    if (f.active && state.fellows[id]) {
      acc[id] = state.fellows[id];
    }

    return acc;
  }, {});
  return {
    fellowsState: state.states.fellows,
    fellows: fellows,
    fellowships: state.fellowships,
    location: location,
    locations: state.locations,
    locationSelection: locationSelection,
    locationSelections: state.locationSelections,
    profileState: state.states.profile,
    profile: state.profile,
    messages: state.messages,
    news: state.news.news,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFellows: () => dispatch(fetchFellows()),
  updateProfile: (name, value) => dispatch(updateProfile(name, value)),
  saveProfile: () => dispatch(saveProfile()),
  fetchProfile: () => dispatch(fetchProfile()),
  addAlert: (alert) => dispatch(addAlert(alert)),
  fetchNews: () => dispatch(fetchNews())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
